
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenLocationType {
    static readonly Country: GenLocationType = new GenLocationType('Country');
    static readonly COCOM: GenLocationType = new GenLocationType('COCOM');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'Country') return GenLocationType.Country;
        if (name === 'COCOM') return GenLocationType.COCOM;
        throw new Error('Unexpected value for GenLocationType:' + name);
    }

    static values(): GenLocationType[] {
        return [
            GenLocationType.Country,
            GenLocationType.COCOM,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

