<div mat-dialog-title class="dialog-header">
  <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-8]="true">
    <mat-icon>delete_outline</mat-icon>
    <div>Delete Role</div>
  </div>
</div>
<div #content mat-dialog-content class="content" [class.flex-align-start-center]="true">
  <div class="content full-width" [class.flex-column]="true" [class.flex-align-start-center]="true" [class.gap-16]="true">
    <div class="full-width info">Check the roles you want to delete. This is a global change. The role will be gone and all users will lose the access it grants.</div>
    <div class="checkbox-group full-width" [style.height.px]="maxRoleHeight" [class.flex-column-wrap]="true"
         [class.flex-align-start-start]="true" [class.gap-12]="true">
      <div class="checkbox-container" *ngFor="let checkList of roleChecklist">
        <mat-checkbox
          color="primary"
          [(ngModel)]="checkList.checked">{{roleDescription(checkList.role)}}
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions [class.flex-row]="true" [class.flex-align-end-center]="true" [class.gap-12]="true">
  <button mat-raised-button [class.flex-145]="true" (click)="close()">CANCEL</button>
  <button mat-raised-button color="primary" [class.flex-145]="true" [disabled]="!isValid" (click)="delete()">Delete</button>
</mat-dialog-actions>
