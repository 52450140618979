
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenLocationType } from './GenLocationType';

export class GenGenLocation {

    id: number;
    cocomUqid: number;
    geom: Object;
    name: string;
    parentLocationUqid: number;
    type: GenLocationType;

    constructor();
    constructor( jsonObj: any );
    constructor( genGenLocation: GenGenLocation);
    constructor( id: number );

    constructor( input?: GenGenLocation|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.cocomUqid = input.cocomUqid; // int [number]
            if( input.geom ) {
              this.geom = new Object( input.geom );
            }
            this.name = input.name; // string [string]
            this.parentLocationUqid = input.parentLocationUqid; // int [number]
            this.type = (typeof input.type === 'string') ? GenLocationType.forName(<string> input.type) : <any> input.type; // GenLocationType
       }
    }

    static jsonResponseToList(arr: any[]): GenGenLocation[] {
        if (arr && arr.map) {
          return arr.map(a => new GenGenLocation(a));
        } else {
          return [];
        }
    }
}

