<h1 mat-dialog-title>Downloads</h1>
<table style="display:block; max-height: 400px;">
  <thead>
    <tr>
      <th>User</th>
      <th>Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ua of userActions">
      <td>{{ ua.user.firstName + ' ' + ua.user.lastName }}</td>
      <td>{{ ua.occurred.format('YYYY-MM-DD HH:mm') }}</td>
    </tr>
  </tbody>

</table>
