
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenAttachment } from './GenAttachment';
import { GenLabel } from './GenLabel';
import { GenTask } from './GenTask';

export class GenTrelloCard {

    attachments: GenAttachment[];
    closed: boolean;
    desc: string;
    idBoard: string;
    idList: string;
    idMembers: string[];
    idShort: number;
    labels: GenLabel[];
    name: string;
    pos: number;
    task: GenTask;
    trelloId: string;
    url: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genTrelloCard: GenTrelloCard);

    constructor( input?: GenTrelloCard ) {
        if ( input && typeof input !== 'number' ) {
            if ( input.attachments && input.attachments.map ) { // list [GenAttachment]
                this.attachments = input.attachments.map( ( x: GenAttachment ) => {
                    return new GenAttachment( x );
                } );
            } else {
                this.attachments = input.attachments;
            }
            this.closed = input.closed; // boolean [boolean]
            this.desc = input.desc; // string [string]
            this.idBoard = input.idBoard; // string [string]
            this.idList = input.idList; // string [string]
            this.idMembers = input.idMembers; // list [string]
            this.idShort = input.idShort; // int [number]
            if ( input.labels && input.labels.map ) { // list [GenLabel]
                this.labels = input.labels.map( ( x: GenLabel ) => {
                    return new GenLabel( x );
                } );
            } else {
                this.labels = input.labels;
            }
            this.name = input.name; // string [string]
            this.pos = input.pos; // float [number]
            if( input.task ) {
              this.task = new GenTask( input.task );
            }
            this.trelloId = input.trelloId; // string [string]
            this.url = input.url; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenTrelloCard[] {
        if (arr && arr.map) {
          return arr.map(a => new GenTrelloCard(a));
        } else {
          return [];
        }
    }
}

