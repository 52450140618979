<!--suppress TypeScriptValidateTypes -->
<div [class.flex-column]="true" [class.flex-align-start-start]="true">
  <mat-form-field class="country-input full-width" appearance="outline">
    <mat-label>Country (empty for Global)</mat-label>
    <input matInput [matAutocomplete]="countryAuto" [formControl]="locationControl">
    <mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="nameFromLocationFn()">
      <mat-option *ngIf="!searchText" (onSelectionChange)="selectLocation(null)"> -- Global --</mat-option>
      <mat-option *ngFor="let location of filteredLocations | async"
                  [value]="location"
                  (onSelectionChange)="selectLocation(location)">
        {{ location.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="name-input full-width" appearance="outline">
    <mat-label>Task Name</mat-label>
    <input matInput
           [(ngModel)]="task.name"
           (keyup)="checkValidity()"
    >
  </mat-form-field>
  <form class="full-width">
    <mat-form-field class="contributor-input full-width" appearance="outline">
      <mat-label>Assignee</mat-label>
      <input matInput
             [matAutocomplete]="contributorAutoComplete"
             [formControl]="contributorControl">
      <mat-autocomplete #contributorAutoComplete="matAutocomplete">
        <mat-option *ngFor="let user of filteredContributors | async"
                    [value]="concatName( user )"
                    (onSelectionChange)="selectContributor(user)">
          {{ displayUserInfo(user) }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Optional</mat-hint>
    </mat-form-field>
  </form>

  <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-12]="true">
    <mat-form-field appearance="outline" [class.flex-grow]="true">
      <mat-label>Published Date</mat-label>
      <input matInput
             [(ngModel)]="task.publishedTimestamp"
             [matDatepicker]="publishedDatepicker"
      >
      <mat-datepicker-toggle matSuffix [for]="publishedDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #publishedDatepicker></mat-datepicker>
      <mat-hint>Optional</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" [class.flex-grow]="true">
      <mat-label>Tentative Date</mat-label>
      <input matInput
             [(ngModel)]="task.releaseTimestamp"
             [matDatepicker]="releaseDatepicker"
             (keyup)="checkValidity()"
             (dateChange)="checkValidity()">
      <mat-datepicker-toggle matSuffix [for]="releaseDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #releaseDatepicker></mat-datepicker>
      <mat-hint *ngIf="task.releaseTimestamp">Anticipated {{ task.anticipatedRelease() }}</mat-hint>
    </mat-form-field>
  </div>

  <mat-form-field class="release-notes full-width" appearance="outline">
    <mat-label>Description</mat-label>
    <textarea matInput
              [(ngModel)]="task.releaseNotes"
              matTextareaAutosize
              matAutosizeMinRows="4"
              matAutosizeMaxRows="6"
              name="releaseNotes"
              id="releaseNotes"
              (keyup)="checkValidity()"></textarea>
  </mat-form-field>

  <mat-form-field class="tag-input full-width" appearance="outline">
    <mat-label>Tag</mat-label>
    <input matInput
           [matAutocomplete]="tagAutoComplete"
           (keyup.enter)="addInputTextAsTag()"
           [formControl]="tagControl">

    <mat-autocomplete #tagAutoComplete="matAutocomplete">
      <mat-option *ngFor="let tag of filteredTags | async"
                  [value]="tag"
                  (onSelectionChange)="selectTag(tag)">
        {{ tag }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>Optional</mat-hint>
  </mat-form-field>

  <mat-chip-set class="full-width chip-list">
    <mat-chip class="chip" *ngFor="let tag of task.tags">
      <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-8]="true">
        <mat-icon class="chip-icon">local_offer</mat-icon>
        <div>{{ tag }}</div>
        <mat-icon (click)="removeTag(tag)" class="chip-close-icon">cancel</mat-icon>
      </div>
    </mat-chip>
  </mat-chip-set>

  <portal-file-upload [file]="(task.originalFilename) ? task.originalFilename : file"
                      [optional]="true"
                      (fileAdded)="fileAdded($event)"
                      (deleteFile)="deleteFile()"
                      (downloadFile)="fetch(task)"
                      class="full-width">
  </portal-file-upload>

  <portal-file-upload [file]="thumbnailFile" [canDelete]="false" (fileAdded)="thumbnailFile = $event"
                      (deleteFile)="thumbnailFile = undefined" title="Upload Image" [optional]="true"
                      class="full-width"></portal-file-upload>

  <div *ngIf="task.hasWms() && wmsLayer" class="full-width" [class.flex-column]="true"
       [class.flex-align-start-start]="true">
    <div class="wms-header">Edit WMS Layer</div>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Display Name</mat-label>
      <input type="text" matInput [(ngModel)]="wmsLayer.displayName" name="displayName">
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Style</mat-label>
      <mat-select [(ngModel)]="wmsLayer.styleName">
        <mat-option *ngFor="let style of wmsStyles" [value]="style.name">{{ style.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-12]="true">
    <mat-form-field appearance="outline" [class.flex-grow]="true">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="task.status" (selectionChange)="statusChange($event)">
        <ng-container *ngFor="let status of statuses; let i = index;">
          <mat-option [value]="status"
                      [disabled]="!user || (!user.isPublisher() && status.name === 'Complete')">{{ prettyStatus(status) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="task.originalFilename" appearance="outline" [class.flex-grow]="true">
      <mat-label>Contents File Type</mat-label>
      <input matInput [(ngModel)]="task.fileType">
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Location Scope</mat-label>
    <mat-select [(value)]="task.scope">
      <mat-option [value]="scope" *ngFor="let scope of scopes">{{ scope }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox style="margin-bottom: 12px;" [(ngModel)]="task.sticky" (change)="updateDocumentationTag()"
                color="primary">Documentation
  </mat-checkbox>

  <iframe #downloadFrame></iframe>

</div>

<div *ngIf="uploadInProgress" class="progress-bar">
  <mat-progress-bar class="full-width" mode="determinate" [value]="uploadPercentage"></mat-progress-bar>
</div>

