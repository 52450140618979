import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {defaults as defaultInteractions} from 'ol/interaction/defaults';
import {Image, Tile} from 'ol/layer';
import {transformExtent} from 'ol/proj';
import {ImageWMS, XYZ} from 'ol/source';
import {Map, View} from 'ol';

@Component({
  selector: 'portal-map-preview-dialog',
  templateUrl: './map-preview-dialog.component.html',
  styleUrls: ['./map-preview-dialog.component.scss']
})
export class MapPreviewDialogComponent implements AfterViewInit {

  layerNames: any;
  layer: Tile<XYZ>;
  zoom = 3;
  map: Map;
  taskId: number;
  wmsLayer: Image<ImageWMS>;
  taskName: string;
  requestParams: any;


  @ViewChild('mapElement') mapElement: ElementRef;
  public legendUrl: string;
  selectedLayer: string;
  layerSource: ImageWMS;

  public boundingBoxMap: { [name: string]: [number, number, number, number] } = {};
  public mapView: View;
  public imageHasAlreadyLoaded = false;
  public extentHasAlreadyLoaded = false;

  constructor(private ref: MatDialogRef<MapPreviewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public popup: MatSnackBar,
              public http: HttpClient) {
    if (data) {
      this.taskId = data.taskId;
      this.layerNames = data.layerNames;
      this.taskName = data.taskName;
    }

    this.requestParams = {
      REQUEST: 'GetLegendGraphic',
      VERSION: '1.0.0',
      FORMAT: 'image/png',
      WIDTH: '20',
      HEIGHT: '20',
      LAYER: this.layerNames[0],
    };
  }

  close() {
    this.ref.close();
  }

  ngAfterViewInit(): void {
    this.getCoordinates();
    this.initializeMap();

    this.selectedLayer = this.layerNames[0];
    this.changeLayer();

    this.map.getView().on('change', () => {
      this.zoom = this.map.getView().getZoom();
    });

    window.setTimeout(() => {
    }, 1000);
  }

  public getCoordinates(): void {
    // const url = 'http://localhost:8080/geoserver/wms?'
    const url = 'api/proxy/geoserver';
    const capabilityParams = {
      service: 'wms',
      version: '1.1.1',
      request: 'GetCapabilities'
    };

    const au = Object.keys(capabilityParams).map(key => `${key}=${capabilityParams[key]}`).join('&');


    this.http.post(url, au, {responseType: 'text'}).subscribe(res => {
      const doc = new DOMParser().parseFromString(res, 'text/xml');
      const layerTags: Array<HTMLElement> = Array.from(doc.getElementsByTagName('Layer') as any);

      layerTags.filter(layer => {
        const children = Array.from(layer.childNodes).filter(node => node.nodeType === 1);
        const index = children.findIndex(c => c.nodeName === 'BoundingBox');
        return index !== -1;
      }).forEach(layer => {
        const bboxElement = layer.getElementsByTagName('BoundingBox')[0];
        const nameElement = layer.getElementsByTagName('Name')[0];
        const name = nameElement.innerHTML;

        const bbox: [number, number, number, number] = [
          parseFloat(bboxElement.getAttribute('minx')),
          parseFloat(bboxElement.getAttribute('miny')),
          parseFloat(bboxElement.getAttribute('maxx')),
          parseFloat(bboxElement.getAttribute('maxy'))
        ];
        this.checkBboxFor4326(bbox);

        this.boundingBoxMap[name] = bbox;
      });


    });

  }

  checkBboxFor4326(bbox: [number, number, number, number]) {
    if (bbox[0] <= -180) {
      bbox[0] = -180;
    }
    if (bbox[1] <= -90) {
      bbox[1] = -90;
    }
    if (bbox[2] >= 180) {
      bbox[2] = 180;
    }
    if (bbox[3] >= 90) {
      bbox[3] = 90;
    }
  }

  public initializeMap(): void {

    const viewConfig: View = new View({
      center: [0, 0],
      zoom: this.zoom,
      minZoom: 1
    });

    this.layer = new Tile<XYZ>({
      source: new XYZ({
        attributions: 'Satellite © <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer" target="_blank">ArcGIS Online</a>',
        url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'anonymous',
        wrapX: false
      })
    });

    this.wmsLayer = new Image({
      source: this.layerSource,
      opacity: 0.75
    });

    this.map = new Map({
      target: this.mapElement.nativeElement,
      controls: [],
      layers: [this.layer, this.wmsLayer],
      view: viewConfig,
      // loadTilesWhileAnimating: true,
      interactions: defaultInteractions({
        shiftDragZoom: false,
        altShiftDragRotate: false
      })
    });

    this.mapView = this.map.getView();
  }

  public zoomMap(direction: number): void { // direction must be 1 |-1 but needs to be typed for html
    const options: any = {zoom: this.mapView.getZoom() + direction};
    this.mapView.animate(options);
  }


  public changeLayer(): void {
    let index = this.layerNames.length - 1;
    while (index > 0 && this.layerNames[index] !== this.selectedLayer) { index--; }

    const params = Object.keys(this.requestParams).map(key => {
      return `${key}=${this.requestParams[key]}`;
    }).join('&');

    const source = new ImageWMS({
      url: `${window.location.origin}/api/proxy/proxy?taskId=${this.taskId}&urlIndex=${index}`,
      projection: 'EPSG:3857',
      params: {
        Layers: this.selectedLayer
      },
      ratio: 1,
      serverType: 'geoserver'
    });


    source.on('imageloadstart', () => {
      this.imageHasAlreadyLoaded = false;
    });

    source.on('imageloadend', () => {
      this.imageHasAlreadyLoaded = true;
    });

    this.fitToLayerExtent(this.selectedLayer);

    this.wmsLayer.setSource(source);
    this.legendUrl = `api/proxy/wms-legend?taskId=${this.taskId}&urlIndex=${index}&${params}`;
  }

  public fitToLayerExtent(name: string): void {
    let i = 0;
    const handle = setInterval(() => {
      const bbox: [number, number, number, number] = this.boundingBoxMap[name];
      i++;
      if (bbox) {
        const transformed = transformExtent(bbox, 'EPSG:4326', 'EPSG:3857');
        this.map.getView().fit(transformed, {
          duration: 1000,
          callback: () => {
            this.imageHasAlreadyLoaded = false; // clear this because the image needs to load at the new resolution.
          }
        });
        this.extentHasAlreadyLoaded = true;
        clearInterval(handle);
      }

    }, 100);
  }
}
