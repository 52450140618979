
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenActionType } from './GenActionType';
import { GenTask } from './GenTask';
import { GenUser } from './GenUser';
import * as moment from 'moment';

export class GenUserAction {

    id: number;
    occurred: moment.Moment;
    targetId: number;
    task: GenTask;
    type: GenActionType;
    user: GenUser;
    usersId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genUserAction: GenUserAction);
    constructor( id: number );

    constructor( input?: GenUserAction|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.occurred && typeof input.occurred === 'string' ) {
                this.occurred = moment(input.occurred).utc();
            } else {
                this.occurred = input.occurred;
            }
            this.targetId = input.targetId; // int [number]
            if( input.task ) {
              this.task = new GenTask( input.task );
            }
            this.type = (typeof input.type === 'string') ? GenActionType.forName(<string> input.type) : <any> input.type; // GenActionType
            if( input.user ) {
              this.user = new GenUser( input.user );
            }
            this.usersId = input.usersId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenUserAction[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUserAction(a));
        } else {
          return [];
        }
    }
}

