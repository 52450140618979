
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenGistRoleDefinition {

    authority: string;
    description: string;
    name: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genGistRoleDefinition: GenGistRoleDefinition);

    constructor( input?: GenGistRoleDefinition ) {
        if ( input && typeof input !== 'number' ) {
            this.authority = input.authority; // string [string]
            this.description = input.description; // string [string]
            this.name = input.name; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenGistRoleDefinition[] {
        if (arr && arr.map) {
          return arr.map(a => new GenGistRoleDefinition(a));
        } else {
          return [];
        }
    }
}

