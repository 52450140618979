<div class="full-width full-height" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-12]="true">
  <div class="full-width title truncate">{{title}}</div>
  <div *ngIf="file" class="full-width file-container" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-2]="true">
    <div [class.flex-grow]="true" class="file-name truncate">{{fileDisplay}}</div>
    <button mat-icon-button (click)="delete()">
      <mat-icon class="file-button">delete</mat-icon>
    </button>
    <button *ngIf="canDelete" mat-icon-button (click)="download()">
      <mat-icon class="file-button">save_alt</mat-icon>
    </button>
  </div>
  <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-2]="true">
    <div class="full-width drop-container" portalDropFile (onFileDropped)="fileDropped($event)" [class.flex-column]="true" [class.flex-align-center-center]="true">
      <div>Drag and drop or <span class="browse clickable" (click)="openFileBrowser()">browse</span></div>
    </div>
    <div *ngIf="optional" class="optional">Optional</div>
  </div>
</div>

<input #fileInput (change)="onFileInputChange()" type="file">
