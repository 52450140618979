
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUser } from './GenUser';

export class GenEmail {

    body: string;
    recipients: GenUser[];
    subject: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genEmail: GenEmail);

    constructor( input?: GenEmail ) {
        if ( input && typeof input !== 'number' ) {
            this.body = input.body; // string [string]
            if ( input.recipients && input.recipients.map ) { // list [GenUser]
                this.recipients = input.recipients.map( ( x: GenUser ) => {
                    return new GenUser( x );
                } );
            } else {
                this.recipients = input.recipients;
            }
            this.subject = input.subject; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenEmail[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEmail(a));
        } else {
          return [];
        }
    }
}

