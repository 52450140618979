import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentTagService {
  constructor(private http: HttpClient) {
  }

  getAll(): Observable<string[]> {
      return this.http.get('/api/task/tag').pipe(map(obj => {
          return <string[]> obj;
      }));
  }
}
