<div mat-dialog-title class="dialog-header">
  <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-8]="true">
    <mat-icon>add</mat-icon>
    <div>Add Role</div>
  </div>
</div>
<div #content mat-dialog-content [class.flex-column]="true" [class.flex-align-start-center]="true">
  <div class="content full-width" [class.flex-column]="true" [class.flex-align-start-center]="true"
       [class.gap-16]="true">
    <div class="full-width info">
      To one add role, type in the new role and select the save button. To add multiple roles, type in the role and hit
      enter after each string. Select the save button when complete.
    </div>
    <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Add New Role</mat-label>
        <input matInput [(ngModel)]="roleToAdd" (keyup.enter)="pushRole()">
      </mat-form-field>
      <div *ngIf="nameInUse" class="in-use-error">A role with that name is already in use!</div>
    </div>
    <mat-chip-listbox class="full-width chip-list">
      <mat-chip-option class="chip" *ngFor="let role of roles; let i = index">
        <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-8]="true">
          <div>{{ role }}</div>
          <mat-icon (click)="removeRole(i)" class="chip-close-icon">cancel</mat-icon>
        </div>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>

<mat-dialog-actions [class.flex-row]="true" [class.flex-align-end-center]="true" [class.gap-12]="true">
  <button mat-raised-button [class.flex-145]="true" (click)="close()">CANCEL</button>
  <button mat-raised-button color="primary" [class.flex-145]="true" [disabled]="!isValid" (click)="save()">SAVE</button>
</mat-dialog-actions>
