import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {UserService} from 'src/app/services/user.service';


@Component({
  selector: 'authentication-form',
  templateUrl: 'authentication-form.component.html',
  styleUrls: ['authentication-form.component.scss']
})

export class AuthenticationFormComponent {

  username: string;
  password: string;
  authenticationFailed: boolean = false;
  authorizationFailed: boolean = false;
  adminEmail: string;
  passwordAuthenticationEnabled: boolean = true;
  loginError: boolean = false;
  hidePass: boolean = true;
  rememberMe = true;

  constructor(private authenticationService: AuthenticationService, public router: Router,
              public userService: UserService) {
    this.adminEmail = (window as any).data.adminEmail;
    this.passwordAuthenticationEnabled = window.location.host.indexOf('.sgov.gov') === -1;

    this.loginError = window.location.href.indexOf('login_error') > 0;
    this.userService.me()
      .subscribe((user) => {
        if (!user.roles || user.roles.length === 0) {
          this.router.navigateByUrl('/survey');
        } else {
          this.router.navigateByUrl('/');
        }
      });

  }

  login(): void {
    this.authenticationService.login(this.username || ' ', this.password, this.rememberMe)
      .subscribe(
        authPass => {
          console.log('auth passed!');
          if (authPass) {
            // noinspection JSIgnoredPromiseFromCall
            this.router.navigate(['/'], {
              queryParamsHandling: 'preserve'
            });
          } else {
            this.authenticationFailed = true;
            this.authorizationFailed = false;
          }
        },
        (err: any) => {
          this.authenticationFailed = err.status !== 403;
          this.authorizationFailed = err.status === 403;
        }
      );
  }

  pki(): void {
    window.location.assign('/pki');
  }
}
