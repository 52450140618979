
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenActionType {
    static readonly PAGE_LOAD: GenActionType = new GenActionType('PAGE_LOAD');
    static readonly DOWNLOAD: GenActionType = new GenActionType('DOWNLOAD');
    static readonly MODIFY_ACCOUNT: GenActionType = new GenActionType('MODIFY_ACCOUNT');
    static readonly APPROVE_ACCOUNT: GenActionType = new GenActionType('APPROVE_ACCOUNT');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'PAGE_LOAD') return GenActionType.PAGE_LOAD;
        if (name === 'DOWNLOAD') return GenActionType.DOWNLOAD;
        if (name === 'MODIFY_ACCOUNT') return GenActionType.MODIFY_ACCOUNT;
        if (name === 'APPROVE_ACCOUNT') return GenActionType.APPROVE_ACCOUNT;
        throw new Error('Unexpected value for GenActionType:' + name);
    }

    static values(): GenActionType[] {
        return [
            GenActionType.PAGE_LOAD,
            GenActionType.DOWNLOAD,
            GenActionType.MODIFY_ACCOUNT,
            GenActionType.APPROVE_ACCOUNT,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

