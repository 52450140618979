import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {GenEmail} from '../generated/serverModels/GenEmail';
import {User} from '../types/user.class';
import {GenRoleDefinition} from "../generated/serverModels/GenRoleDefinition";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public roles: BehaviorSubject<Map<string, GenRoleDefinition>> = new BehaviorSubject<Map<string, GenRoleDefinition>>(undefined);

  constructor(public http: HttpClient) {
  }

  public setRoleMap(roles: GenRoleDefinition[]) {
    const roleMap: Map<string, GenRoleDefinition> = new Map<string, GenRoleDefinition>();
    roles.forEach((role) => {
      roleMap.set(role.name, role);
    });
    this.roles.next(roleMap);
  }

  getRoles(): Observable<GenRoleDefinition[]> {
    return this.http.get<GenRoleDefinition[]>('api/security/user/roles');
  }

  addRoles(roles: GenRoleDefinition[]): Observable<GenRoleDefinition[]> {
    return this.http.post<GenRoleDefinition[]>('api/security/user/roles', roles.map(r => JSON.stringify(r)))
  }

  deleteRole(roles: GenRoleDefinition[]): Observable<GenRoleDefinition[]> {
    return this.http.post<GenRoleDefinition[]>('api/security/user/roles/delete', roles.map(r => JSON.stringify(r)));
  }

  getContributors(): Observable<User[]> {
    return this.http.get('/api/security/user?role=ROLE_CONTRIBUTOR')
      .pipe(map(array => {
        if (!Array.isArray(array)) {
          return [];
        }
        return array.map(o => {
          return new User(this.roles.value, o);
        });
      }));
  }

  getAll(searchTerm?: string): Observable<User[]> {
    return this.http.get(`/api/security/user${(searchTerm) ? '?q=' + searchTerm : ''}`)
      .pipe(map(array => {
        if (!Array.isArray(array)) {
          return [];
        }
        return array.map(o => {
          return new User(this.roles.value, o);
        });
      }));
  }

  getByUsername(username: string): Observable<User[]> {
    return this.http.get(`/api/security/user?username=${username}`)
      .pipe(map(array => {
        if (!Array.isArray(array)) {
          return [];
        }
        return array.map(o => {
          return new User(this.roles.value, o);
        });
      }));
  }

  getById(id: number): Observable<User> {
    return this.http.get(`/api/security/user/${id}`)
      .pipe(map(obj => {
        return new User(this.roles.value, obj);
      }));
  }

  approve(user: User): Observable<User> {
    let body: string = '';
    return this.http.put(`/api/security/user/${user.id}/app-man-approve`, body)
      .pipe(map(obj => {
        return new User(this.roles.value, obj);

      }));
  }

  disable(user: User): Observable<User> {
    let body: string = '';
    return this.http.put(`/api/security/user/${user.id}/app-man-disable`, body)
      .pipe(map(obj => {
        return new User(this.roles.value, obj);
      }));
  }

  save(user: User, oldPassword?: string | undefined): Observable<User> {
    return this.http.put(`/api/security/user/app-man/${user.id}?oldPassword=${oldPassword}`, this.getSimplifiedUser(user))
      .pipe(map(obj => {
        return new User(this.roles.value, obj);
      }));
  }

  getSimplifiedUser(user: User): User {
    const u = new User(null, user);
    let roles = [];
    if (u.roles.length) {
      roles = (typeof u.roles[0] === 'string') ? u.roles : u.roles.map(r => r.name);
    }
    u.roles = roles;
    return u;
  }

  me(): Observable<User> {
    return this.http.get('/api/security/user/me')
      .pipe(map(obj => {
        return new User(this.roles.value, obj);
      }));
  }

  emailUsers(email: GenEmail): Observable<void> {
    return this.http.post<void>('/api/security/user/email', email);
  }

  submitSurvey(user: User) {
    return this.http.put(`/api/security/user/${user.id}?submitSurvey=true`, this.getSimplifiedUser(user))
      .pipe(map(obj => {
        return new User(this.roles.value, obj);

      }));
  }

  sendEmailVerificationCode(email: string): Observable<boolean> {
    return this.http.post(`/api/security/user/change-email`, email)
      .pipe(map(() => true));
  }

  checkEmailVerificationCode(code: string) {
    return this.http.post(`/api/security/user/check-email-code`, code)
      .pipe(map(() => true));
  }

  deleteUser(user: User): Observable<void> {
    return this.http.delete<void>(`/api/security/user/${user.id}`);
  }
}
