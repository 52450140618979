import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {GenUser} from 'src/app/generated/serverModels/GenUser';
import {GenWmsProcessStatus} from 'src/app/generated/serverModels/GenWmsProcessStatus';
import {GenWorkflowStatus} from 'src/app/generated/serverModels/GenWorkflowStatus';
import {
  MapPreviewDialogComponent,
} from 'src/app/modules/shared/components/map-preview-dialog/map-preview-dialog.component';
import {BreadCrumbService} from 'src/app/services/bread-crumb.service';
import {DocumentTagService} from 'src/app/services/document-tag.service';
import {TaskService} from 'src/app/services/task.service';
import {UserService} from 'src/app/services/user.service';
import {Project} from 'src/app/types/project.class';
import {PsDataset} from 'src/app/types/ps-dataset.class';
import {Task} from 'src/app/types/task.class';
import {User} from 'src/app/types/user.class';

import {DownloadStatisticsDialogComponent} from '../download-statistics-dialog/download-statistics-dialog.component';
import {FileTransferDialogComponent} from '../file-transfer-dialog/file-transfer-dialog.component';
import {WmsManagementDialogComponent} from '../wms-management-dialog/wms-management-dialog.component';
import {EditTaskDialogComponent} from '../edit-task-dialog/edit-task-dialog.component';
import * as moment from 'moment';
import {HeaderButton} from '../../../../app.component';
import {ProjectService} from '../../../../services/project.service';
import {PromptComponent, PromptData} from '../../../shared/components/prompt/prompt.component';
import {GenGenLocation} from '../../../../generated/serverModels/GenGenLocation';
import {LocationService} from '../../../../services/location.service';
import {GenScope} from '../../../../generated/serverModels/GenScope';

@Component({
  selector: 'portal-task-browser',
  templateUrl: './task-browser.component.html',
  styleUrls: ['./task-browser.component.scss']
})
export class TaskBrowserComponent implements OnInit, AfterViewInit {
  lastIframeDownload: string;
  project: Project;
  searchControl: any;
  tasks: Task[];
  allTasks: Task[];
  @ViewChild('downloadFrame', {static: true})
  downloadFrame: ElementRef;
  @ViewChild('fileList', {static: true}) fileListAnchor: ElementRef;
  projects: Project[] = [];

  searchVal = '';
  taskForMenu: Task;
  public user: User;
  public init: boolean;
  public firstSend = false;
  public onlyMine = false;
  public onlyIncomplete = false;
  public route;
  // noinspection JSMismatchedCollectionQueryUpdate
  public tags: string[];
  // noinspection JSMismatchedCollectionQueryUpdate
  public selectedTags: string[] = [];
  public currentStatus: GenWorkflowStatus;
  public currentOwnerId = -1;
  public contributorControl: UntypedFormControl;
  public filteredContributors: Observable<User[]>;
  public users: User[];
  // noinspection JSUnusedLocalSymbols
  public WMS_STATUS_NONE = GenWmsProcessStatus.None;
  // noinspection JSUnusedLocalSymbols
  public WMS_STATUS_DONE = GenWmsProcessStatus.Done;
  public WMS_STATUS_RUNNING = GenWmsProcessStatus.Running;
  public WMS_STATUS_ERROR = GenWmsProcessStatus.Error;
  statuses: GenWorkflowStatus[] = GenWorkflowStatus.values();
  searchTerm = '';
  hasSearchQueryParam = false;
  today: Date = new Date();
  publishedStartDate: moment.Moment;
  publishedEndDate: moment.Moment;
  dateTimeoutId = -1;
  cocoms: GenGenLocation[] = [];

  filterChips: {active: boolean, title: string, type: string}[] = [
    {active: false, title: 'LandScan USA', type: 'tag'},
    {active: false, title: 'LandScan Global', type: 'tag'},
    {active: false, title: 'LandScan HD', type: 'tag'},
    {active: false, title: 'USAFRICOM', type: 'cocom'},
    {active: false, title: 'USNORTHCOM', type: 'cocom'},
    {active: false, title: 'USSOUTHCOM', type: 'cocom'},
    {active: false, title: 'USCENTCOM', type: 'cocom'},
    {active: false, title: 'USEUCOM', type: 'cocom'},
    {active: false, title: 'USINDOPACOM', type: 'cocom'}
  ];

  downloadTaskIndices: boolean[] = [];
  multiSelectDownloadUrl = '';

  public sortOptions: { 'id': string, 'name': string }[] = [{
    id: 'publishedTimestamp',
    name: 'Published'
  }, {
    id: 'name',
    name: 'Name'
  }, {
    id: 'locationName',
    name: 'Country'
  }, {
    id: 'fileLength',
    name: 'Download Size'
  }, {
    id: 'releaseTimestamp',
    name: 'Anticipated'
  }, {
    id: 'completeness',
    name: 'Status'
  }];
  selectedSortOption: { 'id': string, 'name': string } = this.sortOptions[0];
  public sortDirection = 1;
  public onlyDownloadable = false;
  public onlyDownloaded = false;
  public showThumbnails = true;
  public showArchived = false;
  public attemptedDownload = false;
  public viewHasChanged = false;
  public loading = true;

  get anyTasksSelected(): boolean {
    return this.downloadTaskIndices.indexOf(true) !== -1;
  }

  get getNumberTasksSelected(): number {
    return this.downloadTaskIndices.filter(i => i).length;
  }

  constructor(private taskService: TaskService,
              public router: Router,
              public dialog: MatDialog,
              public popup: MatSnackBar,
              public documentTagService: DocumentTagService,
              public breadcrumbService: BreadCrumbService,
              public activatedRoute: ActivatedRoute,
              public userService: UserService,
              private projectService: ProjectService,
              private locationService: LocationService) {

    this.user = this.activatedRoute.snapshot.data.user;
    this.searchControl = new UntypedFormControl();
    this.contributorControl = new UntypedFormControl();
    this.locationService.getAllCOCOMs().subscribe((cocoms) => {
      this.cocoms = cocoms;
    });
    this.searchControl.valueChanges
      .pipe(startWith(''))
      .subscribe(name => {
        this.searchVal = name;
        this.applySearchFilter(name);
      });
    // this.activatedRoute.queryParams.subscribe( params => {
    //     if ( this.init ) {
    //         this.breadcrumbService.setProjectCrumb( this.route.slice( 1 ), this.project.name, this.project.id );
    //     }
    // } );
    this.documentTagService.getAll()
      .subscribe((array) => {
        this.tags = array.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0);
      });

    if (this.user.isContributor() || this.user.isPublisher() || this.user.isAdmin()) {
      this.userService.getContributors().subscribe((users) => {
        this.users = users.sort((user1, user2) => {
          return (user1.name.toLowerCase() < user2.name.toLowerCase()) ? -1 : (user1.name.toLowerCase() > user2.name.toLowerCase()) ? 1 : 0;
        }).filter((user) => {
          return user.isContributor();
        });

        if (this.contributorControl.value) {
          this.filteredContributors = this.contributorControl.valueChanges
            .pipe(map(name => {
              return this.filterUsers(name);
            }));
        } else {
          this.filteredContributors = this.contributorControl.valueChanges
            .pipe(startWith(undefined),
              map(name => {
                return this.filterUsers(name);
              }));
        }
      });
    }
  }

  delayedRefresh() {
    if (this.dateTimeoutId !== -1) {
      window.clearTimeout(this.dateTimeoutId);
      this.dateTimeoutId = -1;
    }
    this.dateTimeoutId = window.setTimeout(() => {
      this.dateTimeoutId = -1;
      this.applySearchFilter(this.searchVal);
    }, 1000);
  }

  ngAfterViewInit(): void {
    this.downloadFrame.nativeElement.onload = () => {
      if (!this.attemptedDownload) {
        // Soo... Firefox fires this when the iframe is added before anything has been clicked.
        return;
      }
      let retryFailed = false;
      try {
        if (this.lastIframeDownload) {
          window.open(this.lastIframeDownload);
        } else {
          retryFailed = true;
        }
      } catch (e) {
        retryFailed = true;
      }
      if (retryFailed) {
        window.alert('Download failed...');
      }
    };
  }

  ngOnInit(): void {
    this.user = this.activatedRoute.snapshot.data.user;
    this.showArchived = this.user.isContributor();
    this.route = this.router.url.split('?')[0].split('/');
    this.searchTerm = this.activatedRoute.snapshot.queryParamMap.get('q');
    this.hasSearchQueryParam = !!this.activatedRoute.snapshot.queryParamMap.has('q');
    this.project = this.activatedRoute.snapshot.data.project;
    if (this.project && this.project.name.toLowerCase().includes('landscan')) {
      this.filterChips.push(
        {active: false, title: 'National', type: 'scope'},
        {active: false, title: 'Subnational', type: 'scope'}
      );
    }
    this.allTasks = [];
    this.tasks = [];
    this.loading = true;
    this.projects = [this.project];
    if (!this.project && this.searchTerm) {
      this.projectService.getAll().subscribe((projects) => {
        this.projects = projects;
      });
    }
    this.getTasks();

    // if ( !this.firstSend ) {
    //     this.breadcrumbService.setProjectCrumb( this.route.slice( 1 ), this.project.name, this.project.id );
    //     this.firstSend = true;
    // }
    if (!this.user.isContributor()) {
      this.onlyDownloadable = true;
      this.currentStatus = GenWorkflowStatus.Complete;
    }
    this.init = true;
  }

  getTasks() {
    this.loading = true;
    if (!this.project && this.hasSearchQueryParam) {
      this.projectService.getAll().subscribe((projects) => {
        this.projects = projects;
      });
      this.taskService.getAll(this.searchTerm)
        .subscribe((array) => {
          const documentationTasks: Task[] = [];
          for (let i = array.length - 1; i >= 0; i--) {
            if (array[i].sticky) {
              documentationTasks.unshift(...array.splice(i, 1));
            }
          }
          this.allTasks = array;
          this.allTasks.unshift(...documentationTasks);
          this.applySearchFilter(this.searchVal);
        }, () => {
          this.popup.open('Failed to load items', 'okay', {panelClass: ['failure']});
        }, () => {
          this.loading = false;
        });
    } else {
      this.taskService.queryByProject(this.project.id, this.searchTerm)
        .subscribe((array) => {
          const documentationTasks: Task[] = [];
          for (let i = array.length - 1; i >= 0; i--) {
            if (array[i].sticky) {
              documentationTasks.unshift(...array.splice(i, 1));
            }
          }
          this.allTasks = array;
          this.allTasks.unshift(...documentationTasks);
          this.applySearchFilter(this.searchVal);
        }, () => {
          this.popup.open('Failed to load items', 'okay', {panelClass: ['failure']});
        }, () => {
          this.loading = false;
        });
    }
  }


  previewMap(task: Task) {
    const layerNames = task.url.map(val => {
      return val.split('/').pop();
    });

    const data = {
      taskId: task.id,
      layerNames
    };
    this.dialog.open(MapPreviewDialogComponent, {
      data,
      autoFocus: false,
      width: '90vw',
      height: '90vh',
      panelClass: 'dialog-with-no-padding'
    });
  }

  updateArchiveStatus(task: Task, status: boolean) {
    task.archived = status;
    this.taskService.save(task, null).subscribe((e) => {
      if (e instanceof XMLHttpRequest) {
        this.applySearchFilter(this.searchVal);
      }
    }, (error) => {
      console.error(error);
    });
  }

  public filterUsers(val: string) {
    if (!val || !val.toLowerCase) {
      return this.users;
    }
    val = val.toLowerCase().trim();
    const searchArr = val.split(' ');
    return this.users.filter(user => {
      const haystack = [
        user.firstName,
        user.lastName,
        user.email,
        user.name
      ]
        .concat(user.roles.map(role => role.name))
        .map(str => str.toLowerCase())
        .join();
      return ((!this.project.members || this.project.members.indexOf(user) === -1) && searchArr.every((val) => haystack.indexOf(val) !== -1));
    });
  }

  get newTasksPresent(): boolean {
    return this.tasks.map(t => this.isNew(t)).indexOf(true) !== -1;
  }

  concatName(user: GenUser) {
    return (user) ? `${user.firstName} ${user.lastName}` : ``;
  }

  displayUserInfo(user: User) {
    return (!user) ? '' : `${user.name}, ${user.firstName} ${user.lastName}, ${user.email}`;
  }

  get canCreateWMS(): boolean {
    return this.user.isContributor() && (this.user.username === 'ax6' || this.user.username === '6nr' || this.user.username === 'azm');
  }

  fetch(task: Task): void {
    this.attemptedDownload = true;
    const iframe = this.downloadFrame.nativeElement;

    const downloadPath: string = task.getDownloadPath();
    if (iframe && !task.originalFilename.toLowerCase().endsWith('.pdf')) {
      this.lastIframeDownload = downloadPath;
      try {
        iframe.src = downloadPath;
      } catch (e) {
        window.open(this.lastIframeDownload);
        this.lastIframeDownload = undefined;
      }
    } else {
      this.lastIframeDownload = undefined;
      window.open(downloadPath);
    }

    this.trackDownload(task);
  }

  trackDownload(task: Task) {
    try {
      if (task.myDownloadCount <= 0) {
        task.myDownloadCount = 1;
      }
      return true;
    } catch (err) {
      console.error(err);
      return true;
    }
  }

  edit(task: Task): void {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '60vw',
      maxHeight: '90vh',
      data: {
        user: this.user,
        task,
        project: this.project
      }
    };
    this.dialog.open(EditTaskDialogComponent, configData).afterClosed().subscribe((updatedTask: Task) => {
      if (updatedTask) {
        const origIndex = this.allTasks.findIndex(t => t.id === task.id);
        if (origIndex !== -1) {
          this.allTasks[origIndex] = updatedTask;
          this.allTasks = this.allTasks.slice();
        }
        this.applySearchFilter(this.searchVal);
      }
    });
  }

  remove(taskToDelete: Task, disabled: boolean): void {
    if (!disabled) {
      const promptData: PromptData = {
        level: 'high',
        type: 'item',
        action: 'delete',
        name: taskToDelete.name
      };
      this.dialog.open(PromptComponent, {data: promptData, panelClass: 'dialog-with-no-padding', width: '50vw'})
        .afterClosed()
        .subscribe(confirmed => {
          if (confirmed) {
            this.taskService.remove(taskToDelete).subscribe(() => {
              this.popup.open('Task Deleted', 'okay', {duration: 2000, panelClass: ['success']});
              this.allTasks = this.allTasks.filter(d => d !== taskToDelete);
              this.applySearchFilter(this.searchVal);
            });
          }
        });
    }
  }

  add(): void {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '55vw',
      maxHeight: '98vh',
      panelClass: 'dialog-with-no-padding',
      data: {
        user: this.user,
        project: this.project
      }
    };
    this.dialog.open(EditTaskDialogComponent, configData).afterClosed().subscribe((task: Task) => {
      if (task) {
        this.allTasks.push(task);
        this.applySearchFilter(this.searchVal);
      }
    });
  }

  getProjectSrc(project: Project) {
    if (project.thumbnail) {
      return project.getThumbnailPath();
    } else {
      switch (project.name) {
        case 'Building Feature Extraction':
          return 'assets/login_illustration.png';
        case 'LandScan':
        case 'HSIP':
          return 'assets/landscan.png';
        case 'NGA Data':
          return 'assets/NGA.png';
        case 'Points of Interest':
          return 'assets/pointsofinterest.png';
        default:
          return '';
      }
    }
  }

  applySearchFilter(searchText: string): void {
    if (!this.allTasks) { return; }

    this.tasks = this.allTasks.filter(task => {
      let isIn = true;
      if (this.onlyMine) {
        isIn = isIn && task.contributor.id === this.user.id;
      }
      if (this.onlyIncomplete) {
        isIn = isIn && task.percentageComplete() !== 1;
      }
      if (this.onlyDownloadable) {
        isIn = isIn && task.fileLength > 0;
      }
      if (this.onlyDownloaded) {
        isIn = isIn && task.myDownloadCount === 0;
      }
      if (!this.showArchived) {
        isIn = isIn && !task.archived;
      }
      if (this.selectedTags.length) {
        isIn = this.selectedTags.every(selectedTag => {
          return task.tags.some(taskTag => taskTag === selectedTag);
        });
      }
      if (this.currentOwnerId >= 0) {
        isIn = isIn && task.contributor.id === this.currentOwnerId;
      }
      if (this.currentStatus) {
        isIn = isIn && task.status === this.currentStatus;
      }
      if (this.publishedStartDate && this.publishedStartDate.isValid()) {
        isIn = isIn && task.publishedTimestamp && task.publishedTimestamp.isAfter(this.publishedStartDate);
      }
      if (this.publishedEndDate && this.publishedEndDate.isValid()) {
        isIn = isIn && task.publishedTimestamp && task.publishedTimestamp.isBefore(this.publishedEndDate);
      }
      const tagFilters = this.filterChips.filter(c => c.active && c.type === 'tag').map(c => c.title);
      const cocomFilters = this.filterChips.filter(c => c.active && c.type === 'cocom').map(c => c.title);

      if (tagFilters.length) {
        isIn = isIn && task.tags.map(t => tagFilters.indexOf(t) !== -1).indexOf(true) !== -1;
      }

      if (cocomFilters.length) {
        const cocom = this.cocoms.find(c => c.cocomUqid === task.location.cocomUqid);
        isIn = isIn && cocom && cocomFilters.indexOf(cocom.name) !== -1;
      }

      if (this.filterChips.filter(c => c.active && c.title === 'National').length) {
        isIn = isIn && task.scope == GenScope.National;
      }

      if (this.filterChips.filter(c => c.active && c.title === 'Subnational').length) {
        isIn = isIn && task.scope == GenScope.Subnational;
      }

      return isIn;
    });

    if (searchText) {
      const terms: string[] = searchText.toLowerCase().split(' ');
      this.tasks = this.tasks.filter(task => {
        return terms.every((term) => {
          if (task.originalFilename && task.originalFilename.toLowerCase().indexOf(term) >= 0) {
            return true;
          }
          if (task.location && task.location.name && task.location.name.toLowerCase().indexOf(term) >= 0) {
            return true;
          }
          if ('global'.indexOf(term) >= 0 && (!task.location || !task.location.name)) {
            return true;
          }
          if (task.tags && task.tags.find(tag => tag.indexOf(term) >= 0)) {
            return true;
          }
          //noinspection RedundantIfStatementJS
          if (task.releaseNotes && task.releaseNotes.toLowerCase().indexOf(term) >= 0) {
            return true;
          }
          return task.contributor && (task.contributor.firstName.toLowerCase().includes(term) || task.contributor.lastName.toLowerCase().includes(term));

        });
      });
    }
    this.tasks = this.tasks.slice();
    this.downloadTaskIndices = new Array(this.tasks.length);
    this.downloadTaskIndices.fill(false);
  }

  isComplete(task: Task): boolean {
    return task && task.status === GenWorkflowStatus.Complete;
  }

  isLandScan(task: Task): boolean {
    return task && (task.tags.indexOf('LandScan HD') !== -1 || task.tags.indexOf('LandScan Global') !== -1);
  }

  prettyStatus(status: GenWorkflowStatus): string {
    return status.name.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  viewDownloadDetails(task: Task) {
    this.dialog.open(DownloadStatisticsDialogComponent, {
      data: {
        task
      }
    });
  }

  public checkIfApply(value: string) {
    if (!value || value.length === 0) {
      this.currentOwnerId = -1;
      this.applySearchFilter(this.searchVal);
    }
  }

  public getDownloadURL(): string {
    let path = '/api/task/multi-download?';
    let first = true;
    this.tasks.forEach((task) => {
      if (task.myDownloadCount === 0 && task.fileLength > 0) {
        if (!first) {
          path += '&';
        }
        path += `id=${task.id}`;
        first = false;
      }
    });
    return path;
  }

  public getMultiSelectURL(): string {
    let path = '/api/task/multi-download?';
    let first = true;
    this.downloadTaskIndices.filter(i => i).forEach((value, index) => {
      if (!first) {
        path += '&';
      }
      path += `id=${this.tasks[index].id}`;
      first = false;
    });
    return path;
  }


  public downloadSpreadSheetURL(): string {
    let idQuery = 'id=' + this.tasks.map((task) => task.id).join(',');
    if (this.searchTerm) {
      idQuery += `&prefix=${this.searchTerm}`;
    }
    return `/api/task/download-spreadsheet?${idQuery}`;
  }

  public refreshTasks() {
    this.tasks.forEach((task) => {
      if (task.myDownloadCount === 0 && task.fileLength > 0) {
        task.myDownloadCount = 1;
      }
    });
    this.applySearchFilter(this.searchVal);
  }

  refreshSelectedTasks() {
    this.downloadTaskIndices.filter(i => i).forEach((value, index) => {
      this.tasks[index].myDownloadCount++;
    });
    this.applySearchFilter(this.searchVal);
  }


  public openDatasetModal(task: Task) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        filename: task.originalFilename,
      }
    };

    const upload = (dataset: PsDataset) => {
      this.taskService.createWms(task, dataset.createCountData)
        .subscribe(() => {
          this.popup.open('Your dataset is ready to preview!', 'okay', {
            duration: 5000,
            panelClass: ['success']
          });
        }, () => {
          this.popup.open('There was an error creating the services.', 'okay', {
            duration: 6000,
            panelClass: ['failure']
          });
        });
    };

    this.dialog.open(FileTransferDialogComponent, config).afterClosed()
      .pipe(filter(val => !!val))
      .subscribe((dataset: PsDataset) => {
        this.popup.open('Your WMS layer is being created.', 'okay', {
          duration: 5000,
          panelClass: ['success']
        });

        upload(dataset);
      });
  }

  public manageWms(task: Task): void {
    const layerName = task.url[0].split('/').pop();
    this.taskService.getLayer(layerName).subscribe(res => {
      const config: MatDialogConfig = {
        data: res,
        disableClose: true
      };

      this.dialog.open(WmsManagementDialogComponent, config).afterClosed()
        .pipe(filter(val => !!val))
        .subscribe(layer => {
          this.taskService.updateLayer(layer).subscribe(() => {
            this.popup.open('WMS Layer Updated', 'okay', {
              duration: 5000,
              panelClass: ['success']
            });
          });
        });
    });
  }

  isNew(task: Task): boolean {
    const c1 = task.myDownloadCount === 0;
    const c2 = task.publishedTimestamp && moment().diff(moment(task.publishedTimestamp), 'days') <= 14;
    const c3 = task.fileLength > 0;

    return c1 && c2 && c3;
  }

  downloadCheckClicked(event: MouseEvent, index: number) {
    if (event.shiftKey && this.anyTasksSelected) {
      let lastSelected = -1;
      this.downloadTaskIndices.forEach((val, i) => {
        if (val) {
          lastSelected = i;
        }
      });

      if (index > lastSelected) {
        for (let i = lastSelected + 1; i < index; i++) {
          this.downloadTaskIndices[i] = true;
        }
      } else if (index < lastSelected) {
        for (let i = index + 1; i < lastSelected; i++) {
          this.downloadTaskIndices[i] = true;
        }
      }
    }
  }

  headerButtonAction(headerButton: HeaderButton) {
    if (headerButton.action === 'create') {
      this.add();
    } else if (headerButton.action === 'export') {
      (this.fileListAnchor.nativeElement as HTMLElement).click();
    }
  }

  clearFilterChips() {
    for (let i = 0; i < this.filterChips.length; i++) {
      this.filterChips[i].active = false;
    }
    this.applySearchFilter(this.searchVal);
  }
}
