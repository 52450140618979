<div class="user-survey-container" [class.flex-column]="true">
  <div [class.flex-column]="true"
       class="account-page-form"
       [class.gap-40]="true">

    <div class="flex-grow">
      <h1>
        User Info
      </h1>
      <p>
        Welcome <strong>{{ user.username }}</strong> <span *ngIf="user.emailVerified"> ( {{ user.email }} ) </span>,
      </p>

      <p *ngIf="user.emailVerified" class="instruction">
        <span class="warning">All fields are required.</span>
      </p>
      <div *ngIf="!user.emailVerified"
           [class.flex-column]="true"
           [class.gap-10]="true"
           [class.flex-align-start-stretch]="true">
        <p>
          The Data Portal requires an email address. Please enter one below. Use a .gov or .mil address if you have one.
        </p>
        <p style="font-style: italic">
          If you have trouble with this process, contact <a [href]="'mailto:' + adminEmail">{{adminEmail}}</a> for
          assistance.
        </p>
        <mat-form-field>
          <input matInput
                 type="email"
                 placeholder="Email (use .mil or .gov address if available)"
                 [(ngModel)]="user.email"
                 name="email"/>
        </mat-form-field>

        <button mat-raised-button
                color="primary"
                [disabled]="justSentVerificationCode || !user.email"
                (click)="sendEmailVerificationCode()">
          <mat-icon *ngIf="verificationCodeSent">done</mat-icon>
          {{getSendButtonText()}}
        </button>
      </div>

      <div *ngIf="!user.emailVerified && verificationCodeSent"
           [class.flex-column]="true"
           [class.gap-10]="true"
           [class.flex-align-start-stretch]="true">

        <mat-form-field>
          <input matInput
                 placeholder="Verification Code"
                 [(ngModel)]="verificationCode"
                 name="verificationCode"/>
        </mat-form-field>

        <button mat-raised-button
                color="primary"
                [disabled]="user.emailVerified || !verificationCode"
                (click)="checkEmailVerificationCode()">
          <mat-icon *ngIf="user.emailVerified">done</mat-icon>
          Verify Email
        </button>
      </div>

      <div *ngIf="user.emailVerified" [class.flex-column]="true" [class.gap-10]="true">
        <div [class.flex-column]="true"
             [class.gap-10]="true"
             [class.flex-align-start-stretch]="true">

          <label>Are you a contractor? <span *ngIf="user.contractor === undefined" class="required">*</span></label>
          <mat-radio-group color="primary" [class.flex-column]="true" [class.flex-align-start-start]="true"
                           [class.gap-2]="true"
                           name="contractor"
                           [disabled]="contractorAlreadySet"
                           [(ngModel)]="user.contractor">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>

          <mat-form-field class="full-width no-bottom-padding" appearance="outline">
            <mat-label>Agency</mat-label>
            <input type="text"
                   matInput
                   autocomplete="off"
                   [formControl]="agencyControl"
                   [matAutocomplete]="agencyAuto">
            <mat-autocomplete autoActiveFirstOption #agencyAuto="matAutocomplete">
              <mat-option *ngFor="let option of filteredAgencies | async" [value]="option.name"
                          (onSelectionChange)="selectAgency($event, option)">
                {{option.name}}
              </mat-option>
              <mat-option value="Other" (onSelectionChange)="selectAgency($event, 'Other')">Other</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field *ngIf="selectedAgency === 'Other'" class="no-bottom-padding full-width"
                          appearance="outline">
            <mat-label>Other Agency's Name</mat-label>
            <input matInput [(ngModel)]="otherAgency">
          </mat-form-field>

          <mat-form-field *ngIf="!selectedAgency || (selectedAgency === 'Other')"
                          class="full-width no-bottom-padding"
                          appearance="outline"
                          [matTooltipDisabled]="selectedAgency === 'Other'"
                          matTooltip="Please select a parent agency first">
            <mat-label>Sub-Agency</mat-label>
            <input matInput disabled="true" [(ngModel)]="selectedSubAgency">
          </mat-form-field>

          <mat-form-field *ngIf="selectedAgency && selectedAgency !== 'Other'" class="full-width no-bottom-padding"
                          appearance="outline">
            <mat-label>Sub-Agency</mat-label>
            <input type="text"
                   matInput
                   autocomplete="off"
                   [formControl]="subAgencyControl"
                   [matAutocomplete]="subAgencyAuto">
            <mat-autocomplete autoActiveFirstOption #subAgencyAuto="matAutocomplete">
              <mat-option *ngFor="let option of filteredSubAgencies | async" [value]="option.name"
                          (onSelectionChange)="selectSubAgency($event, option)">
                {{option.name}}
              </mat-option>
              <mat-option value="Other" (onSelectionChange)="selectSubAgency($event, 'Other')">Other</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field *ngIf="selectedSubAgency === 'Other'" class="no-bottom-padding full-width"
                          appearance="outline">
            <mat-label>Other Sub-Agency's Name</mat-label>
            <input matInput [(ngModel)]="otherSubAgency">
          </mat-form-field>

          <mat-form-field class="no-bottom-padding full-width" appearance="outline">
            <mat-label>Department</mat-label>
            <input matInput [(ngModel)]="department">
          </mat-form-field>


          <mat-form-field class="no-bottom-padding" [class.flex-grow-auto]="true" appearance="outline">
            <mat-label>Reason for requesting an account</mat-label>
            <textarea matInput
                      name="requestReason"
                      [(ngModel)]="user.requestReason"
                      class="info-textarea-size"></textarea>
          </mat-form-field>

          <p>
            This information is used by NGA to report metrics to leadership in support of ORNL's
            LandScan program and is a requirement for access to the Portal.
          </p>

          <div *ngIf="!isGovOrMil((this.user) ? this.user.email : '')" style="border-top: 1px dashed #a6a6a6"
               class="full-width" [class.flex-column]="true" [class.gap-10]="true"
               [class.flex-align-start-stretch]="true">
            <div class="primary-c poc-warning">Your login is not associated with a valid .gov or .mil email address.
              If you are currently in a partnership with or working on behalf of the US federal government, please
              provide the required information below:
            </div>
            <label>US Federal Government Point of Contact Information</label>
            <mat-form-field class="no-bottom-padding" [class.flex-grow-auto]="true" appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="user.pocName">
            </mat-form-field>
            <mat-form-field class="no-bottom-padding" [class.flex-grow-auto]="true" appearance="outline">
              <mat-label>Organization</mat-label>
              <input matInput [(ngModel)]="user.pocOrganization">
            </mat-form-field>
            <mat-form-field class="no-bottom-padding" [class.flex-grow-auto]="true" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="user.pocEmail">
            </mat-form-field>
            <div *ngIf="validateEmail(this.user.pocEmail) && !isGovOrMil(this.user.pocEmail)"
                 class="poc-email-warning warn-c">Your point of contact must have a .gov or .mil email address
            </div>
            <mat-form-field class="no-bottom-padding" [class.flex-grow-auto]="true" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input matInput [(ngModel)]="user.pocPhone">
            </mat-form-field>
          </div>
        </div>


        <button mat-raised-button
                color="primary"
                [disabled]="submitted || !isFormValid()"
                (click)="submit()">
          <mat-icon *ngIf="submitted">done</mat-icon>
          Submit
        </button>

        <p *ngIf="submitted" style="color: green; font-weight: bold;">
          Thank you for your information. Your account will be reviewed shortly by our site admin.
        </p>
      </div>
    </div>

  </div>
</div>
