
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenLabel {

    color: string;
    name: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genLabel: GenLabel);

    constructor( input?: GenLabel ) {
        if ( input && typeof input !== 'number' ) {
            this.color = input.color; // string [string]
            this.name = input.name; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenLabel[] {
        if (arr && arr.map) {
          return arr.map(a => new GenLabel(a));
        } else {
          return [];
        }
    }
}

