import {GenRoleDefinition} from '../generated/serverModels/GenRoleDefinition';
import {GenUser} from '../generated/serverModels/GenUser';

export class User extends GenUser {

  public roleMap: Map<string, GenRoleDefinition> = new Map<string, GenRoleDefinition>();
  public canLogin = false;

  constructor(roleMap: Map<string, GenRoleDefinition>, data?: any) {
    super(data);
    this.roleMap = roleMap;
    if (data?.roles && typeof data?.roles[0] === 'string') {
      if (roleMap) {
        this.roles = data.roles.map(r => this.roleMap.get(r));
      } else {
        this.roles = data.roles.map(name => new GenRoleDefinition({
          name: name,
          authority: name
        }));
      }
    }
    this.canLogin = data?.enabled && data?.approved && !!data?.roles.length;
  }

  hasRole(role: GenRoleDefinition | string): boolean {
    if (!this.roles && !role) {
      return false;
    }
    if (typeof role === 'string') {
      // @ts-ignore
      const r: string[] = (typeof this.roles[0] === 'string') ? this.roles : this.roles.map(rr => rr.name);
      return r.some((userRole: string) => userRole === role);
    } else {
      return this.roles.some((userRole: GenRoleDefinition) => userRole?.name === role.name);
    }
  }

  hasAnyRole(roles: any[]): boolean {
    if (roles.length === 0) return true;
    if (typeof roles[0] === 'string') {
      return roles.some((role: string) => this.hasRole(role));
    } else {
      return roles.some((role: GenRoleDefinition) => this.hasRole(role));
    }
  }

  isApproved(): boolean {
    return this.approved && this.enabled;
  }

  isContributor(): boolean {
    return this.hasRole(this.roleMap.get('ROLE_CONTRIBUTOR'));
  }

  isNga(): boolean {
    return this.hasRole(this.roleMap.get('ROLE_NGA'));
  }

  isAdmin(): boolean {
    return this.hasRole(this.roleMap.get('ROLE_ADMIN'));
  }

  isPublisher(): boolean {
    return this.hasRole(this.roleMap.get('ROLE_PUBLISHER'));
  }

  isSponsor(): boolean {
    return this.hasRole(this.roleMap.get('ROLE_SPONSOR'));
  }

  setApproved(value: boolean): void {
    this.approved = true;
    this.enabled = true;
    this.changeRole(value, this.roleMap.get('ROLE_APPROVED_USER'));
  }

  changeRole(value: boolean, role: GenRoleDefinition) {
    if (value && !this.hasRole(role)) {
      this.roles.push(role);
    }
    if (!value && this.hasRole(role)) {
      this.roles = this.roles.filter(r => r !== role);
    }
  }

  isLastLoginRecent(): boolean {
    let ageInMillis = (new Date().valueOf() - this.lastLoginDate.valueOf());
    return ageInMillis < (1000 * 60 * 60 * 24 * 30);
  }

  hasUsaService(): boolean {
    return this.hasAnyRole([this.roleMap.get('ROLE_KEY_USA'), this.roleMap.get('ROLE_ADMIN')]);
  }

  hasHdService(): boolean {
    return this.hasAnyRole([this.roleMap.get('ROLE_KEY_HD'), this.roleMap.get('ROLE_ADMIN')]);
  }

  hasPdtApi(): boolean {
    return this.hasAnyRole([this.roleMap.get('ROLE_KEY_PDT_API'), this.roleMap.get('ROLE_ADMIN')]);
  }

  hasGlobalService(): boolean {
    return this.hasAnyRole([this.roleMap.get('ROLE_KEY_USA'), this.roleMap.get('ROLE_ADMIN')]);
  }

  isApplicationManager() {
    return this.hasAnyRole([this.roleMap.get('ROLE_ADMIN'), this.roleMap.get('ROLE_APPLICATION_MANAGER')]);
  }
}
