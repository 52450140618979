import {Component} from '@angular/core';

@Component({
  selector: 'portal-poc-landing',
  template: '<div style="position: absolute; top: 100px; left: 0px; right: 0px; text-align: center">Waiting on response from the server...</div>'
})
export class PocLandingComponent {

  constructor() {
  }



}
