<div mat-dialog-title>
  Dataset Options for {{filename}}
</div>
<div mat-dialog-content>

  <p>
    Each layer's style will be inferred from the name of the directory containing the .adf file.
    Directory names containing "day", "night", "global", or "hd" will get a style of the same name.
    If no style can be inferred, a default style will be used.
  </p>
  <!--Count-->
  <mat-checkbox [(ngModel)]="dataset.createCountData">
    Create Count Services
  </mat-checkbox>

</div>
<div mat-dialog-actions [class.flex-row]="true" [class.flex-align-end-center]="true" [class.gap-5]="true">
  <button mat-raised-button (click)="dialogRef.close()">
      Cancel
  </button>
  <button mat-raised-button color="primary" (click)="uploadDataset()">
      Create WMS
  </button>

</div>
