import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PsDatasetStyle } from '../types/ps-dataset-style.class';
import { IPsLayer } from '../types/ps-layer.interface';
import { Task } from '../types/task.class';
import {User} from "../types/user.class";

@Injectable({
  providedIn: 'root'
})
export class TaskService {

    public taskChange: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(private http: HttpClient) {
    }

    getAll(searchTerm?: string): Observable<Task[]> {
        return this.http.get(`/api/task${(searchTerm) ? '?q=' + searchTerm : ''}`).pipe(map(array => {
            if (!Array.isArray(array)) {
                return [];
            }
            return array.map(o => new Task(o));
        }));
    }

    getRecentTasks(): Observable<Task[]> {
      return this.http.get('/api/task/recent').pipe(map(array => {
        if (!Array.isArray(array)) {
          return [];
        }
        return array.map(o => new Task(o));
      }));
    }

    queryByProject(projectId: number, searchTerm?: string): Observable<Task[]> {
        return this.http.get(`/api/task?projectId=${projectId}${(searchTerm) ? '&q=' + searchTerm : ''}`).pipe(map(array => {
            if (!Array.isArray(array)) {
                return [];
            }
            return array.map(o => new Task(o));
        }));
    }

    remove(document: Task): Observable<Object> {
        return this.http.delete(`/api/task/${document.id}`);
    }

    getById(id: number): Promise<Task> {
        return this.http.get<Task>(`/api/task/${id}`).pipe(map(obj => new Task(obj))).toPromise();
    }

    save(document: Task, file: any, thumbnail?: any): Observable<XMLHttpRequest | ProgressEvent> {
        let formData = new FormData();
        let xhr = new XMLHttpRequest();

        if (file) {
            formData.append('file', file);
        }

        if (thumbnail) {
            formData.append('thumbnail', thumbnail);
        }

        formData.append('metadata', JSON.stringify(this.getSimplifiedTask(document)));

        let subject = new ReplaySubject<XMLHttpRequest | ProgressEvent>();

        let url: string = document.id ? `/api/task/${document.id}` : `/api/task`;

        xhr.open('POST', url, true);
        xhr.upload.onprogress = (e) => {
            subject.next(e);
        };
        xhr.onerror = xhr.onload = (e) => {
            if (xhr.responseType !== undefined && xhr.status === 200) {
                subject.next(xhr);
            } else {
                subject.error(xhr);
            }
            subject.complete();
        };
        xhr.send(formData);

        return subject;
    }

    getSimplifiedTask(task: Task): Task {
      if (task.contributor) {
        const u = new User(null, task.contributor);
        let userRoles = [];
        if (u.roles.length) {
          userRoles = (typeof u.roles[0] === 'string') ? u.roles : u.roles.map(r => r.name);
        }
        u.roles = userRoles;
        task.contributor = u;
      }
      return task;
    }

    deleteFile(task: Task): Observable<Task> {
        return this.http.post<Task>(`/api/task/${task.id}/delete-file`, task);
    }

    getTasksPendingReview(): Observable<Task[]> {
        return this.http.get<Task[]>('api/task/pending-review');
    }

    public getWmsStyles(): Observable<PsDatasetStyle[]> {
        const url = 'api/task/popservices/styles';
        return this.http.get<any[]>(url)
            .pipe(map(styles => styles.map(style => new PsDatasetStyle(style))))
    }

    public createWms(task: Task, createCountServices: boolean = false): Observable<any> {
        const url = `api/task/popservices/${task.id}`;
        const body = (createCountServices) ? "{status: true}" : undefined;
        return this.http.post(url, body);
    }

    public getLayer(serviceLayer: string): Observable<IPsLayer> {
        const popservicesUrl = `api/maplayer/layer?service-layer=${serviceLayer}`;
        const url = `api/proxy/popservices?url=${popservicesUrl}`;
        return this.http.get<IPsLayer>( url );
    }

    public updateLayer(layer: IPsLayer): Observable<any> {
        const popservicesUrl = `api/maplayer`;
        const url = `api/proxy/popservices?url=${popservicesUrl}`;
        return this.http.put(url, layer);
    }

}
