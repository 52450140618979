import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {ReportingService} from 'src/app/services/reporting.service';

import {GenSiteMetricsReport} from '../../generated/serverModels/GenSiteMetricsReport';
import {GenWorkflowStatus} from "../../generated/serverModels/GenWorkflowStatus";
import {Task} from "../../types/task.class";
import {User} from "../../types/user.class";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../services/project.service";
import {Project} from "../../types/project.class";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";


@Component( {
  selector: 'portal-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: [ './reporting.component.scss' ]
} )
export class ReportingComponent implements AfterViewInit, OnInit {

  startDate: moment.Moment;
  endDate: moment.Moment;
  report: GenSiteMetricsReport;
  taskReports: TaskStatusReport[] = [];
  tasks: Task[] = [];
  newUsers: User[] = [];
  siteMetricReportCards: ReportCard[] = [];
  taskStatusReportCards: ReportCard[] = [];
  downloadReportCard: ReportCard;
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  displayedColumns: string[] = ['user', 'status', 'organization', 'downloadCount', 'requestReason'];
  loading = false;
  downloadUrl = '';
  user: User;
  projects: Project[] = [];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  statuses: GenWorkflowStatus[] = GenWorkflowStatus.values();
  currentStatus: GenWorkflowStatus;

  constructor( public reportingService: ReportingService,
               public popup: MatSnackBar,
               private activatedRoute: ActivatedRoute,
               private projectService: ProjectService) {
    this.startDate = moment().add(-1, "month").startOf("month");
    this.endDate = moment().add(-1, "month").endOf("month");
    this.getReport();

    this.projectService.getAll().subscribe((projects) => {
      this.projects = projects;
    });

  }

  ngOnInit() {
    this.user = this.activatedRoute.snapshot.data[ 'user' ];

  }

  getReport() {
    this.loading = true;
    this.reportingService.getSiteMetricsReport( this.startDate, this.endDate ).subscribe( ( report ) => {
      this.loading = false;
      this.newUsers = report.accountsCreated.map(a => new User(null, a));
      this.dataSource.data = this.newUsers;
      this.report = report;
      this.siteMetricReportCards = [
        {value: report.activeUsers, label: `Active User${(report.activeUsers > 1) ? 's' : ''}`, icon: 'activeuser'},
        {value: report.accountsCreated.length, label: `New User${(report.accountsCreated.length > 1) ? 's' : ''}`, icon: 'newuser'}
      ]
      this.downloadReportCard = {value: report.downloads, label: `Item${(report.downloads > 1) ? 's' : ''} Downloaded`, icon: 'download'}
      this.taskReports =  [];
      this.taskStatusReportCards = [];
      this.tasks = report.historicalTasks.map((t) => {
        const ret: Task = new Task(t);
        ret.status = t.historicalStatus;
        ret.downloadCount = t.downloadCount;
        return ret;
      })
      report.historicalTasks.forEach((historicalTask) => {
        if (!this.taskReports.find(r => r.status == historicalTask.historicalStatus)) {
          this.taskReports.push({
            status: (typeof historicalTask.historicalStatus === 'string') ? GenWorkflowStatus.forName(historicalTask.historicalStatus) : historicalTask.historicalStatus,
            tasks: [],
            downloadUrl: ''
          });
        }
        const task: Task = new Task(historicalTask);
        task.status = historicalTask.historicalStatus;
        task.downloadCount = historicalTask.taskDownloadCount;
        this.taskReports.find(r => r.status == historicalTask.historicalStatus).tasks.push(task);
      });
      this.taskReports = this.taskReports.sort((a, b) => {
        return this.statusOrder(a.status) - this.statusOrder(b.status);
      });
      this.taskReports.forEach((report) => {
        const reportStatus = (typeof report.status === 'string') ? GenWorkflowStatus.forName(report.status) : report.status;
        if (report.tasks.length) {
          this.taskStatusReportCards.push(
            {value: report.tasks.length, status: reportStatus, icon: reportStatus.toString().toLowerCase()}
          )
          this.taskStatusReportCards = this.taskStatusReportCards.sort((a, b) => {
            return this.statusOrder(a.status) - this.statusOrder(b.status);
          })
        }
      });
    }, ( error ) => {
      this.loading = false;
      this.popup.open( `Failed to generate report.`, 'okay', { duration: 6000, panelClass: [ 'failure' ] } );
    } );
  }

  filterTasks() {
    const allTasks = (this.currentStatus) ?
      this.report.historicalTasks.filter(t => t.historicalStatus.toString() == this.currentStatus.toString())
      : this.report.historicalTasks;
    this.tasks = allTasks.map((t) => {
      const ret: Task = new Task(t);
      ret.status = t.historicalStatus;
      ret.downloadCount = t.downloadCount;
      return ret;
    });
  }

  prettyStatus( status: GenWorkflowStatus ): string {
    return (status) ? status.toString().replace( /([a-z])([A-Z])/g, '$1 $2' ) : '';
  }

  statusLabel( status: GenWorkflowStatus, value: number ) : string {
    switch (status) {
      case GenWorkflowStatus.Potential:
        return 'Potential';
      case GenWorkflowStatus.OnHold:
        return `On Hold`;
      case GenWorkflowStatus.InQueue:
        return `In Queue`;
      case GenWorkflowStatus.InProgress:
        return `In Progress`;
      case GenWorkflowStatus.Review:
        return `In Review`;
      case GenWorkflowStatus.Complete:
        return `Dataset${(value > 1) ? 's' : ''} Delivered`;
    }
  }

  statusOrder(status: GenWorkflowStatus): number {
    switch(status) {
      case GenWorkflowStatus.OnHold:
        return 0;
      case GenWorkflowStatus.Potential:
        return 1;
      case GenWorkflowStatus.InQueue:
        return 2;
      case GenWorkflowStatus.InProgress:
        return 3;
      case GenWorkflowStatus.Review:
        return 4;
      case GenWorkflowStatus.Complete:
        return 5;
    }
  }

  isValid(): boolean {
    return !!this.startDate && !!this.endDate;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      return this.displayCell(item, property);
    }
    this.dataSource.paginator = this.paginator;
  }

  displayCell(user: User, property: string) {
    switch (property) {
      case 'user':
        return `${user.firstName} ${user.lastName}`;
      case 'status':
        if (user.enabled) {
          return 'Enabled';
        } else if (user.approved && !user.enabled) {
          return 'Disabled';
        } else if (user.approved) {
          return 'Approved';
        } else if ((!user.approved || (user.enabled && user.roles.length === 0))) {
          return 'Needs Approval or Data Access'
        }
      case 'organization':
      case 'downloadCount':
      case 'requestReason':
        return user[property];
      default:
        return '';
    }
  }
}

interface ReportCard {
  value: number;
  label?: string;
  status?: GenWorkflowStatus;
  icon: string;
}

interface TaskStatusReport {
  status: GenWorkflowStatus;
  tasks: Task[];
  downloadUrl: string;
}
