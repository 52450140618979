export class PsDatasetStyle {

 public  filename: string;
 public  name: string;

  constructor();
  constructor(jsonObj: any);
  constructor(genStyleInfoPost: PsDatasetStyle);

  constructor(input?: PsDatasetStyle) {
      if (input && typeof input !== 'number') {
          this.filename = input.filename;
          this.name = input.name;
      }
  }
}
