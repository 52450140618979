<div mat-dialog-title class="dialog-header" [ngClass]="{'scrolled': scrolled}">
  {{taskName}}
</div>
<div mat-dialog-content>
  <div #content class="flex-column" (scroll)="contentScrolled(content)">
    <portal-create-task class="full-width full-height"
                        [modalView]="true"
                        [modalViewData]="modalViewData"
                        [saveSub]="saveSub"
                        (savedTask)="taskSaved($event)"
                        (validityChange)="isValid = $event">
    </portal-create-task>
  </div>
</div>

<mat-dialog-actions class="full-width flex-row flex-align-end-center gap-12">
  <button mat-raised-button (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!isValid" (click)="save()">Save Changes</button>
</mat-dialog-actions>
