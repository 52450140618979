
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenWorkflowStatus {
    static readonly Potential: GenWorkflowStatus = new GenWorkflowStatus('Potential');
    static readonly InQueue: GenWorkflowStatus = new GenWorkflowStatus('InQueue');
    static readonly OnHold: GenWorkflowStatus = new GenWorkflowStatus('OnHold');
    static readonly InProgress: GenWorkflowStatus = new GenWorkflowStatus('InProgress');
    static readonly Review: GenWorkflowStatus = new GenWorkflowStatus('Review');
    static readonly Complete: GenWorkflowStatus = new GenWorkflowStatus('Complete');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'Potential') return GenWorkflowStatus.Potential;
        if (name === 'InQueue') return GenWorkflowStatus.InQueue;
        if (name === 'OnHold') return GenWorkflowStatus.OnHold;
        if (name === 'InProgress') return GenWorkflowStatus.InProgress;
        if (name === 'Review') return GenWorkflowStatus.Review;
        if (name === 'Complete') return GenWorkflowStatus.Complete;
        throw new Error('Unexpected value for GenWorkflowStatus:' + name);
    }

    static values(): GenWorkflowStatus[] {
        return [
            GenWorkflowStatus.Potential,
            GenWorkflowStatus.InQueue,
            GenWorkflowStatus.OnHold,
            GenWorkflowStatus.InProgress,
            GenWorkflowStatus.Review,
            GenWorkflowStatus.Complete,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

