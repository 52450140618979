<div mat-dialog-title class="dialog-header">
  <div class="flex-row flex-align-start-center">
    <div class="flex-grow">{{task.name}} Notes</div>
    <mat-icon class="clickable" (click)="close()">clear</mat-icon>
  </div>
</div>
<div mat-dialog-content>
  <div class="notes">
    {{task.releaseNotes}}
  </div>
</div>
