import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';

import {UserService} from '../services/user.service';
import {User} from '../types/user.class';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedUserGuard  {
  constructor(public userService: UserService, public router: Router) {
  }

  /**
   * finds user specified by id in given route. if neither is provided, will return a user with defaults.
   *
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    const observable = this.userService.me();
    observable.subscribe(() => {
    }, () => {
      this.router.navigate(['/login']);
    });

    return observable;
  }
}
