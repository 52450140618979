import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import * as moment from 'moment';
import {User} from '../../../../types/user.class';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {UserService} from '../../../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from '../../../../services/authentication.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EmailBuilderComponent} from '../email-builder/email-builder.component';
import {startWith} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {PromptComponent, PromptData} from '../../../shared/components/prompt/prompt.component';
import {AddRoleComponent} from '../add-role/add-role.component';
import {DeleteRoleComponent} from '../delete-role/delete-role.component';
import {GenGistRoleDefinition} from '../../../../generated/serverModels/GenGistRoleDefinition';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'portal-user-manager-component',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent implements OnInit, AfterViewInit {

  constructor(private userService: UserService,
              private popup: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private router: Router,
              private dialog: MatDialog) {
    // create service.

  }

  searchControl: any;
  filterAwaitingApproval = false;
  users: User[];
  allUsers: User[] = [];
  selectedRole: GenRoleDefinition = undefined;
  selectedStatus: string = undefined;
  statuses: string[] = ['Enabled', 'Disabled', 'Approved', 'Needs Approval'];
  searchText: string;
  today: Date = new Date();
  joinDate: moment.Moment;
  dateTimeoutId = -1;
  domains: string[] = [];
  selectedDomains: string[] = [];
  loading = false;
  searchTerm = '';
  organizations: string[] = [
    'NGA',
    'DOD',
    'DOS',
    'ORNL',
    'Other'
  ];
  selectedOrganization: string = undefined;

  sortOptions: SortOption[] = [
    new SortOption('email', 'User'),
    new SortOption('lastLoginDate', 'Last Login'),
    new SortOption('Domain', 'Domain')
  ];
  selectedSortOption: SortOption;
  sortDirection = 1;

  @ViewChild('downloadLink') link: ElementRef;
  @ViewChild(MatSort, {static: true}) matSort: MatSort;
  editableRoles: GenRoleDefinition[] = [];
  dataSource = new MatTableDataSource<User>();
  displayedColumns: string[] = ['user', 'domain', 'lastLoginDate', 'icons', 'roles'];
  menuRolesOptions: MenuRoleOption[] = [];
  userForMenu: User;
  user: User;

  static now(): number {
    return new Date().valueOf();
  }

  displayLoginDate(user: User): string {
    if (user.lastLoginDate) {
      const ld = moment(user.lastLoginDate);
      if (Math.abs(moment().diff(ld, 'days')) > 30) {
        return ld.format('YYYY-MM-DD');
      } else {
        return ld.fromNow();
      }
    }
    return '-';
  }

  getUsers() {
    this.loading = true;
    this.userService.getAll(this.searchTerm).subscribe(users => {
      this.loading = false;
      const needApprovalUsers: User[] = users.filter(u => this.userNeedsApproval(u));

      this.allUsers = needApprovalUsers.concat(users.filter(u => !this.userNeedsApproval(u)));
      this.domains = [];
      const d: string[] = this.allUsers.map(u => this.getEmailDomain(u.email));
      d.forEach((domain) => {
        if (this.domains.indexOf(domain) === -1) {
          this.domains.push(domain);
        }
      });
      this.domains.sort();
      this.applySearchFilter(this.searchText);
    });
  }

  ngOnInit() {
    this.user = this.activatedRoute.snapshot.data.user;
    this.getUsers();

    this.initEditableRoles();

    this.searchControl = new UntypedFormControl();
    this.searchText = '';

    this.searchControl.valueChanges.pipe(
      startWith(this.searchText)
    ).subscribe(() => {
      this.refresh();
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.search) {
        this.searchText = params.search.replace('_', ' ');
        this.searchControl.setValue(this.searchText);
      }
      if (params.role) {
        this.selectedRole = params.role.replace('_', ' ');
      }
      if (params.status) {
        this.selectedStatus = params.status.replace('_', ' ');
      }
      if (params.join_date) {
        this.joinDate = moment(params.join_date, 'MM_DD_YYYY');
      }
      if (params.organization) {
        this.selectedOrganization = params.role.replace('_', ' ');
      }
      this.refresh();
    });
    this.dataSource.sort = this.matSort;
    this.dataSource.sortingDataAccessor = (user, property): string | number => {
      switch (property) {
        case 'user':
          return `${user.firstName} ${user.lastName}`;
        case 'domain':
          return this.getEmailDomain(user.email);
        case 'lastLoginDate':
          return moment(user.lastLoginDate).format();
        default:
          return user[property];
      }
    };
  }

  filteredRoles(user: User) {
    return user.roles.filter(r => this.editableRoles.indexOf(r) !== -1);
  }

  addRoleMenuOpened(user: User) {
    this.userForMenu = user;
    for (const roleOption of this.menuRolesOptions) {
      roleOption.checked = user.roles.indexOf(roleOption.role) !== -1;
    }
  }

  delayedRefresh() {
    if (this.dateTimeoutId !== -1) {
      window.clearTimeout(this.dateTimeoutId);
      this.dateTimeoutId = -1;
    }
    this.dateTimeoutId = window.setTimeout(() => {
      this.dateTimeoutId = -1;
      this.refresh();
    }, 1000);
  }

  refresh(): void {
    const params: any = {}; // TODO what is all of this???
    if (this.searchControl.value) {
      params.search = this.searchControl.value.replace(' ', '_');
    }
    if (this.selectedRole) {
      params.role = this.selectedRole.description.replace(' ', '_');
    }
    if (this.selectedStatus) {
      params.status = this.selectedStatus.replace(' ', '_');
    }
    if (this.joinDate) {
      params.join_date = this.joinDate.format('MM_DD_YYYY');
    }
    if (this.selectedOrganization) {
      params.organization = this.selectedOrganization.replace(' ', '_');
    }
    this.router.navigate(['user'], {queryParams: params});
    this.applySearchFilter(this.searchControl.value);
  }

  openEmailBuilder() {
    this.dialog.open(EmailBuilderComponent, {
      data: this.users.filter(u => u.enabled),
      width: '50vw'
    }).afterClosed().subscribe((state) => {
      if (state) {
        if (state === 'Sent') {
          this.popup.open(`Email sent`, 'okay', {duration: 2000, panelClass: ['success']});
        } else if (state == 'Failure') {
          this.popup.open(`Failed to send`, 'okay', {duration: 6000, panelClass: ['failure']});
        }
      }
    });
  }

  ngAfterViewInit(): void {
  }


  enableUser(user: User): void {
    // do before the call happens in case something else saves the user -- do not want to disable the user.
    user.enabled = true;
    user.approved = true;
    user.roles.push(this.user.roleMap.get('ROLE_APPROVED_USER'));
    this.userService.approve(user)
      .subscribe(() => {
        this.popup.open(`${user.username} enabled`, 'okay', {duration: 2000, panelClass: ['success']});
      }, () => {
        this.popup.open(`${user.username} enable failed`, 'okay', {
          duration: 60000,
          panelClass: ['failure']
        });
      });
  }

  disableUser(user: User): void {
    const data: PromptData = {
      level: 'high',
      type: 'user',
      action: 'disable',
      name: `${user.firstName} ${user.lastName}`
    };
    this.dialog.open(PromptComponent, {data, panelClass: 'dialog-with-no-padding', width: '50vw'})
      .afterClosed().subscribe((response) => {
      if (response) {
        this.userService.disable(user)
          .subscribe(() => {
            user.enabled = false;
            this.popup.open(`${user.username} disabled`, 'okay', {duration: 2000, panelClass: ['success']});
          }, () => {
            this.popup.open(`${user.username} disable failed`, 'okay', {
              duration: 60000,
              panelClass: ['failure']
            });
          });
      }
    });

  }

  getEmailDomain(email: string): string {
    if (email.indexOf('@') !== -1) {
      const domain: string = email.substring(email.indexOf('@') + 1);
      return domain.substring(0, domain.indexOf('.')).toUpperCase();
    } else {
      return '-';
    }

  }

  userNeedsApproval(user: User): boolean {
    return (!user.approved || (user.enabled && user.roles.length === 0));
  }

  applySearchFilter(searchText: string): void {
    if (!this.allUsers) {
      return;
    }

    this.users = this.allUsers.filter(user => {
      let isIn = true;
      if (this.selectedRole) {
        isIn = isIn && !!user.hasAnyRole([this.selectedRole]);
      }
      if (this.selectedOrganization) {
        switch (this.selectedOrganization) {
          case 'NGA':
            isIn = isIn &&
              user.organization.toLowerCase().includes('nga') ||
              user.organization.toLowerCase().includes('national geospatial-intelligence agency') ||
              user.organization.toLowerCase().includes('national geospatial intelligence agency');
            break;
          case 'DOD':
            isIn = isIn &&
              user.organization.toLowerCase().includes('dod') ||
              user.organization.toLowerCase().includes('department of defense');
            break;
          case 'DOS':
            isIn = isIn &&
              user.organization.toLowerCase().includes('dos') ||
              user.organization.toLowerCase().includes('department of state');
            break;
          case 'ORNL':
            isIn = isIn &&
              user.organization.toLowerCase().includes('ornl') ||
              user.organization.toLowerCase().includes('oak ridge national laboratory');
            break;
          case 'Other':
            isIn = isIn &&
              !(user.organization.toLowerCase().includes('ornl') ||
                user.organization.toLowerCase().includes('oak ridge national laboratory') ||
                user.organization.toLowerCase().includes('dos') ||
                user.organization.toLowerCase().includes('department of state') ||
                user.organization.toLowerCase().includes('dod') ||
                user.organization.toLowerCase().includes('department of defense') ||
                user.organization.toLowerCase().includes('nga') ||
                user.organization.toLowerCase().includes('national geospatial-intelligence agency') ||
                user.organization.toLowerCase().includes('national geospatial intelligence agency'));
            break;
        }
      }
      if (this.selectedStatus) {
        switch (this.selectedStatus) {
          case 'Enabled':
            isIn = isIn && user.canLogin;
            break;
          case 'Disabled':
            isIn = isIn && user.approved && !user.enabled;
            break;
          case 'Approved':
            isIn = isIn && user.approved;
            break;
          case 'Needs Approval':
            isIn = isIn && this.userNeedsApproval(user);
            break;
        }
      }
      if (this.joinDate) {
        const date: moment.Moment = moment(user.joinDate);
        isIn = isIn && date.isAfter(this.joinDate);
      }
      if (this.selectedDomains && this.selectedDomains.length) {
        const domain = this.getEmailDomain(user.email);
        isIn = isIn && this.selectedDomains.indexOf(domain) !== -1;
      }
      return isIn;
    });
    if (searchText) {
      const terms: string[] = searchText.toLowerCase().split(' ');
      this.users = this.users
        .filter((user: User) => {
          return terms.every((term) => {
            if (term.indexOf('username:') === 0) {
              const username: string = term.substr(9).toLowerCase();
              return user.username.toLowerCase() === username;
            }
            if (user.firstName.toLowerCase().indexOf(term) >= 0) {
              return true;
            }
            if (user.lastName.toLowerCase().indexOf(term) >= 0) {
              return true;
            }
            if (user.email.toLowerCase().indexOf(term) >= 0) {
              return true;
            }
            //noinspection RedundantIfStatementJS
            if (user.username.toLowerCase().indexOf(term) >= 0) {
              return true;
            }
            return false;
          });
        });
    }
    if (this.filterAwaitingApproval) {
      this.users = this.users.filter(u => !u.isApproved() && !u.approved);
    }

    // if (this.users) {
    //   this.users.sort((a: User, b: User) => {
    //     return a.username < b.username ? -1 : a.username > b.username ? 1 : 0;
    //   });
    // }
    this.dataSource.data = this.users;
  }

  sort(id: string): void {
    let sortOption: SortOption;
    if (id) {
      sortOption = this.sortOptions.find((option) => {
        return option.id === id;
      });
      if (sortOption === this.selectedSortOption) {
        this.sortDirection *= -1;
      } else {
        this.sortDirection = 1;
      }
      this.selectedSortOption = sortOption;
    } else {
      sortOption = this.selectedSortOption;
      this.sortDirection *= -1;
    }

    if (id === 'Domain') {
      this.users.sort((a, b) => {
        return (this.getEmailDomain(a.email) < this.getEmailDomain(b.email) ? -1 : this.getEmailDomain(a.email) > this.getEmailDomain(b.email) ? 1 : 0) * this.sortDirection;
      });
    } else if (sortOption.role) {
      this.users.sort((a, b) => {
        const valA: boolean = a.hasRole(sortOption.role);
        const valB: boolean = b.hasRole(sortOption.role);

        return (valA === valB) ? 0 : (valA ? (this.sortDirection * -1) : this.sortDirection);
      });
    } else {
      this.users.sort((a, b) => {
        let valA = a[sortOption.id];
        let valB = b[sortOption.id];

        // sort case-insensitive
        if (valA && valA.toLowerCase) {
          valA = valA.toLowerCase();
        }
        if (valB && valB.toLowerCase) {
          valB = valB.toLowerCase();
        }
        // treat undefined as 0.
        if (typeof valA === 'undefined') {
          valA = 0;
        }
        if (typeof valB === 'undefined') {
          valB = 0;
        }
        return (valA < valB ? -1 : valA > valB ? 1 : 0) * this.sortDirection;
      });
    }
  }

  onRoleChange(user: User, role: GenRoleDefinition): void {
    const has = user.hasRole(role);
    user.changeRole(!has, role);

    this.userService.save(user).subscribe((u) => {
      this.popup.open(`${u.username} profile updated`, 'okay', {duration: 2000, panelClass: ['success']});
    }, () => {
      this.popup.open(`${user.username} update failed`, 'okay', {duration: 60000, panelClass: ['failure']});
    });
    // this.dataSource.data = this.users;

  }

  impersonate(user: User): void {
    this.authenticationService.impersonate(user);
  }

  downloadUserReport() {
    const rows = this.users.map((user) =>
      [
        `"${(user.firstName || '').replace('"', '\'')}"`,
        `"${(user.lastName || '').replace('"', '\'')}"`,
        `"${user.joinDate.format('MM/DD/YYYY') || ''}"`,
        `"${(user.email || '').replace('"', '\'')}"`,
        `"${(user.phone || '').replace('"', '\'')}"`,
        `"${(user.organization || '').replace('"', '\'')}"`,
        `"${(user.requestReason || '').replace('"', '\'')}"`,
        `"${this.getRoles(user) || ''}"`
      ]);
    window.alert('fixed');

    const headerRow = ['First Name', 'Last Name', 'Join Date', 'Email', 'Phone Number', 'Organization', 'Reason For Account', 'Roles'];
    let csvContent = (headerRow.join(',')) + '\r\n';
    csvContent += rows.map(row => row.join(',')).join('\r\n');

    const el = this.link.nativeElement;
    el.href = 'data:text/csv;base64,' + window.btoa(csvContent);
    el.download = 'User_Report.csv';
    el.click();
  }

  getRoles(user: User): string {
    const ret: string[] = [];
    if (user.isContributor()) {
      ret.push('Contributor');
    }
    if (user.isPublisher()) {
      ret.push('Publisher');
    }
    if (user.isNga()) {
      ret.push('NGA');
    }
    if (user.isAdmin()) {
      ret.push('Admin');
    }

    return ret.join(',');
  }

  roleDescription(roleDefinition: GenGistRoleDefinition): string {
    return roleDefinition.description;
  }

  addRolesFromMenu() {
    this.menuRolesOptions.forEach((roleOption) => {
      if (roleOption.checked) {
        if (this.userForMenu.roles.indexOf(roleOption.role) === -1) {
          this.userForMenu.roles.push(roleOption.role);
        }
      } else {
        const index = this.userForMenu.roles.indexOf(roleOption.role);
        if (index !== -1) {
          this.userForMenu.roles.splice(index, 1);
        }
      }
    });
    for (const roleOption of this.menuRolesOptions) {
      roleOption.checked = false;
    }
    this.userService.save(this.userForMenu).subscribe((u) => {
      this.popup.open(`${u.username} profile updated`, 'okay', {duration: 2000, panelClass: ['success']});
    }, () => {
      this.popup.open(`${this.userForMenu.username} update failed`, 'okay', {duration: 60000, panelClass: ['failure']});
    });
    this.userForMenu = undefined;
    // this.dataSource.data = this.users;
  }

  addGlobalRole() {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '55vw',
      maxHeight: '80vh',
      panelClass: 'dialog-with-no-padding',
      data: this.editableRoles
    };
    this.dialog.open(AddRoleComponent, configData).afterClosed().subscribe((roles) => {
      if (roles) {
        this.loading = true;
        this.userService.addRoles(roles).subscribe((newRoles) => {
          this.popup.open(`Successfully added ${roles.length} role${(roles.length > 1) ? 's' : ''}`, 'okay', {
            duration: 5000,
            panelClass: ['success']
          });
          this.loading = false;
          this.userService.setRoleMap(newRoles);
          this.user.roleMap = this.userService.roles.value;
          this.initEditableRoles();
          this.getUsers();
        }, () => {
          this.loading = false;
          this.popup.open(`Error adding ${roles.length} role${(roles.length > 1) ? 's' : ''}`, 'okay', {
            duration: 15000,
            panelClass: ['failure']
          });
        });
      }
    });
  }

  initEditableRoles() {

    this.editableRoles = [];
    this.user.roleMap.forEach((value, key) => {
      if (!key.includes('_KEY_') && key !== 'ROLE_APPROVED_USER' && key !== 'ROLE_RESETTING_PASSWORD') {
        this.editableRoles.push(value);
      }
    });

    this.menuRolesOptions = [];
    this.editableRoles.forEach((role) => {
      this.menuRolesOptions.push({
        checked: false,
        role,
        displayName: this.roleDescription(role)
      });
    });

    this.sortOptions = this.sortOptions.concat(this.editableRoles.map(role => {
      return {
        id: role.name,
        name: role.name,
        role
      };
    }));
  }

  deleteGlobalRole() {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '55vw',
      maxHeight: '80vh',
      panelClass: 'dialog-with-no-padding',
      data: this.editableRoles
    };
    this.dialog.open(DeleteRoleComponent, configData).afterClosed().subscribe((roles) => {
      if (roles) {
        this.loading = true;
        this.userService.deleteRole(roles).subscribe((newRoles) => {
          this.popup.open(`Successfully deleted ${roles.length} role${(roles.length > 1) ? 's' : ''}`, 'okay', {
            duration: 5000,
            panelClass: ['success']
          });
          this.loading = false;
          this.userService.setRoleMap(newRoles);
          this.user.roleMap = this.userService.roles.value;
          this.initEditableRoles();
          this.getUsers();
        }, () => {
          this.loading = false;
          this.popup.open(`Error deleting ${roles.length} role${(roles.length > 1) ? 's' : ''}`, 'okay', {
            duration: 15000,
            panelClass: ['failure']
          });
        });
      }
    });
  }
}

class SortOption {
  constructor(id: string, name: string, role?: GenRoleDefinition) {
    this.id = id;
    this.name = name;
    this.role = role;
  }

  id: string;
  name: string;
  role: GenRoleDefinition | undefined;
}

interface MenuRoleOption {
  checked: boolean;
  role: GenRoleDefinition;
  displayName: string;
}
