
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRoleDefinition } from './GenRoleDefinition';
import * as moment from 'moment';

export class GenFileShare {

    id: number;
    description: string;
    expirationDate: moment.Moment;
    fileLength: number;
    fileType: string;
    originalFilename: string;
    recipientUsernames: string[];
    roles: GenRoleDefinition[];
    rootDirectory: string;
    serverFilename: string;
    uploaderUsername: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genFileShare: GenFileShare);
    constructor( id: number );

    constructor( input?: GenFileShare|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.description = input.description; // string [string]
            if ( input.expirationDate && typeof input.expirationDate === 'string' ) {
                this.expirationDate = moment(input.expirationDate).utc();
            } else {
                this.expirationDate = input.expirationDate;
            }
            this.fileLength = input.fileLength; // int [number]
            this.fileType = input.fileType; // string [string]
            this.originalFilename = input.originalFilename; // string [string]
            this.recipientUsernames = input.recipientUsernames; // list [string]
            if ( input.roles && input.roles.map ) { // list [GenRoleDefinition]
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return new GenRoleDefinition( x );
                } );
            } else {
                this.roles = input.roles;
            }
            this.rootDirectory = input.rootDirectory; // string [string]
            this.serverFilename = input.serverFilename; // string [string]
            this.uploaderUsername = input.uploaderUsername; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenFileShare[] {
        if (arr && arr.map) {
          return arr.map(a => new GenFileShare(a));
        } else {
          return [];
        }
    }
}

