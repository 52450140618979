import { GenTask } from '../generated/serverModels/GenTask';
import { GenWmsProcessStatus } from '../generated/serverModels/GenWmsProcessStatus';
import { GenWorkflowStatus } from '../generated/serverModels/GenWorkflowStatus';

export class Task extends GenTask {
    getDownloadPath(): string {
        return `/api/task/download/${this.id}/${this.originalFilename}`;
    }

    getThumbnailPath(): string | undefined {
        if (this.thumbnail) {
            return `/api/task/thumbnail/${this.id}/thumbnail.png`;
        } else {
            return undefined;
        }
    }

    get locationName(): string {
        return this.location.name || 'Global';
    }

    get completeness(): number {
        return this.percentageComplete();
    }

    constructor(obj?: any) {
        super(obj);
    }

    percentageComplete(): number {
        const steps = GenWorkflowStatus.values();

        let total = steps.length;
        if (!total) {
            return undefined;
        }
        let currentStep = steps.findIndex(s => s === this.status);
        if (currentStep === -1) {
            return undefined
        }
        return currentStep / total;
    }

    public hasWms(): boolean {
        return this.url && this.url.length && this.wmsProcessStatus === GenWmsProcessStatus.Done;
    }

    anticipatedRelease(): string {
        if (!this.releaseTimestamp) {
            return '';
        }

        let year = this.releaseTimestamp.format('Y');
        let quarter = Math.ceil((this.releaseTimestamp.month() + 1) / 3);

        return `CY${year}-Q${quarter}`;

    }
}
