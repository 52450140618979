import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {map} from 'rxjs/operators';

import {GenRoleDefinition} from '../generated/serverModels/GenRoleDefinition';
import {UserService} from '../services/user.service';
import {User} from '../types/user.class';

/**
 *
 *
 * @export
 * @class RolesGuard
 * @extends {resolveGuard}
 * @implements {canActivate}
 */
@Injectable( {
    providedIn: 'root'
} )
export class RolesGuard  {
    /**
     * Creates an instance of resolveGuard.
     *
     * @param {UserService} userService
     * @param {Router} router
     *
     * @memberOf resolveGuard
     */
    constructor( public userService: UserService ) {
    }


    /**
     * Creates a guard that will only let users view a page if
     * they have the role defined in the routing file
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     *
     * @returns Promise<boolean>
     *
     * @memberOf RolesGuard
     */
    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean> {

        return this.userService.me().pipe(
            map( ( user: User ) => {
                const requiredRoles: GenRoleDefinition[] = route.data['anyRole'].map(r => (user.roleMap) ? user.roleMap.get(r) : r);
                return user.hasAnyRole( requiredRoles );
            }
            ) ).toPromise();
    }
}
