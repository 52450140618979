import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'portal-poc-failure',
  template: '' +
    '<div class="warn-c" style="position: absolute; top: 100px; left: 0; right: 0; text-align: center">' +
    '  <p *ngIf="reason === \'user-not-found\'">No user was found with that verification code.  The verification code can be used only once, and it may have already been used.</p> ' +
    '  Your response failed to save. Please contact our admin at <a href="mailto:{{adminEmail}}">{{adminEmail}}</a>' +
    '</div>'
})
export class PocFailureComponent {

  reason: string;
  constructor(route: ActivatedRoute) {
    route.queryParams.subscribe(params => {
      this.reason = params.reason;
    });
  }

  get adminEmail(): string {
    return `${(window as any).data.adminEmail}`;
  }


}
