
<div class="full-width full-height project-browser-container" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-12]="true">
  <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-16]="true">
    <div class="section-title">Projects</div>
    <div class="full-width" [class.flex-row-wrap]="true" [class.flex-align-start-center]="true" [class.gap-24]="true">
      <div *ngFor="let project of projects" class="project-card" [class.flex-column]="true" [class.flex-align-end-center]="true" [class.gap-16]="true" (click)="viewTasks(project)">
        <img *ngIf="getProjectSrc(project)" style="width: 129px; height: 129px" [src]="getProjectSrc(project)" [alt]="project.name + '_img'">
        <div *ngIf="!getProjectSrc(project)" class="no-thumbnail-filler" [class.flex-column]="true" [class.flex-align-center-center]="true">
          <img src="assets/ornl-logo-black.png" style="width: 81px; height: 40px"  alt="no_thumbnail">
        </div>
        <div class="project-name">{{ project.name }}</div>
        <button mat-icon-button  *ngIf="user.isContributor()" class="project-menu" [matMenuTriggerFor]="projectMenu" (click)="$event.stopPropagation()"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #projectMenu="matMenu">
          <button mat-menu-item (click)="edit(project)">Edit Project</button>
          <button mat-menu-item (click)="remove(project)">Delete Project</button>
        </mat-menu>
      </div>
      <div *ngIf="fileShares.length" class="project-card" [class.flex-column]="true" [class.flex-align-end-center]="true" [class.gap-16]="true" (click)="viewSharedFiles()">
        <div class="no-thumbnail-filler" [class.flex-column]="true" [class.flex-align-center-center]="true">
          <mat-icon class="shared-folder-icon">folder_open</mat-icon>
        </div>
        <div class="project-name">Shared Files</div>
      </div>
    </div>
  </div>

  <div class="full-width" style="padding-bottom: 24px;" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-16]="true">
    <div class="full-width" [class.flex-row]="true" [class.flex-align-start-end]="true">
      <div class="section-title" [class.flex-grow]="true">Recent Uploads</div>
      <div *ngIf="multiSelectDownloadUrl.length" matRipple class="download-selected-link clickable" (click)="downloadSelected.click()" [class.flex-125]="true" [class.flex-column]="true" [class.flex-align-center-center]="true">
        <div>Download Checked</div>
      </div>
    </div>
    <portal-task-table class="full-width task-table" [user]="user" [showThumbnails]="false" [view]="'recent'" [tasks]="recentTasks" [projects]="allProjects" (selectedTaskChange)="multiSelectDownloadUrl = $event"></portal-task-table>
  </div>

</div>

<a #downloadSelected style="display: none" [href]="multiSelectDownloadUrl" target="_blank"></a>


