import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BreadCrumb } from '../types/breadcrumb.class';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {
    public projBreadCrumb: BreadCrumb;
    public taskBreadCrumb: BreadCrumb;
    public breadcrumbs: BehaviorSubject<BreadCrumb[]>;
    constructor() {
        this.breadcrumbs = new BehaviorSubject(undefined);
    }

    setProjectCrumb( route: any[], name: string, id: number, view?: string ) {
        if ( this.projBreadCrumb && this.projBreadCrumb.id === id ) {
            if ( view && this.projBreadCrumb.view !== view ) {
                this.projBreadCrumb.view = view;
                this.notify();
            }
            return;
        }
        this.projBreadCrumb = new BreadCrumb(route, id, name, view);
        if ( route.length < 2 ) this.taskBreadCrumb = undefined;
        this.notify();
    }

    setTaskCrumb( route: any[], name: string, id: number, projectName: string, projectId: number ) {
        if (this.taskBreadCrumb && this.taskBreadCrumb.id === id) return;
        if (this.projBreadCrumb.id !== projectId ) {
            let tmpRoute: string[] = route.slice(0, 3);
            this.projBreadCrumb = new BreadCrumb( tmpRoute, projectId, projectName );
        }
        this.taskBreadCrumb = new BreadCrumb(route, id, name);
        this.notify();
    }

    clear() {
        this.projBreadCrumb = undefined;
        this.taskBreadCrumb = undefined;
        this.notify();
    }

    public notify() {
        let bcs: BreadCrumb[] = [];
        if ( this.projBreadCrumb ) bcs.push( this.projBreadCrumb );
        if ( this.taskBreadCrumb ) bcs.push( this.taskBreadCrumb );
        this.breadcrumbs.next( bcs );
    }

}
