import {HttpClient} from '@angular/common/http';
import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Task} from "../../../../types/task.class";

@Component({
  selector: 'portal-release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss']
})
export class ReleaseNotesDialogComponent {

  task: Task;

  constructor(private ref: MatDialogRef<ReleaseNotesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public popup: MatSnackBar,
              public http: HttpClient) {
    this.task = data?.task;
  }

  close() {
    this.ref.close();
  }

}
