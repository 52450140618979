<iframe #downloadFrame></iframe>

<div class="full-width full-height task-browser-container" [class.flex-column]="true"
     [class.flex-align-start-start]="true" [class.gap-12]="true">
  <div class="loading-overlay" [class.show]="loading">
    <div *ngIf="loading" class="loading-mask">
      <mat-spinner diameter="150"></mat-spinner>
    </div>
  </div>
  <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-20]="true">
    <div *ngIf="project && !hasSearchQueryParam" [class.flex-row]="true" [class.flex-align-start-center]="true"
         [class.gap-10]="true">
      <img *ngIf="getProjectSrc(project)" style="width: 34px; height: 34px" [src]="getProjectSrc(project)"
           [alt]="project.name + '_img'">
      <div class="project-title">{{ project.name }}</div>
    </div>
    <div class="full-width flex-row flex-align-start-center gap-20">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="search-field">
        <mat-label>Search by keyword or assignee</mat-label>
        <input type="text" matInput [(ngModel)]="searchVal" (keyup)="applySearchFilter(searchVal)">
        <mat-icon matPrefix style="color: #a2a2a2">search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      class="portal-select"
                      floatLabel="always"
                      subscriptSizing="dynamic">
        <mat-label>Tag</mat-label>
        <mat-select (selectionChange)="applySearchFilter(searchVal)"
                    [(value)]="selectedTags"
                    multiple
                    placeholder="Any Tag">
          <mat-option *ngFor="let tag of tags" [value]="tag">{{ tag }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="user.isContributor()" appearance="outline" class="portal-select" subscriptSizing="dynamic">
        <mat-label>Status</mat-label>
        <mat-select placeholder="Any Status" [(value)]="currentStatus">
          <mat-option [value]="undefined" (click)="applySearchFilter(searchVal)">Any Status
          </mat-option>
          <ng-container *ngFor="let status of statuses; let i = index;">
            <mat-option [value]="status" (click)="applySearchFilter(searchVal)">{{ prettyStatus(status) }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="portal-date-picker" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Published Start Date</mat-label>
        <input matInput [matDatepicker]="startDate" [(ngModel)]="publishedStartDate"
               (dateChange)="applySearchFilter(searchVal)"
               (dateInput)="delayedRefresh()">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate [startAt]="publishedStartDate || today"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="portal-date-picker" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Published End Date</mat-label>
        <input matInput [matDatepicker]="endDate" [(ngModel)]="publishedEndDate"
               (dateChange)="applySearchFilter(searchVal)"
               (dateInput)="delayedRefresh()">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate [startAt]="publishedEndDate || today"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="full-width" [class.flex-row]="true" [class.flex-align-start-start]="true" [class.gap-20]="true">
      <div [class.flex-grow]="true" [class.flex-row-wrap]="true" [class.flex-align-start-center]="true">
        <div class="filter-chip-title">Select Filters:</div>
        <div class="filter-chip clickable" *ngFor="let chip of filterChips"
             (click)="chip.active = !chip.active; applySearchFilter(searchVal)"
             [ngClass]="{'active': chip.active}"
             [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-6]="true">
          <div class="filter-chip-text">{{ chip.title }}</div>
        </div>
        <div class="filter-chip active clickable" [class.flex-row]="true" [class.flex-align-start-center]="true"
             [class.gap-6]="true" (click)="clearFilterChips()">
          <mat-icon class="filter-chip-icon">check</mat-icon>
          <div class="filter-chip-text">Clear Filters</div>
        </div>
      </div>
      <!--      <div class="toggle-filter clickable" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-6]="true" (click)="showThumbnails = !showThumbnails; this.applySearchFilter(searchVal)">-->
      <!--        <mat-icon class="toggle-icon active" *ngIf="showThumbnails">visibility</mat-icon>-->
      <!--        <mat-icon class="toggle-icon" *ngIf="!showThumbnails">visibility_off</mat-icon>-->
      <!--        <div style="white-space: nowrap">{{(showThumbnails) ? 'Show' : 'Hide'}} Thumbnails</div>-->
      <!--      </div>-->
      <div *ngIf="user.isContributor()" class="toggle-filter clickable" [class.flex-row]="true"
           [class.flex-align-start-center]="true" [class.gap-6]="true"
           (click)="showArchived = !showArchived; this.applySearchFilter(searchVal)">
        <mat-icon class="toggle-icon active" *ngIf="showArchived">visibility</mat-icon>
        <mat-icon class="toggle-icon" *ngIf="!showArchived">visibility_off</mat-icon>
        <div style="white-space: nowrap">{{ (showArchived) ? 'Show' : 'Hide' }} Archived</div>
      </div>
    </div>

    <div [class.flex-grow]="true" [class.flex-row]="true" [class.flex-align-end-center]="true" [class.gap-20]="true">
      <div *ngIf="multiSelectDownloadUrl.length" matRipple class="download-selected-link clickable"
           (click)="downloadSelected.click()" [class.flex-125]="true" [class.flex-column]="true"
           [class.flex-align-center-center]="true">
        <div>Download Checked</div>
      </div>

    </div>

  </div>
  <portal-task-table class="full-width task-table" [user]="user" [showThumbnails]="false" [tasks]="tasks"
                     [projects]="projects" (selectedTaskChange)="multiSelectDownloadUrl = $event"></portal-task-table>

</div>
<a #downloadSelected style="display: none" [href]="multiSelectDownloadUrl" target="_blank"></a>
<a #fileList style="display: none" [href]="downloadSpreadSheetURL()" target="_blank"></a>
