import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenActionType } from 'src/app/generated/serverModels/GenActionType';
import { GenTask } from 'src/app/generated/serverModels/GenTask';
import { GenUserAction } from 'src/app/generated/serverModels/GenUserAction';

@Component( {
  selector: 'portal-download-statistics-dialog',
  templateUrl: './download-statistics-dialog.component.html',
  styleUrls: [ './download-statistics-dialog.component.scss' ]
} )
export class DownloadStatisticsDialogComponent {

public userActions: GenUserAction[];

  constructor(public http: HttpClient,
  public popup: MatSnackBar,
    @Inject( MAT_DIALOG_DATA )public  data: any ) {
    this.userActions = [];
    this.getDownloadsForTask( <GenTask>data.task )
      .subscribe( actions => this.userActions = actions,
        () => this.popup.open( 'Error getting download history', 'okay', {
          duration: 60000,
          panelClass: [ 'failure' ]
        } )
      )
  }
public getDownloadsForTask(task: GenTask): Observable<GenUserAction[]> {
    return this.http.get(`/api/tracking/download?targetId=${task.id}&type=` + GenActionType.DOWNLOAD)
        .pipe(map(arr => {
            if (!Array.isArray(arr)) {
                return [];
            }
            return arr.map(obj => new GenUserAction(obj));
        }))
}
}
