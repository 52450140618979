import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'portal-poc-success',
  template: '' +
    '<div class="primary-c" style="position: absolute; top: 100px; left: 0; right: 0; text-align: center">' +
    '  <p *ngIf="action === \'approve\'">Thank you for verifying the user.</p> ' +
    '  <p *ngIf="action === \'deny\'">Thank you for indicating that you do not verify the user.</p> ' +
    '  Thank you! Your response has been recorded successfully.' +
    '</div>'
})
export class PocSuccessComponent {

  action: string;
  constructor(route: ActivatedRoute) {
    route.queryParams.subscribe(params => {
      this.action = params.action;
    });
  }

}
