import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';

import {Project} from '../types/project.class';
import {User} from "../types/user.class";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(public http: HttpClient) {
  }

  getAll(): Observable<Project[]> {
    return this.http.get('/api/project').pipe(map(array => {
      if (!Array.isArray(array)) {
        return [];
      }
      return array.map(o => new Project(o));
    }));
  }

  remove(project: Project): Observable<Object> {
    return this.http.delete(`/api/project/${project.id}`);
  }

  getById(id: number): Observable<Project> {
    return this.http.get(`/api/project/${id}`).pipe(map(obj => new Project(obj)));
  }

  save(project: Project, thumbnail?: any): Observable<XMLHttpRequest> {
    let formData = new FormData();
    let xhr = new XMLHttpRequest();

    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }

    formData.append('metadata', JSON.stringify(this.getSimplifiedProject(project)));

    let subject = new ReplaySubject<XMLHttpRequest>();

    let url: string = project.id ? `/api/project/${project.id}` : `/api/project`;

    xhr.open('POST', url, true);
    xhr.onerror = xhr.onload = () => {
      subject.next(xhr);
      subject.complete();
    };
    xhr.send(formData);

    return subject;
  }

  getSimplifiedProject(project: Project): Project {
    const p = new Project(project);
    if (p.pointOfContact) {
      const u = new User(null, p.pointOfContact);
      let userRoles = [];
      if (u.roles.length) {
        userRoles = (typeof u.roles[0] === 'string') ? u.roles : u.roles.map(r => r.name);
      }
      u.roles = userRoles;
      p.pointOfContact = u;
    }
    return p;
  }
}
