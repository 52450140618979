import {Component, Inject, OnInit} from '@angular/core';
import {User} from "../../../../types/user.class";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GenEmail} from "../../../../generated/serverModels/GenEmail";

@Component({
  selector: 'portal-email-builder',
  templateUrl: './email-builder.component.html',
  styleUrls: ['./email-builder.component.scss']
})
export class EmailBuilderComponent implements OnInit {

  users: User[] = [];
  shownUsers: User[] = [];
  allShown: boolean = false;
  subject: string;
  body: string;
  me: User;

  constructor(public ref: MatDialogRef<EmailBuilderComponent>,
              @Inject( MAT_DIALOG_DATA ) private data: User[],
              private userService: UserService,
              private popup: MatSnackBar) {
    this.users = data.concat();
    this.userService.me().subscribe((user) => {
      this.me = user;
    });
    this.shownUsers = this.users.slice(0, 9);
    if (this.users.length <= 10) {
      this.allShown = true;
    }
  }

  ngOnInit() {

  }

  isValid() {
    return !(this.subject && this.body);
  }

  showAllUsers() {
    this.shownUsers = this.users.concat();
    this.allShown = true;
  }

  removeUser(index: number, user: User) {
    this.shownUsers.splice(index, 1);
    const i = this.users.findIndex(u => u.id === user.id);
    if (i !== -1) {
      this.users.splice(i ,1);
    }
    if (!this.allShown) {
      this.shownUsers.push(this.users[this.shownUsers.length]);
      if (this.users.length === 10) {
        this.allShown = true;
      }
    }
  }

  showLess() {
    this.shownUsers = this.users.slice(0, 9);
    this.allShown = false;
  }

  close() {
    this.ref.close();
  }

  send() {
    const email: GenEmail = new GenEmail();
    email.subject = this.subject;
    email.body = this.body;
    email.recipients = this.users;
    this.userService.emailUsers(email).subscribe(() => {
      this.ref.close('Sent');
    }, (error) => {
      this.ref.close('Failure');
    });
  }

  sendTest() {
    const email: GenEmail = new GenEmail();
    email.subject = this.subject;
    email.body = this.body;
    email.recipients = [this.me];
    this.userService.emailUsers(email).subscribe(() => {
      this.popup.open(`Test email sent to ${this.me.email}`, 'okay', {duration: 6000, panelClass: ['success']});
    }, (error) => {
      this.popup.open(`Failed to send`, 'okay', {duration: 6000, panelClass: ['failure']});
    });
  }

}
