import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {User} from '../../../../types/user.class';
import {GenUser} from '../../../../generated/serverModels/GenUser';
import {map, startWith} from 'rxjs/operators';
import {FileShare} from '../../../../types/file-share.class';
import {FileShareService} from '../../../../services/file-share.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../../services/user.service';
import {MatOptionSelectionChange} from '@angular/material/core';
import * as moment from 'moment';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {GenGistRoleDefinition} from '../../../../generated/serverModels/GenGistRoleDefinition';

@Component({
  selector: 'portal-share-file',
  templateUrl: './share-file.component.html',
  styleUrls: ['./share-file.component.scss']
})
export class ShareFileComponent implements OnInit, AfterViewInit {
  fileShare: FileShare;
  userControl: UntypedFormControl = new UntypedFormControl();
  filteredUsers: Observable<User[]>;
  file: File;
  lastIframeDownload: string;
  attemptedDownload = false;
  scrolled = false;
  editableRoles: GenRoleDefinition[] = [];
  uploadInProgress: boolean;
  uploadPercentage: number;
  lastProgressUpdateTime: number;
  users: User[] = [];
  user: User;
  today = moment();
  displayNameByUsername: { [name: string]: string } = {};

  @ViewChild('downloadFrame', {static: true})
  downloadFrame: ElementRef;

  addingNewRole = false;
  @ViewChild('addRoleInput', {static: false}) roleInput: ElementRef;
  addRoleName = '';

  get nameInUse(): boolean {
    if (this.addRoleName) {
      const existing = this.editableRoles.map(r => r.name.replace('ROLE_', '').split('_').join(' '));
      return existing.indexOf(this.addRoleName.toUpperCase()) !== -1;
    } else {
      return false;
    }
  }

  constructor(
    public ref: MatDialogRef<ShareFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileShareService: FileShareService,
    private popup: MatSnackBar,
    private userService: UserService
  ) {
    this.fileShare = data.fileShare;
    this.user = data.user;
    if (!this.fileShare.expirationDate) {
      this.fileShare.expirationDate = moment().add(1, 'month');
    }
    this.editableRoles = [];
    this.user.roleMap.forEach((value, key) => {
      if (!key.includes('_KEY_') && key !== 'ROLE_APPROVED_USER' && key !== 'ROLE_RESETTING_PASSWORD') {
        this.editableRoles.push(value);
      }
    });
    this.userService.getAll().subscribe((users: User[]) => {
      this.users = users;
      users.forEach((user) => {
        this.displayNameByUsername[user.username] = `${user.firstName} ${user.lastName}`;
      });
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map(name => {
          return this.filterUsers(name);
        }));
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.downloadFrame.nativeElement.onload = () => {
      if (!this.attemptedDownload) {
        // Soo... Firefox fires this when the iframe is added before anything has been clicked.
        return;
      }
      let retryFailed = false;
      try {
        if (this.lastIframeDownload) {
          window.open(this.lastIframeDownload);
        } else {
          retryFailed = true;
        }
      } catch (e) {
        retryFailed = true;
      }
      if (retryFailed) {
        window.alert('Download failed...');
      }
    };
  }

  public filterUsers(val: string) {
    if (!val || !val.toLowerCase) {
      return this.users;
    }
    val = val.toLowerCase().trim();
    const searchArr = val.split(' ');
    return this.users.filter(user => {
      const haystack = [
        user.firstName,
        user.lastName,
        user.email,
        user.name
      ]
        .concat(user.roles.map(role => role.name))
        .map(str => str.toLowerCase())
        .join();
      return (searchArr.every((s) => haystack.indexOf(s) !== -1));
    });
  }

  get isValid(): boolean {
    const c1 = !!this.fileShare.description && !!this.fileShare.originalFilename && !!this.fileShare.expirationDate;
    const c4 = !!this.fileShare.recipientUsernames || this.fileShare.roles;
    const c2 = !!this.fileShare.recipientUsernames && !!this.fileShare.recipientUsernames.length;
    const c3 = !!this.fileShare.roles && !!this.fileShare.roles.length;
    return c1 && c4 && (c2 || c3);
  }

  close() {
    this.ref.close();
  }

  fetch(fileShare: FileShare): void {
    this.attemptedDownload = true;
    const iframe = this.downloadFrame.nativeElement;

    const downloadPath = `/api/fileshare/download/${fileShare.id}/${fileShare.originalFilename}`;
    if (iframe && !fileShare.originalFilename.toLowerCase().endsWith('.pdf')) {
      this.lastIframeDownload = downloadPath;
      try {
        iframe.src = downloadPath;
      } catch (e) {
        window.open(this.lastIframeDownload);
        this.lastIframeDownload = undefined;
      }
    } else {
      this.lastIframeDownload = undefined;
      window.open(downloadPath);
    }
  }

  get maxRoleHeight(): number {
    const addOne = (this.addingNewRole) ? 1 : 0;
    return Math.ceil((this.editableRoles.length + addOne) / 2) * 36;
  }

  contentScrolled(el: Element) {
    this.scrolled = (el as HTMLElement).scrollTop !== 0;
  }

  fileAdded(file: File) {
    this.file = file;
    this.fileShare.originalFilename = file.name;
  }

  displayUserInfo(user: User) {
    return (!user) ? '' : `${user.name}, ${user.firstName} ${user.lastName}, ${user.email}`;
  }

  concatName(user: GenUser) {
    return (user) ? `${user.firstName} ${user.lastName}` : ``;
  }

  removeUsername(i: number) {
    this.fileShare.recipientUsernames.splice(i, 1);
  }

  onRoleChange(fileShare: FileShare, role: GenRoleDefinition): void {
    if (!fileShare.roles) {
      fileShare.roles = [];
    }
    const has = fileShare.hasRole(role);
    fileShare.changeRole(!has, role);
  }

  roleDescription(roleDefinition: GenGistRoleDefinition): string {
    return roleDefinition.description;
  }

  save() {
    this.fileShare.expirationDate = moment(this.fileShare.expirationDate).endOf('day');
    const me = this;
    const file = this.file;

    this.uploadInProgress = true;
    this.uploadPercentage = 0;
    this.lastProgressUpdateTime = 0;

    this.fileShareService.save(this.fileShare, file)
      .subscribe(response => {
        if (response instanceof ProgressEvent) {
          if (response.lengthComputable && (new Date().getTime() - this.lastProgressUpdateTime) > 500) {
            this.uploadPercentage = response.loaded / response.total * 100.0;
            this.lastProgressUpdateTime = new Date().getTime();
          }
        } else if (response instanceof XMLHttpRequest) {
          this.uploadInProgress = false;
          const msg: string = me.fileShare.id ? 'Shared File Updated' : 'Shared File Uploaded';
          me.popup.open(msg, 'okay', {duration: 2000, panelClass: ['success']});
          this.ref.close(this.fileShare);
        }
      }, (response) => {
        this.uploadInProgress = false;
        let msg = 'Save Failed';
        try {
          const error = JSON.parse(response.responseText);
          if (error && error.userMessage) {
            msg += (': ' + error.userMessage);
          }
        } catch (err) {
        }
        me.popup.open(msg, 'okay', {duration: 300000, panelClass: ['failure']});
      });
  }


  selectUser(event: MatOptionSelectionChange, user: User) {
    if (event.isUserInput) {
      if (!this.fileShare.recipientUsernames) {
        this.fileShare.recipientUsernames = [];
      }
      this.fileShare.recipientUsernames.push(user.username);
      this.userControl.setValue('');
    }
  }

  addRole() {
    this.addingNewRole = true;
    const intId = window.setInterval(() => {
      if (this.roleInput) {
        window.clearInterval(intId);
        (this.roleInput.nativeElement as HTMLElement).focus();
      }
    }, 200);
  }

  addRoleFinish() {
    this.addingNewRole = false;
    if (this.addRoleName && !this.nameInUse) {
      const role = new GenGistRoleDefinition();
      role.name = `ROLE_${this.addRoleName.toUpperCase().split(' ').join('_')}`;
      role.description = this.addRoleName;
      role.authority = role.name;
      this.addRoleName = '';
      this.userService.addRoles([role]).subscribe((newRoles) => {
        this.popup.open(`Successfully added ${role.description}`, 'okay', {duration: 5000, panelClass: ['success']});
        this.userService.setRoleMap(newRoles);
        this.user.roleMap = this.userService.roles.value;
        this.editableRoles = [];
        this.user.roleMap.forEach((value, key) => {
          if (!key.includes('_KEY_') && key !== 'ROLE_APPROVED_USER' && key !== 'ROLE_RESETTING_PASSWORD') {
            this.editableRoles.push(value);
          }
        });
      }, () => {
        this.popup.open(`Error adding role ${role.description}`, 'okay', {duration: 15000, panelClass: ['failure']});
      });
    }
  }
}
