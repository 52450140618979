import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalViewData} from "../create-task/create-task.component";
import {Task} from "../../../../types/task.class";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'portal-edit-task-dialog',
  templateUrl: './edit-task-dialog.component.html',
  styleUrls: ['./edit-task-dialog.component.scss']
})
export class EditTaskDialogComponent implements OnInit {

  modalViewData: ModalViewData;
  scrolled = false;
  saveSub: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  isValid = false;
  taskName = 'Task';


  constructor(public dialogRef: MatDialogRef<EditTaskDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalViewData = data;
    this.isValid = this.data.task && (!!this.data.task.name && !!this.data.task.releaseNotes && !!this.data.task.releaseTimestamp);
    // CheckMarx scans complain about using this same code if it is in the HTML.
    // Putting it here since Angular sanitizes the content anyway and it is not really a vulnerability.
    this.taskName = data.task?.name || 'New ' + data.project.name + ' Item';
  }

  contentScrolled(el: Element) {
    this.scrolled = (el as HTMLElement).scrollTop !== 0
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  taskSaved(task: Task) {
    this.dialogRef.close(task);
  }

  save() {
    this.saveSub.next({saving: true});
  }

}
