import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { User } from '../types/user.class';

@Injectable({
  providedIn: 'root'
})
export class SurveyGuard  {
  constructor(public userService: UserService,public router: Router) {}
   /**
     * Creates a guard that will only let users view a page if
     * they have at least one role
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     *
     * @returns Promise<boolean>
     )     */
     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.userService.me().pipe(
          map((user: User) => {
              if (user.roles && user.roles.length > 0) {
                  this.router.navigateByUrl('/');
                  return false;
              }
              return true;
          })
      );
  }

}
