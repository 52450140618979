<div mat-dialog-title class="dialog-header" [class.flex-row]="true" [class.flex-align-start-center]="true"
     [class.gap-8]="true">
  <mat-icon class="prompt-icon" [ngClass]="{'med': data.level === 'med', 'high': data.level === 'high'}">error
  </mat-icon>
  <div class="prompt-title">
    {{ data.action.charAt(0).toUpperCase() + data.action.slice(1) }} {{ data.type.charAt(0).toUpperCase() + data.type.slice(1) }}
  </div>
</div>
<mat-dialog-content>
  <div class="full-width full-height content">
    Are you sure you want to {{ data.action.toLowerCase() }}
    the {{ data.type.toLowerCase() }} {{ data.name }}{{ (!data.hasEnding) ? '?' : '' }}
  </div>
</mat-dialog-content>
<mat-dialog-actions [class.flex-row]="true" [class.flex-align-end-center]="true" [class.gap-12]="true">
  <button mat-raised-button [class.flex-145]="true" (click)="dialogRef.close(false)">CANCEL</button>
  <button mat-raised-button color="primary" [class.flex-145]="true"
          (click)="dialogRef.close(true)">{{ data.action.toUpperCase() }}
  </button>
</mat-dialog-actions>
