<div class="portal-toolbar" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-18]="true">
  <img class="logo" alt="ORNL logo" src="assets/ornl-logo-black.png"/>
  <span class="title" title="Show All Projects" (click)="home()">Human Dynamics Portal</span>
  <div class="header-button-container" *ngIf="user" [class.flex-row]="true" [class.flex-align-start-center]="true"
       [class.gap-12]="true">
    <button mat-raised-button *ngFor="let button of headerButtons" color="primary" class="header-button"
            (click)="onHeaderButtonClick(button)">
      <mat-icon class="header-button-icon" *ngIf="button.icon">{{ button.icon }}</mat-icon>
      {{ button.title }}
    </button>
  </div>
  <!--suppress TypeScriptValidateTypes -->
  <button *ngIf="user && user.roles && user.roles.length > 0" mat-icon-button
          [mat-menu-trigger-for]="menu" class="menu-button">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu x-position="after" #menu="matMenu">
  <a *ngIf="user" [routerLink]="'/user/' + user.username" mat-menu-item>
    <mat-icon>person</mat-icon>
    {{ user.username }} profile
  </a>
  <a *ngIf="user && user.isApplicationManager()" routerLink="/user" mat-menu-item>
    <mat-icon>groups</mat-icon>
    Manage Users
  </a>
  <a *ngIf="user && user.isAdmin()" routerLink="/admin/key" mat-menu-item>
    <mat-icon>vpn_key</mat-icon>
    Keys...
  </a>
  <a routerLink="/" mat-menu-item>
    <mat-icon>folder</mat-icon>
    Projects
  </a>
  <a routerLink="/map" mat-menu-item *ngIf="user && user.isContributor()">
    <mat-icon>map</mat-icon>
    Data Completeness Map
  </a>
  <a mat-menu-item *ngIf="user && (user.isSponsor() || user.isPublisher() || user.isAdmin())"
     (click)="navigate('/reporting')">
    <mat-icon>table_rows</mat-icon>
    Site Metrics Report
  </a>
  <button *ngIf="trelloEnabled && user && user.isContributor()" (click)="pullTrelloUpdates()" mat-menu-item>
    Get Trello Changes
  </button>
  <!--  <button *ngIf="user && user.isAdmin()" (click)="fileTypeMigration()" mat-menu-item>File Type Migration</button>-->
  <button *ngIf="user && (user.isAdmin() || user.isPublisher())" (click)="shareTempFile()" mat-menu-item>
    <mat-icon>share</mat-icon>
    Share Temporary File
  </button>
  <a *ngIf="user && !user.impersonating" href="/logout" mat-menu-item>
    <mat-icon>logout</mat-icon>
    Logout
  </a>
  <button *ngIf="user && user.impersonating" (click)="unimpersonate()" mat-menu-item>
    <mat-icon>logout</mat-icon>
    Logout (unimpersonate)
  </button>
</mat-menu>

<mat-menu #taskMenu="matMenu">
  <a *ngFor="let task of tasksNeedingReview; let i = index" routerLink="/project/{{ownedProject.id}}/task/{{task.id}}"
     mat-menu-item>
    <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-10]="true"
         style="width: 100%; height: 100%;">
      <div class="task-name">{{ task.name }}</div>
    </div>
  </a>
</mat-menu>

<main>
  <router-outlet (activate)="onRouteActivate($event)"></router-outlet>
</main>

<div *ngIf="!(sessionIsValid | async)" class="session-fault-mask">
  <div class="session-fault-message">
    There is a problem with the server connection.<br/>
    You may need to check your network connection or refresh the page.
  </div>
</div>
