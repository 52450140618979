
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenAttachment {

    _id: string;
    bytes: string;
    date: moment.Moment;
    idMember: string;
    name: string;
    url: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genAttachment: GenAttachment);

    constructor( input?: GenAttachment ) {
        if ( input && typeof input !== 'number' ) {
            this._id = input._id; // string [string]
            this.bytes = input.bytes; // string [string]
            if ( input.date && typeof input.date === 'string' ) {
                this.date = moment(input.date).utc();
            } else {
                this.date = input.date;
            }
            this.idMember = input.idMember; // string [string]
            this.name = input.name; // string [string]
            this.url = input.url; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenAttachment[] {
        if (arr && arr.map) {
          return arr.map(a => new GenAttachment(a));
        } else {
          return [];
        }
    }
}

