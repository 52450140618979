import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { GenSiteMetricsReport } from '../generated/serverModels/GenSiteMetricsReport';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private http: HttpClient) {
  }

  getSiteMetricsReport(startDate: moment.Moment, endDate: moment.Moment): Observable<GenSiteMetricsReport> {
      return this.http.get<GenSiteMetricsReport>(`api/reporting/site-metrics?startDate=${startDate.format()}&endDate=${endDate.format()}`);
  }
}
