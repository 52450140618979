<div mat-dialog-title>
  WMS Management
</div>

<div mat-dialog-content>
  <mat-form-field>
      <input type="text" matInput placeholder="Display Name" [(ngModel)]="data.displayName" name="displayName">
  </mat-form-field>

  <mat-form-field>
      <mat-select placeholder="Style" [(ngModel)]="data.styleName">
          <mat-option *ngFor="let style of styles" [value]="style.name">{{style.name}}</mat-option>
      </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions [class.flex-row]="true" [class.flex-align-end-center]="true" [class.gap-5]="true">
  <button mat-raised-button (click)="close()">
      Cancel
  </button>
  <button mat-raised-button color="primary" (click)="update()">
      Update WMS Info
  </button>
</div>
