
<div class="full-width full-height reporting-container" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-12]="true">
  <div class="loading-overlay" [class.show]="loading">
    <div *ngIf="loading" class="loading-mask">
      <mat-spinner diameter="150"></mat-spinner>
    </div>
  </div>
  <div class="full-width" [class.flex-row]="true" [class.flex-align-start-end]="true" [class.gap-16]="true">
    <div class="title" [class.flex-grow]="true">Site Metrics</div>
    <mat-form-field class="no-bottom-padding portal-date-picker" [class.flex-145]="true" appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput
               [(ngModel)]="startDate"
               [matDatepicker]="sd"
               (dateChange)="getReport()">
        <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #sd></mat-datepicker>
    <mat-form-field class="no-bottom-padding portal-date-picker" [class.flex-145]="true" appearance="outline">
        <mat-label>End Date</mat-label>
        <input matInput
               [(ngModel)]="endDate"
               [matDatepicker]="ed"
               (dateChange)="getReport()">
        <mat-datepicker-toggle matSuffix [for]="ed"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #ed></mat-datepicker>
  </div>
  <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-24]="true">
    <div *ngFor="let report of siteMetricReportCards" [class.flex-grow]="true" class="report-card" [class.flex-column]="true" [class.flex-align-start-center]="true" [class.gap-24]="true">
      <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true">
        <mat-icon class="report-card-icon" [svgIcon]="report.icon"></mat-icon>
      </div>
      <div class="report-card-value">{{report.value}}</div>
      <div class="report-card-label">{{report.label}}</div>
    </div>
    <div *ngIf="downloadReportCard && taskStatusReportCards.length" class="divider"></div>
    <div *ngIf="downloadReportCard" [class.flex-grow]="true" class="report-card" [class.flex-column]="true" [class.flex-align-start-center]="true" [class.gap-24]="true">
      <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true">
        <mat-icon class="report-card-icon" [svgIcon]="downloadReportCard.icon"></mat-icon>
      </div>
      <div class="report-card-value">{{downloadReportCard.value}}</div>
      <div class="report-card-label">{{downloadReportCard.label}}</div>
    </div>
    <div *ngIf="taskStatusReportCards.length" class="divider"></div>
    <div *ngFor="let report of taskStatusReportCards" [class.flex-grow]="true"  class="report-card" [class.flex-column]="true" [class.flex-align-start-center]="true" [class.gap-24]="true">
      <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true">
        <mat-icon class="report-card-icon" [svgIcon]="report.icon"></mat-icon>
      </div>
      <div class="report-card-value">{{report.value}}</div>
      <div class="report-card-label">{{statusLabel(report.status, report.value)}}</div>
    </div>
  </div>
  <div class="title">New users for selected dates</div>
  <div class="full-width table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
        <mat-cell *matCellDef="let element"><div class="full-width">{{displayCell(element, 'user')}}</div></mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let element"><div class="full-width">{{displayCell(element, 'status')}}</div></mat-cell>
      </ng-container>
      <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Organization</mat-header-cell>
        <mat-cell *matCellDef="let element"><div class="full-width">{{displayCell(element, 'organization')}}</div></mat-cell>
      </ng-container>
      <ng-container matColumnDef="downloadCount">
        <mat-header-cell *matHeaderCellDef mat-sort-header># of items Downloaded</mat-header-cell>
        <mat-cell *matCellDef="let element"><div class="full-width">{{displayCell(element, 'downloadCount')}}</div></mat-cell>
      </ng-container>
      <ng-container matColumnDef="requestReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Reason for account</mat-header-cell>
        <mat-cell *matCellDef="let element"><div class="full-width">{{displayCell(element, 'requestReason')}}</div></mat-cell>
      </ng-container>
      <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </div>
  <div class="full-width flex-align-end-end" [class.flex-row]="true" [class.gap-16]="true">
    <div class="title" [class.flex-grow]="true">Items modified within selected dates</div>
    <div *ngIf="downloadUrl.length" matRipple class="download-selected-link clickable" (click)="downloadSelected.click()" [class.flex-125]="true" [class.flex-column]="true" [class.flex-align-center-center]="true">
      <div>Download Checked</div>
    </div>
    <mat-form-field class="no-bottom-padding portal-select" appearance="outline" floatLabel="always" [class.flex-125]="true" *ngIf="user.isContributor()">
      <mat-label>Status</mat-label>
      <mat-select placeholder="Any Status" [(value)]="currentStatus">
        <mat-option [value]="undefined" (click)="filterTasks()">Any Status
        </mat-option>
        <ng-container *ngFor="let status of statuses; let i = index;">
          <mat-option [value]="status" (click)="filterTasks()">{{prettyStatus(status)}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-24]="true">
    <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-8]="true">
      <portal-task-table  class="full-width task-table"
                          [user]="user"
                          [showThumbnails]="true"
                          [view]="'report'"
                          [projects]="projects"
                          [tasks]="tasks"
                          (selectedTaskChange)="downloadUrl = $event">
      </portal-task-table>
      <a #downloadSelected style="display: none" [href]="downloadUrl" target="_blank"></a>
    </div>
  </div>
</div>
