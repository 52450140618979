import {GenFileShare} from "../generated/serverModels/GenFileShare";
import {GenRoleDefinition} from "../generated/serverModels/GenRoleDefinition";

export class FileShare extends GenFileShare {

  public roleMap: Map<string, GenRoleDefinition> = new Map<string, GenRoleDefinition>();

  constructor(roleMap: Map<string, GenRoleDefinition>, data?: any) {
    super(data);
    this.roleMap = roleMap;
    if (roleMap) {
      this.roles = (data && data.roles && typeof data.roles[0] === 'string') ? data.roles.map(r => this.roleMap.get(r)) : this.roles;
    }
  }

  hasRole(role: GenRoleDefinition | string): boolean {
    if (!this.roles) {
      return false;
    }
    if ( typeof role === 'string') {
      // @ts-ignore
      const r: string[] = (typeof this.roles[0] === 'string') ? this.roles : this.roles.map(r => r.name);
      return r.some((userRole: string) => {
        return userRole === role;
      });
    } else {
      return this.roles.some((userRole: GenRoleDefinition) => {
        return userRole.name === role.name;
      });
    }
  }

  changeRole(value: boolean, role: GenRoleDefinition) {
    if (value && !this.hasRole(role)) {
      this.roles.push(role);
    }
    if (!value && this.hasRole(role)) {
      this.roles = this.roles.filter(r => r !== role);
    }
  }
}
