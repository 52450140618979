import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {startWith} from 'rxjs/operators';
import {GenProject} from 'src/app/generated/serverModels/GenProject';
import {BreadCrumbService} from 'src/app/services/bread-crumb.service';
import {ProjectService} from 'src/app/services/project.service';
import {Project} from 'src/app/types/project.class';
import {User} from 'src/app/types/user.class';

import {Task} from 'src/app/types/task.class';
import {TaskService} from '../../../../services/task.service';
import {PromptComponent, PromptData} from '../../../shared/components/prompt/prompt.component';
import {FileShareService} from '../../../../services/file-share.service';
import {FileShare} from '../../../../types/file-share.class';
import {UserService} from '../../../../services/user.service';
import {HeaderButton} from '../../../../app.component';
import {ProjectFormDialogComponent} from '../project-form-dialog/project-form-dialog.component';

@Component({
  selector: 'portal-project-browser',
  templateUrl: './project-browser.component.html',
  styleUrls: ['./project-browser.component.scss']
})
export class ProjectBrowserComponent implements OnInit {

  searchControl: any;
  projects: Project[];
  allProjects: Project[] = [];
  user: User;
  adminEmail: string;
  view = 'grid';
  recentTasks: Task[] = [];
  multiSelectDownloadUrl = '';
  fileShares: FileShare[] = [];

  constructor(private projectService: ProjectService,
              private taskService: TaskService,
              public router: Router,
              public dialog: MatDialog,
              public breadcrumbService: BreadCrumbService,
              public popup: MatSnackBar,
              public activatedRoute: ActivatedRoute,
              private fileShareService: FileShareService,
              private userService: UserService) {
    this.adminEmail = (window as any).data.adminEmail;

    // create service.

    this.taskService.getRecentTasks().subscribe((tasks) => {
      this.recentTasks = tasks;
    });

    this.searchControl = new UntypedFormControl();
    this.searchControl.valueChanges.pipe(
      startWith(''),
    ).subscribe(name => {
      this.applySearchFilter(name);
    });
    this.breadcrumbService.clear();
    this.user = this.activatedRoute.snapshot.data.user;
  }

  ngOnInit(): void {
    this.user = this.activatedRoute.snapshot.data.user;
    this.projectService.getAll().subscribe(projects => {
      this.allProjects = projects;
      if (!this.user.roleMap) {
        this.user.roleMap = this.userService.roles.value;
      }
      this.allProjects = projects.map(p => new Project(p));
      this.applySearchFilter('');
    });

    this.fileShareService.getMyFiles().subscribe((fileShares) => {
      this.fileShares = fileShares;
      if (!this.user.roleMap) {
        this.user.roleMap = this.userService.roles.value;
      }
      this.fileShares = fileShares.map(f => new FileShare(this.user.roleMap, f));
    });

  }

  viewSharedFiles() {
    this.router.navigate(['project', 'fileshare']);
  }

  edit(project?: Project): void {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '55vw',
      maxHeight: '98vh',
      panelClass: 'dialog-with-no-padding',
      data: {
        user: this.user,
        project
      }
    };
    this.dialog.open(ProjectFormDialogComponent, configData).afterClosed().subscribe((updatedProject: Project) => {
      if (updatedProject) {
        if (project) {
          const index = this.projects.findIndex(p => p.id === updatedProject.id);
          if (index !== -1) {
            this.projects[index] = updatedProject;
          }
        } else {
          this.projects.push(updatedProject);
        }
        this.projects = this.projects.slice();

      }
    });
  }

  remove(projectToDelete: Project): void {
    const promptData: PromptData = {
      level: 'high',
      type: 'project',
      action: 'delete',
      name: projectToDelete.name
    };
    this.dialog.open(PromptComponent, {
      data: promptData,
      panelClass: 'dialog-with-no-padding',
      width: '50vw'
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.projectService.remove(projectToDelete).subscribe(() => {
          this.popup.open('project Deleted', 'okay', {duration: 2000, panelClass: ['success']});
          this.projects = this.projects.filter(d => d !== projectToDelete);
        });
      }
    });
  }

  viewTasks(project: Project): void {
    this.router.navigate(['project', project.id, 'task']);
  }

  applySearchFilter(searchText: string): void {
    if (searchText) {
      const terms: string[] = searchText.toLowerCase().split(' ');
      this.projects = this.allProjects
        .filter(doc => {
          const pocString = [doc.pointOfContact.email, doc.pointOfContact.firstName, doc.pointOfContact.lastName,
            doc.pointOfContact.username, doc.pointOfContact.name].join();
          return terms.every((term) => {
            if (doc.name.toLowerCase().indexOf(term) >= 0) {
              return true;
            }
            // noinspection RedundantIfStatementJS
            if (pocString.indexOf(term) >= 0) {
              return true;
            }
            return false;
          });
        });
    } else {
      this.projects = this.allProjects;
    }
    if (this.projects) {
      this.projects.sort((a: GenProject, b: GenProject) => {
        return (b.name > a.name) ? -1 : (a.name > b.name) ? 1 : 0;
      });
    }
  }

  // noinspection JSUnusedGlobalSymbols
  headerButtonAction(headerButton: HeaderButton) {
    if (headerButton.action === 'create') {
      this.upload();
    }
  }

  upload(): void {
    this.edit();
  }

  getProjectSrc(project: Project) {
    if (project.thumbnail) {
      return project.getThumbnailPath();
    } else {
      switch (project.name) {
        case 'Building Feature Extraction':
          return 'assets/login_illustration.png';
        case 'LandScan':
        case 'HSIP':
          return 'assets/landscan.png';
        case 'NGA Data':
          return 'assets/NGA.png';
        case 'Points of Interest':
          return 'assets/pointsofinterest.png';
        default:
          return '';
      }
    }
  }

}
