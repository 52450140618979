
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenScope {
    static readonly Global: GenScope = new GenScope('Global');
    static readonly National: GenScope = new GenScope('National');
    static readonly Subnational: GenScope = new GenScope('Subnational');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'Global') return GenScope.Global;
        if (name === 'National') return GenScope.National;
        if (name === 'Subnational') return GenScope.Subnational;
        throw new Error('Unexpected value for GenScope:' + name);
    }

    static values(): GenScope[] {
        return [
            GenScope.Global,
            GenScope.National,
            GenScope.Subnational,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

