
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenGenLocation } from './GenGenLocation';
import { GenScope } from './GenScope';
import { GenUser } from './GenUser';
import { GenWmsProcessStatus } from './GenWmsProcessStatus';
import { GenWorkflowStatus } from './GenWorkflowStatus';
import * as moment from 'moment';

export class GenTask {

    id: number;
    archived: boolean;
    contributor: GenUser;
    downloadCount: number;
    fileLength: number;
    fileType: string;
    lastDownloadTimestamp: moment.Moment;
    lastModified: moment.Moment;
    location: GenGenLocation;
    locationId: number;
    myDownloadCount: number;
    name: string;
    open: boolean;
    originalFilename: string;
    projectId: number;
    publishedTimestamp: moment.Moment;
    releaseNotes: string;
    releaseTimestamp: moment.Moment;
    rootDirectory: string;
    scope: GenScope;
    serverFilename: string;
    status: GenWorkflowStatus;
    sticky: boolean;
    syncTrello: boolean;
    tags: string[];
    thumbnail: string;
    trelloId: string;
    url: string[];
    wmsProcessErrorMessage: string;
    wmsProcessStatus: GenWmsProcessStatus;

    constructor();
    constructor( jsonObj: any );
    constructor( genTask: GenTask);
    constructor( id: number );

    constructor( input?: GenTask|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.archived = input.archived; // boolean [boolean]
            if( input.contributor ) {
              this.contributor = new GenUser( input.contributor );
            }
            this.downloadCount = input.downloadCount; // int [number]
            this.fileLength = input.fileLength; // int [number]
            this.fileType = input.fileType; // string [string]
            if ( input.lastDownloadTimestamp && typeof input.lastDownloadTimestamp === 'string' ) {
                this.lastDownloadTimestamp = moment(input.lastDownloadTimestamp).utc();
            } else {
                this.lastDownloadTimestamp = input.lastDownloadTimestamp;
            }
            if ( input.lastModified && typeof input.lastModified === 'string' ) {
                this.lastModified = moment(input.lastModified).utc();
            } else {
                this.lastModified = input.lastModified;
            }
            if( input.location ) {
              this.location = new GenGenLocation( input.location );
            }
            this.locationId = input.locationId; // int [number]
            this.myDownloadCount = input.myDownloadCount; // int [number]
            this.name = input.name; // string [string]
            this.open = input.open; // boolean [boolean]
            this.originalFilename = input.originalFilename; // string [string]
            this.projectId = input.projectId; // int [number]
            if ( input.publishedTimestamp && typeof input.publishedTimestamp === 'string' ) {
                this.publishedTimestamp = moment(input.publishedTimestamp).utc();
            } else {
                this.publishedTimestamp = input.publishedTimestamp;
            }
            this.releaseNotes = input.releaseNotes; // string [string]
            if ( input.releaseTimestamp && typeof input.releaseTimestamp === 'string' ) {
                this.releaseTimestamp = moment(input.releaseTimestamp).utc();
            } else {
                this.releaseTimestamp = input.releaseTimestamp;
            }
            this.rootDirectory = input.rootDirectory; // string [string]
            this.scope = (typeof input.scope === 'string') ? GenScope.forName(<string> input.scope) : <any> input.scope; // GenScope
            this.serverFilename = input.serverFilename; // string [string]
            this.status = (typeof input.status === 'string') ? GenWorkflowStatus.forName(<string> input.status) : <any> input.status; // GenWorkflowStatus
            this.sticky = input.sticky; // boolean [boolean]
            this.syncTrello = input.syncTrello; // boolean [boolean]
            this.tags = input.tags; // list [string]
            this.thumbnail = input.thumbnail; // string [string]
            this.trelloId = input.trelloId; // string [string]
            this.url = input.url; // list [string]
            this.wmsProcessErrorMessage = input.wmsProcessErrorMessage; // string [string]
            this.wmsProcessStatus = (typeof input.wmsProcessStatus === 'string') ? GenWmsProcessStatus.forName(<string> input.wmsProcessStatus) : <any> input.wmsProcessStatus; // GenWmsProcessStatus
       }
    }

    static jsonResponseToList(arr: any[]): GenTask[] {
        if (arr && arr.map) {
          return arr.map(a => new GenTask(a));
        } else {
          return [];
        }
    }
}

