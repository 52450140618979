import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {GenGistRoleDefinition} from '../../../../generated/serverModels/GenGistRoleDefinition';

@Component({
  selector: 'portal-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  roleToAdd = '';
  roles: GenRoleDefinition[] = [];
  existingRoles: GenRoleDefinition[] = [];

  constructor(public ref: MatDialogRef<AddRoleComponent>,
              @Inject( MAT_DIALOG_DATA ) private data: GenRoleDefinition[]) {
    this.existingRoles = data;
  }

  ngOnInit(): void {
  }

  get nameInUse(): boolean {
    if (this.roleToAdd) {
      const existing = this.existingRoles.map(r => r.name.replace('ROLE_', '').split('_').join(' '));
      return existing.indexOf(this.roleToAdd.toUpperCase()) !== -1;
    } else {
      return false;
    }
  }

  get isValid(): boolean {
    return (!!this.roleToAdd && !this.nameInUse) || !!this.roles.length;
  }

  save() {
    if (this.roleToAdd && !this.nameInUse) {
      const roleDef = new GenGistRoleDefinition();
      roleDef.description = this.roleToAdd;
      roleDef.name = `ROLE_${this.roleToAdd.replace(/[^a-zA-Z0-9_ -]/g, '').toUpperCase().split(' ').join('_')}`;
      roleDef.authority = roleDef.name;
      this.roles.push(roleDef);
    }
    this.ref.close(this.roles);
  }

  close() {
    this.ref.close();
  }

  removeRole(index: number) {
    this.roles.splice(index, 1);
  }

  pushRole() {
    if (this.roleToAdd && !this.nameInUse) {
      const roleDef = new GenGistRoleDefinition();
      roleDef.description = this.roleToAdd;
      roleDef.name = `ROLE_${this.roleToAdd.replace(/[^a-zA-Z0-9_ -]/g, '').toUpperCase().split(' ').join('_')}`;
      roleDef.authority = roleDef.name;
      this.roles.push(roleDef);
      this.roleToAdd = '';
    }
  }
}
