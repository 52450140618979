
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUser } from './GenUser';

export class GenProject {

    id: number;
    members: GenUser[];
    name: string;
    notes: string;
    pointOfContact: GenUser;
    roles: string[];
    rootDirectory: string;
    serverFilename: string;
    thumbnail: string;
    totalTasks: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genProject: GenProject);
    constructor( id: number );

    constructor( input?: GenProject|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.members && input.members.map ) { // list [GenUser]
                this.members = input.members.map( ( x: GenUser ) => {
                    return new GenUser( x );
                } );
            } else {
                this.members = input.members;
            }
            this.name = input.name; // string [string]
            this.notes = input.notes; // string [string]
            if( input.pointOfContact ) {
              this.pointOfContact = new GenUser( input.pointOfContact );
            }
            this.roles = input.roles; // list [string]
            this.rootDirectory = input.rootDirectory; // string [string]
            this.serverFilename = input.serverFilename; // string [string]
            this.thumbnail = input.thumbnail; // string [string]
            this.totalTasks = input.totalTasks; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenProject[] {
        if (arr && arr.map) {
          return arr.map(a => new GenProject(a));
        } else {
          return [];
        }
    }
}

