import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ProjectService} from 'src/app/services/project.service';
import {UserService} from 'src/app/services/user.service';
import {Project} from 'src/app/types/project.class';
import {User} from 'src/app/types/user.class';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {GenGistRoleDefinition} from '../../../../generated/serverModels/GenGistRoleDefinition';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'portal-project-form-dialog',
  templateUrl: './project-form-dialog.component.html',
  styleUrls: ['./project-form-dialog.component.scss']
})
export class ProjectFormDialogComponent implements OnInit {
  users: User[];
  filteredContributors: Observable<User[]>;
  contributorControl: UntypedFormControl;
  filteredPOC: Observable<User[]>;
  pocControl: UntypedFormControl;
  thumbnailFile: File;

  project: Project;
  roleDefinitions: GenRoleDefinition[];
  addingNewRole = false;
  @ViewChild('addRoleInput', {static: false}) roleInput: ElementRef;
  addRoleName = '';
  user: User;
  isValid = false;

  get nameInUse(): boolean {
    if (this.addRoleName) {
      const existing = this.roleDefinitions.map(r => r.name.replace('ROLE_', '').split('_').join(' '));
      return existing.indexOf(this.addRoleName.toUpperCase()) !== -1;
    } else {
      return false;
    }
  }

  get maxRoleHeight(): number {
    const addOne = (this.addingNewRole) ? 1 : 0;
    return Math.ceil((this.roleDefinitions.length + addOne) / 2) * 36;
  }

  constructor(private popup: MatSnackBar,
              public ref: MatDialogRef<Project>,
              @Inject(MAT_DIALOG_DATA) public data: ProjectFormComponentData,
              private router: Router,
              private projectService: ProjectService,
              private userService: UserService) {
    this.contributorControl = new UntypedFormControl();
    this.pocControl = new UntypedFormControl();

    this.project = this.data.project || new Project();
    this.checkValidity();

    this.userService.me().subscribe(u => this.user = u);

    this.userService.getContributors().subscribe((users) => {
      this.users = users.sort((user1, user2) => {
        return (user1.name.toLowerCase() < user2.name.toLowerCase()) ? -1 : (user1.name.toLowerCase() > user2.name.toLowerCase()) ? 1 : 0;
      }).filter((user) => {
        return user.isContributor();
      });

      this.filteredContributors = this.contributorControl.valueChanges.pipe(
        startWith(''),
        map(name => {
          return this.filterUsers(name);
        }));
      this.filteredPOC = this.pocControl.valueChanges.pipe(
        startWith(''),
        map(name => {
          return this.filterUsers(name);
        }));
    });
  }

  ngOnInit() {
    const project = this.project;
    if (project) {
      this.project = project;
      if (project.pointOfContact) {
        this.pocControl.setValue(project.pointOfContact.name);
      }
    }
    this.roleDefinitions = [];
    this.userService.roles.value.forEach((value, key) => {
      if (!key.includes('_KEY_') && key !== 'ROLE_APPROVED_USER' && key !== 'ROLE_RESETTING_PASSWORD') {
        this.roleDefinitions.push(value);
      }
    });
  }

  displayUser(user: User): string {
    return (!user) ? '' : `${user.name}, ${user.firstName} ${user.lastName}, ${user.email}`;
  }

  selectPOC(user: User) {
    this.project.pointOfContact = user;
  }

  save(): void {
    const me = this;
    const creating = !this.project.id;
    // TODO copy values from the FormControls to the project.
    this.projectService.save(this.project, this.thumbnailFile).subscribe(response => {
      if (response.responseType !== undefined && response.status === 200) {
        const createdProject = new Project(JSON.parse(response.response));
        this.ref.close(createdProject);
        const msg: string = me.project.id ? 'Project Updated' : 'Project Uploaded';
        me.popup.open(msg, 'okay', {duration: 2000, panelClass: ['success']});
        if (creating) {
          me.router.navigate(['']);
        }
      } else {
        let msg = 'Save Failed';
        try {
          const error = JSON.parse(response.responseText);
          if (error?.userMessage) {
            msg += (': ' + error.userMessage);
          }
        } catch (err) {
        }
        me.popup.open(msg, 'okay', {duration: 60000, panelClass: ['failure']});
      }
    });
  }

  checkValidity() {
    this.isValid = !!this.project.name && !!this.project.pointOfContact && !!this.project.roles.length;
  }

  onRoleChange(role: GenRoleDefinition): void {
    const has = this.project.hasRole(role);
    this.project.changeRole(!has, role);
    this.checkValidity();
  }

  private filterUsers(val: string) {
    val = (val || '').toLowerCase().trim();
    const searchArr = val.split(' ');
    return this.users.filter(user => {
      const haystack = [user.firstName, user.lastName, user.email, user.name].concat(user.roles.map(role => role.name)).join().toLowerCase();
      return ((!this.project.members || this.project.members.indexOf(user) === -1) && searchArr.every((val) => haystack.indexOf(val) !== -1));
    });
  }

  addRole() {
    this.addingNewRole = true;
    const intId = window.setInterval(() => {
      if (this.roleInput) {
        window.clearInterval(intId);
        (this.roleInput.nativeElement as HTMLElement).focus();
      }
    }, 200);
  }

  addRoleFinish() {
    this.addingNewRole = false;
    if (this.addRoleName && !this.nameInUse) {
      const role = new GenGistRoleDefinition();
      role.name = `ROLE_${this.addRoleName.toUpperCase().split(' ').join('_')}`;
      role.description = this.addRoleName;
      role.authority = role.name;
      this.addRoleName = '';
      this.userService.addRoles([role]).subscribe((newRoles) => {
        this.popup.open(`Successfully added ${role.description}`, 'okay', {duration: 5000, panelClass: ['success']});
        this.userService.setRoleMap(newRoles);
        this.user.roleMap = this.userService.roles.value;
        this.roleDefinitions = [];
        this.user.roleMap.forEach((value, key) => {
          if (!key.includes('_KEY_') && key !== 'ROLE_APPROVED_USER' && key !== 'ROLE_RESETTING_PASSWORD') {
            this.roleDefinitions.push(value);
          }
        });
      }, () => {
        this.popup.open(`Error adding role ${role.description}`, 'okay', {
          duration: 15000,
          panelClass: ['failure']
        });
      });
    }
  }
}

export interface ProjectFormComponentData {
  project: Project;
}
