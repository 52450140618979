
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRoleDefinition } from './GenRoleDefinition';
import * as moment from 'moment';

export class GenUser {

    id: number;
    accountEnabled: boolean;
    apiKey: string;
    approved: boolean;
    contractor: boolean;
    dataModifier: boolean;
    department: string;
    downloadCount: number;
    email: string;
    emailVerificationCode: string;
    emailVerified: boolean;
    enabled: boolean;
    expirationDate: moment.Moment;
    firstName: string;
    fullyApproved: boolean;
    impersonating: boolean;
    internalNotes: string;
    joinDate: moment.Moment;
    lastLoginDate: moment.Moment;
    lastName: string;
    lastRequestUri: string;
    name: string;
    organization: string;
    originatingSystem: string;
    password: string;
    passwordResetCode: string;
    passwordResetTime: moment.Moment;
    phone: string;
    pkiSubject: string;
    pocApproved: boolean;
    pocEmail: string;
    pocName: string;
    pocNotifiedDate: moment.Moment;
    pocOrganization: string;
    pocPhone: string;
    pocVerificationCode: string;
    pocVerified: boolean;
    reason: string;
    requestReason: string;
    roles: GenRoleDefinition[];
    subOrganization: string;
    subscribed: boolean;
    username: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUser: GenUser);
    constructor( id: number );

    constructor( input?: GenUser|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.accountEnabled = input.accountEnabled; // boolean [boolean]
            this.apiKey = input.apiKey; // string [string]
            this.approved = input.approved; // boolean [boolean]
            this.contractor = input.contractor; // boolean [boolean]
            this.dataModifier = input.dataModifier; // boolean [boolean]
            this.department = input.department; // string [string]
            this.downloadCount = input.downloadCount; // int [number]
            this.email = input.email; // string [string]
            this.emailVerificationCode = input.emailVerificationCode; // string [string]
            this.emailVerified = input.emailVerified; // boolean [boolean]
            this.enabled = input.enabled; // boolean [boolean]
            if ( input.expirationDate && typeof input.expirationDate === 'string' ) {
                this.expirationDate = moment(input.expirationDate).utc();
            } else {
                this.expirationDate = input.expirationDate;
            }
            this.firstName = input.firstName; // string [string]
            this.fullyApproved = input.fullyApproved; // boolean [boolean]
            this.impersonating = input.impersonating; // boolean [boolean]
            this.internalNotes = input.internalNotes; // string [string]
            if ( input.joinDate && typeof input.joinDate === 'string' ) {
                this.joinDate = moment(input.joinDate).utc();
            } else {
                this.joinDate = input.joinDate;
            }
            if ( input.lastLoginDate && typeof input.lastLoginDate === 'string' ) {
                this.lastLoginDate = moment(input.lastLoginDate).utc();
            } else {
                this.lastLoginDate = input.lastLoginDate;
            }
            this.lastName = input.lastName; // string [string]
            this.lastRequestUri = input.lastRequestUri; // string [string]
            this.name = input.name; // string [string]
            this.organization = input.organization; // string [string]
            this.originatingSystem = input.originatingSystem; // string [string]
            this.password = input.password; // string [string]
            this.passwordResetCode = input.passwordResetCode; // string [string]
            if ( input.passwordResetTime && typeof input.passwordResetTime === 'string' ) {
                this.passwordResetTime = moment(input.passwordResetTime).utc();
            } else {
                this.passwordResetTime = input.passwordResetTime;
            }
            this.phone = input.phone; // string [string]
            this.pkiSubject = input.pkiSubject; // string [string]
            this.pocApproved = input.pocApproved; // boolean [boolean]
            this.pocEmail = input.pocEmail; // string [string]
            this.pocName = input.pocName; // string [string]
            if ( input.pocNotifiedDate && typeof input.pocNotifiedDate === 'string' ) {
                this.pocNotifiedDate = moment(input.pocNotifiedDate).utc();
            } else {
                this.pocNotifiedDate = input.pocNotifiedDate;
            }
            this.pocOrganization = input.pocOrganization; // string [string]
            this.pocPhone = input.pocPhone; // string [string]
            this.pocVerificationCode = input.pocVerificationCode; // string [string]
            this.pocVerified = input.pocVerified; // boolean [boolean]
            this.reason = input.reason; // string [string]
            this.requestReason = input.requestReason; // string [string]
            if ( input.roles && input.roles.map ) { // list [GenRoleDefinition]
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return new GenRoleDefinition( x );
                } );
            } else {
                this.roles = input.roles;
            }
            this.subOrganization = input.subOrganization; // string [string]
            this.subscribed = input.subscribed; // boolean [boolean]
            this.username = input.username; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUser[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUser(a));
        } else {
          return [];
        }
    }
}

