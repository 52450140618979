import { PsDatasetStyle } from './ps-dataset-style.class';

export class PsDataset {

   public  createCountData: boolean;
   public  layerStyle: PsDatasetStyle;
   public  year: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genDataset: PsDataset);

    constructor( input?: PsDataset ) {
        if ( input && typeof input !== 'number' ) {
            this.createCountData = input.createCountData;
            this.layerStyle = new PsDatasetStyle( input.layerStyle );
            this.year = input.year;
        }
    }
}
