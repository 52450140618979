import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {User} from '../types/user.class';
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authenticatedUser: BehaviorSubject<User>;

  constructor(private http: HttpClient, public router: Router, private popup: MatSnackBar, private userService: UserService) {
    this.authenticatedUser = new BehaviorSubject<User>(undefined);
    this.testAuthentication();
  }


  /**
   * Will authenticate username and password and create a session.
   * If username and password are not provided, it test to see if a session with the server exist.
   * Will return user object that is authenticated after call returns.
   *
   * @param {string} [username] optional user name to test
   * @param {string} [password] optional password to test
   * @param rememberMe
   * @returns {Observable<GenUser>} -- user that is currently authenticated after call completes
   *
   * @memberOf AuthenticationService
   */
  public testAuthentication(username?: string, password?: string, rememberMe?: boolean): Observable<boolean> {
    let authCheck: Observable<any>;

    if (!username || !password) {
      authCheck = this.http.get('api/security/user/me');
    } else {
      let headers = new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('X-Requested-With', 'XMLHttpRequest');

      authCheck = this.http.post('/login',
        'username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password) + ((rememberMe) ? `&remember-me=${encodeURIComponent('on')}` : ''),
        {headers: headers, responseType: 'text'}
      ).pipe(switchMap(() => this.http.get('/api/security/user/me')));
    }

    let ret = new Subject<boolean>();

    authCheck.subscribe({
      next: (x: any) => {
        let u = x?.enabled ? new User(this.userService.roles.value, x) : undefined;
        this.authenticatedUser.next(u);
        ret.next(!!u);
        ret.complete()
      },
      error: err => {
        console.error(err);
        this.authenticatedUser.next(undefined);
        ret.next(false);
        ret.complete()
      }
    });

    return ret;
  }

  /**
   *
   * @memberOf AuthenticationService
   */
  public impersonate(target: User) {
    this.http.post(`/admin/impersonate`, `username=${target.username}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'text'
    })
      .subscribe(u => {
        this.successHandler('Impersonate', u);
      }, (e) => {
        this.errorHandler('Impersonate', e);
      });
  }


  private successHandler(type: string, user: string) {
    this.authenticatedUser.next(new User(null, JSON.parse(user)));
    this.popup.open(`${type} Successful, Refreshing.....`, '', {panelClass: 'success'});
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }

  private errorHandler(type: string, error: any) {
    this.popup.open(`${type} Failed`, '', {panelClass: 'failure', duration: 2000});
    throw error;
  }

  public unImpersonate() {
    return this.http.post('/admin/unimpersonate', '', {responseType: 'text'})
      .subscribe(u => {
        this.successHandler('Unimpersonate', u);
      }, (e) => {
        this.errorHandler('Unimpersonate', e);
      });
  }

  /**
   * Will attempt to login in to the server with the username and password. If the user is already authenticated,
   * they will be logged out first.
   *
   * @param {string} username
   * @param {string} password
   * @param rememberMe
   * @returns Observable<GenUser>
   *
   * @memberOf AuthenticationService
   */
  public login(username: string, password: string, rememberMe = false): Observable<boolean> {
    return this.testAuthentication(username, password, rememberMe);
  }

  /**
   * This is not used for application access and will not affect the session's state.  It is just used to
   * verify that the person at the browser has the user's password.  This helps prevent a malicious user
   * from using someone elses already-authenticated browser to change their password (the check should
   * happen again on the server as well but making this method available to the client makes the user
   * experience a bit nicer.
   *
   * @param password
   */
  public verifyPassword(password: string): Observable<boolean> {
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers: headers};

    return this.http.post('/api/security/user/verify-password', password, options) as Observable<boolean>;
  }

  /**
   * Checks for appropriate password complexity
   *
   * @param password
   */
  public checkPasswordComplexity(password: string): Observable<string> {
    let headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.post('/api/security/user/check-password-complexity', password, {
      headers: headers,
      responseType: 'text'
    });
  }

  /**
   * logs user out
   * @memberOf AuthenticationService
   */
  public logout(): void {
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = '/logout';
    document.body.appendChild(form);
    form.submit();
  }

  checkEmailExists(email: string): Observable<boolean> {
    return this.http.post('/api/security/user/check-email-exists', email) as Observable<boolean>;
  }

  /**
   * Start a setInterval timer to hit the server every N milliseconds to keep the session alive.
   * @param {number} frequencyMilliseconds
   */
  checkSession(frequencyMilliseconds: number): Observable<boolean> {
    let lastSuccess = undefined;
    let status = new BehaviorSubject(true);
    window.setInterval(() => {
        if (!lastSuccess || (new Date().getTime() - lastSuccess.getTime()) > frequencyMilliseconds) {
          this.http.get('/api/session').subscribe(
            () => {
              if (!status.getValue()) {
                status.next(true);
              }
              lastSuccess = new Date();
            },
            () => {
              if (status.getValue() && this.authenticatedUser.getValue()) {
                lastSuccess = null;
                status.next(false);
              }
            }
          );
        }
      },
      Math.min(5000, frequencyMilliseconds) //Interval
    );

    return status;
  }
}
