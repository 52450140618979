import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject} from "rxjs";
import {FileShare} from "../types/file-share.class";
import {User} from "../types/user.class";

@Injectable({
  providedIn: 'root'
})
export class FileShareService {

  constructor(private http: HttpClient) {
  }

  save(document: FileShare, file: any): Observable<XMLHttpRequest | ProgressEvent> {
    let formData = new FormData();
    let xhr = new XMLHttpRequest();

    if (file) {
      formData.append('file', file);
    }

    formData.append('metadata', JSON.stringify(this.getSimplifiedFileShare(document)));

    let subject = new ReplaySubject<XMLHttpRequest | ProgressEvent>();

    let url: string = document.id ? `/api/fileshare/${document.id}` : `/api/fileshare`;

    xhr.open('POST', url, true);
    xhr.upload.onprogress = (e) => {
      subject.next(e);
    };
    xhr.onerror = xhr.onload = (e) => {
      if (xhr.responseType !== undefined && xhr.status === 200) {
        subject.next(xhr);
      } else {
        subject.error(xhr);
      }
      subject.complete();
    };
    xhr.send(formData);

    return subject;
  }

  getSimplifiedFileShare(fileshare: FileShare): FileShare {
    const fs = new FileShare(null, fileshare);
    let roles = [];
    if (fs.roles && fs.roles.length) {
      roles = (typeof fs.roles[0] === 'string') ? fs.roles : fs.roles.map(r => r.name);
    }
    fs.roles = roles;
    return fs;
  }

  getMyFiles(): Observable<FileShare[]> {
    return this.http.get<FileShare[]>('api/fileshare');
  }

  delete(fileShare: FileShare): Observable<void> {
    return this.http.delete<void>(`api/fileshare/${fileShare.id}`);
  }

}
