<div class="full-width full-height user-manager-container flex-column flex-align-start-start gap-12">
  <div class="loading-overlay" [class.show]="loading">
    <div *ngIf="loading" class="loading-mask">
      <mat-spinner diameter="150"></mat-spinner>
    </div>
  </div>
  <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true" [class.gap-20]="true">
    <div class="flex-row flex-align-start-center gap-10">
      <mat-icon class="title-icon">account_circle</mat-icon>
      <div class="title">User Management</div>
    </div>
    <div class="full-width flex-row flex-align-start-center gap-20">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>Search</mat-label>
        <input type="text" matInput [formControl]="searchControl" (keyup)="refresh()">
        <mat-icon matPrefix style="color: #a2a2a2">search</mat-icon>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="portal-select" appearance="outline" floatLabel="always">
        <mat-label>Organization</mat-label>
        <mat-select placeholder="Any Organization" [(ngModel)]="selectedOrganization" (selectionChange)="refresh()">
          <mat-option [value]="undefined">Any Organization</mat-option>
          <mat-option *ngFor="let organization of organizations" [value]="organization">{{ organization }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="no-bottom-padding portal-select" appearance="outline"
                      floatLabel="always">
        <mat-label>Role</mat-label>
        <mat-select placeholder="Any Role" [(ngModel)]="selectedRole" (selectionChange)="refresh()">
          <mat-option [value]="undefined">Any Role</mat-option>
          <mat-option *ngFor="let role of editableRoles"
                      [value]="role">{{ roleDescription(role) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="no-bottom-padding portal-select" appearance="outline"
                      floatLabel="always">
        <mat-label>Status</mat-label>
        <mat-select placeholder="Any Status" [(ngModel)]="selectedStatus" (selectionChange)="refresh()">
          <mat-option [value]="undefined">Any Status</mat-option>
          <mat-option *ngFor="let status of statuses" [value]="status">{{ status }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="no-bottom-padding portal-date-picker" appearance="outline"
                      style="max-width: 125px;">
        <mat-label>Join Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="joinDate" (dateChange)="refresh()"
               (dateInput)="delayedRefresh()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="joinDate || today"></mat-datepicker>
      </mat-form-field>
      <button mat-raised-button (click)="addGlobalRole()">
        <mat-icon>add</mat-icon> Add Role
      </button>
      <button mat-raised-button (click)="deleteGlobalRole()">
        <mat-icon>delete_outline</mat-icon> Delete Role
      </button>
    </div>
  </div>
  <div class="user-table full-width">
    <mat-table [dataSource]="dataSource" class="full-width" style="border-radius: 4px" matSort>
      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="full-width" [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-10]="true">
            <mat-icon class="user-icon">account_circle</mat-icon>
            <div [class.flex-grow]="true" [class.flex-column]="true" class="flex-align-center-start">
              <div class="user-name full-width truncate">{{ element.firstName }} {{ element.lastName }}</div>
              <div class="user-email full-width truncate">{{ element.email }}</div>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="domain">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Domain</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ getEmailDomain(element.email) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastLoginDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.lastLoginDate?.format('YYYY-MM-DD') }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="icons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="full-width" [class.flex-row]="true" [class.flex-align-center-center]="true">
            <div class="row-icon-container">
              <mat-icon class="row-icon clickable" (click)="impersonate(element)">face</mat-icon>
            </div>
            <div class="row-icon-container">
              <mat-icon class="row-icon clickable" [routerLink]="['/user', element.username]"
                        [ngClass]="{'persist': userNeedsApproval(element)}">edit
              </mat-icon>
            </div>
            <div class="row-icon-container">
              <mat-icon class="row-icon clickable"
                        (click)="(!element.enabled && !userNeedsApproval(element)) ? enableUser(element) : disableUser(element)"
                        [ngClass]="{'red': !element.enabled && !userNeedsApproval(element), 'persist': !element.enabled && !userNeedsApproval(element)}">
                block
              </mat-icon>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="roles">
        <mat-header-cell *matHeaderCellDef>Roles</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="full-width" [class.flex-row-wrap]="true" [class.flex-align-start-center]="true"
               [class.gap-10]="true">
            <ng-container
              *ngIf="element.roles.length && !(userNeedsApproval(element) || (!element.enabled && !userNeedsApproval(element)))">
              <div *ngFor="let role of filteredRoles(element)" class="{{'role-chip ' + role.name}}"
                   [class.flex-row]="true"
                   [class.flex-align-start-center]="true">
                <div class="role-title full-height">{{ this.roleDescription(role) }}</div>
                <div class="{{'clear-container full-height ' + role.name}}" [class.flex-column]="true"
                     [class.flex-align-center-center]="true">
                  <mat-icon class="clickable role-clear-icon" (click)="onRoleChange(element, role)">clear</mat-icon>
                </div>
              </div>
            </ng-container>
            <div class="clickable add-role"
                 *ngIf="!userNeedsApproval(element) && !(!element.enabled && !userNeedsApproval(element))"
                 (click)="$event.stopPropagation(); addRoleMenuOpened(element);" [matMenuTriggerFor]="roleMenu">+ Add
              Role
            </div>
            <div class="full-width truncate role-action" *ngIf="userNeedsApproval(element)">
              Needs Approval
            </div>
            <div class="full-width truncate role-action" *ngIf="!element.enabled && !userNeedsApproval(element)">
              Disabled
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>


</div>

<mat-menu #roleMenu="matMenu">
  <button *ngFor="let roleOption of menuRolesOptions" mat-menu-item
          (click)="$event.stopPropagation(); roleOption.checked = !roleOption.checked">
    <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-12]="true">
      <mat-checkbox color="primary" [(ngModel)]="roleOption.checked" (click)="$event.stopPropagation()"></mat-checkbox>
      <div>{{ roleOption.displayName }}</div>
    </div>
  </button>
  <button style="width: calc(100% - 16px); margin: 0 8px" color="primary" mat-raised-button
          (click)="addRolesFromMenu()">DONE
  </button>
</mat-menu>
<a #downloadLink></a>
