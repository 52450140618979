import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {GenTrelloData} from "../generated/serverModels/GenTrelloData";
import {GenTrelloCard} from "../generated/serverModels/GenTrelloCard";
import {GenWorkflowStatus} from "../generated/serverModels/GenWorkflowStatus";
import {Task} from "../types/task.class";

@Injectable({
  providedIn: 'root'
})
export class TrelloService {

  private enabled = false;

  authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  get isEnabled(): boolean { return this.enabled; }

  setEnabled(value: boolean) {
    this.enabled = value;
  }

  getTrelloData(): Observable<GenTrelloData> {
    return this.http.get<GenTrelloData>('api/trello');
  }

  getCardById(id: string): Observable<GenTrelloCard> {
    return this.http.get<GenTrelloCard>(`api/trello/card/${id}`);
  }

  updateCardListId(cardId: string, idList: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve();
      };

      const failure = () => {
        reject();
      };

      (window as any).Trello.put(`/cards/${cardId}`, {idList: idList}, success, failure);

    });

  }

  authenticate(): Promise<void> {
    return new Promise((resolve, reject) => {
      (window as any).Trello.authorize({
        type: 'popup',
        name: 'ORNL Human Dynamics Portal',
        scope: {
          read: 'true',
          write: 'true' },
        expiration: 'never',
        success: () => { resolve() },
        error: () => { reject() }
      })
    });
  }

  createCard(name: string, idList: string): Promise<GenTrelloCard> {
    return new Promise((resolve, reject) => {
      const success = (data: any) => {
        const ret = new GenTrelloCard();
        ret.trelloId = data.id;
        ret.idList = data.idList;
        ret.name = data.name;
        resolve(ret);
      };

      const failure = () => {
        reject(null);
      };

      const newCard = {
        name: name,
        idList: idList
      };

      (window as any).Trello.post(`/cards/`, newCard, success, failure);
    });
  }

  getIdListFromStatus(status: GenWorkflowStatus): string {
    switch (status) {
      case GenWorkflowStatus.OnHold:
        return (window as any).data.trelloOnHoldId.split(', ')[0];
      case GenWorkflowStatus.Potential:
        return (window as any).data.trelloInQueueId.split(', ')[0];
      case GenWorkflowStatus.InQueue:
        return (window as any).data.trelloInQueueId.split(', ')[0];
      case GenWorkflowStatus.InProgress:
        return (window as any).data.trelloInProgressId.split(', ')[0];
      case GenWorkflowStatus.Review:
        return (window as any).data.trelloReviewId.split(', ')[0];
      case GenWorkflowStatus.Complete:
        return (window as any).data.trelloCompleteId.split(', ')[0];
    }
  }

  getStatusFromIdList(idList: string): GenWorkflowStatus {
    if ((window as any).data.trelloOnHoldId.split(', ').indexOf(idList) !== -1) {
      return GenWorkflowStatus.OnHold;
    } else if ((window as any).data.trelloInQueueId.split(', ').indexOf(idList) !== -1) {
      return GenWorkflowStatus.Potential;
    } else if ((window as any).data.trelloInProgressId.split(', ').indexOf(idList) !== -1) {
      return GenWorkflowStatus.InQueue;
    } else if ((window as any).data.trelloInProgressId.split(', ').indexOf(idList) !== -1) {
      return GenWorkflowStatus.InProgress;
    } else if ((window as any).data.trelloReviewId.split(', ').indexOf(idList) !== -1) {
      return GenWorkflowStatus.Review;
    } else if ((window as any).data.trelloCompleteId.split(', ').indexOf(idList) !== -1) {
      return GenWorkflowStatus.Complete;
    } else {
      return null;
    }
  }

  pullUpdates(): Observable<Task[]> {
    return this.http.get<Task[]>('api/trello/updates');
  }
}
