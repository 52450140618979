<div class="container" [class.flex-column]="true" [class.flex-align-start-center]="true" [class.gap-10]="true">
  <mat-form-field style="width: 100%">
    <input matInput [(ngModel)]="subject" placeholder="Subject">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <textarea matInput [(ngModel)]="body" placeholder="Body" matTextareaAutosize matAutosizeMinRows="5"
              matAutosizeMaxRows="15"></textarea>
  </mat-form-field>
  <mat-chip-grid>
    <mat-chip-row style="margin-bottom: 2px;" *ngFor="let user of shownUsers; let i = index" removable="true"
                  (removed)="removeUser(i, user)">
      {{ user.email }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <mat-chip-row style="margin-bottom: 2px;" *ngIf="!allShown" color="primary" (click)="showAllUsers()"
                  selected="true">
      {{ 'Show ' + (users.length - shownUsers.length) + ' more...' }}
    </mat-chip-row>
    <mat-chip-row style="margin-bottom: 2px;" *ngIf="allShown && this.users.length > 10" color="primary"
                  (click)="showLess()" selected="true">
      Show less...
    </mat-chip-row>
  </mat-chip-grid>
  <div style="width: 100%; padding-bottom: 10px;" [class.flex-row]="true" [class.flex-align-start-center]="true">
    <button mat-raised-button [class.flex-125]="true" [disabled]="isValid()" (click)="sendTest()">
      Send To Self
    </button>
    <div [class.flex-grow]="true" [class.flex-row]="true" [class.flex-align-end-center]="true"
         [class.gap-10]="true">
      <button mat-raised-button (click)="close()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="isValid()" (click)="send()">
        Send
      </button>
    </div>
  </div>
</div>
