<div mat-dialog-title class="flex-row flex-align-space-between full-width">
  <div class="flex-row flex-align-space-between full-width">
    <span>{{ project?.name || 'Create New Project' }} </span>
    <mat-icon class="clickable" (click)="ref.close()">clear</mat-icon>
  </div>
</div>

<div mat-dialog-content class="flex-column flex-align-start-start gap-5 content">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Name [required]</mat-label>
    <input matInput [(ngModel)]="project.name" (keyup)="checkValidity()">
  </mat-form-field>

  <form class="full-width">
    <mat-form-field class="poc-input full-width" appearance="outline">
      <mat-label>Point of Contact [required]</mat-label>
      <!--suppress TypeScriptValidateTypes -->
      <input matInput
             [matAutocomplete]="pocAutoComplete"
             [formControl]="pocControl">
      <mat-autocomplete #pocAutoComplete="matAutocomplete">
        <mat-option *ngFor="let user of filteredPOC | async"
                    [value]="user.firstName + ' ' + user.lastName "
                    (onSelectionChange)="selectPOC(user); this.checkValidity();">
          {{ displayUser(user) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <div class="title">Roles</div>

  <div class="checkbox-group full-width gap-12 flex-align-start-start flex-column-wrap"
       [style.height.px]="maxRoleHeight">

    <div class="checkbox-container" *ngFor="let role of roleDefinitions">
      <mat-checkbox
        color="primary"
        (change)="onRoleChange(role);"
        [checked]="project.hasRole(role)">{{ role.description }}
      </mat-checkbox>
    </div>
    <div *ngIf="addingNewRole"
         class="checkbox-container flex-row flex-align-start-center gap-8">
      <mat-checkbox></mat-checkbox>
      <input class="role-input" placeholder="New Role" #addRoleInput matInput [(ngModel)]="addRoleName"
             (blur)="addRoleFinish()" (keyup.enter)="addRoleInput.blur()">
    </div>
  </div>

  <div *ngIf="!project.roles.length" class="error-fg">At least one role is required</div>

  <div class="full-width flex-row flex-align-start-center gap-16">
    <div matRipple class="clickable add-role" (click)="addRole()">+ Add Role</div>
    <div class="role-warning flex-grow" *ngIf="addingNewRole && nameInUse">
      A role with that name already exists!
    </div>
  </div>

  <mat-form-field class="release-notes full-width" appearance="outline">
    <mat-label>Description</mat-label>
    <textarea matInput
              [(ngModel)]="project.notes"
              matTextareaAutosize
              matAutosizeMinRows="4"
              matAutosizeMaxRows="6"
              name="description"
              id="description"></textarea>
    <mat-hint>Optional</mat-hint>
  </mat-form-field>

  <portal-file-upload [file]="thumbnailFile" [canDelete]="false" (fileAdded)="thumbnailFile = $event"
                      (deleteFile)="thumbnailFile = undefined" title="Upload Image" [optional]="true"
                      class="full-width"></portal-file-upload>

</div>

<mat-dialog-actions class="full-width">
  <div class="full-width flex-row flex-align-end-center gap-12">
    <button mat-raised-button (click)="ref.close()">
      Cancel
    </button>
    <button mat-raised-button color="primary" [disabled]="!isValid" (click)="save()">
      Save Changes
    </button>
  </div>
</mat-dialog-actions>
