import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { PsDatasetStyle } from 'src/app/types/ps-dataset-style.class';
import { PsDataset } from 'src/app/types/ps-dataset.class';

@Component({
  selector: 'portal-file-transfer-dialog',
  templateUrl: './file-transfer-dialog.component.html',
  styleUrls: ['./file-transfer-dialog.component.scss']
})
export class FileTransferDialogComponent {

public styles: PsDatasetStyle[] = [];
public dataset: PsDataset = new PsDataset();
public formGroup: UntypedFormGroup;
public filename: string;

  constructor(public dialogRef: MatDialogRef<FileTransferDialogComponent>, @Inject(MAT_DIALOG_DATA)public data: any) {
      this.styles = data.styles;
      this.filename = data.filename;


      const regex = /19[7-9]|20[0-9][0-9]/g;
      const match = this.filename.match(regex);
      const yearStr: string = (match === null) ? undefined : match[0];

      if (yearStr !== undefined && !isNaN(parseInt(yearStr))) {
          this.dataset.year = parseInt(yearStr)
      }


      this.initializeControls();
  }

public initializeControls(): void {
      const controls = {
          style: new UntypedFormControl('', [Validators.required]),
          year: new UntypedFormControl('', [Validators.required]),
      };
      this.formGroup = new UntypedFormGroup(controls)
  }

public setStyle(event: MatSelectChange): void {
      this.dataset.layerStyle = event.value;
  }

public displayDatasetStyle(style: PsDatasetStyle): string {
      return style.name.split('_')
          .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
          .join(' ')
  }

public uploadDataset(): void {
      this.dialogRef.close(this.dataset);
  }
}
