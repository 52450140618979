import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Task} from "../../../../types/task.class";

@Component({
  selector: 'portal-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() file: File | string;
  @Input() title = 'Upload File';
  @Input() optional = false;
  @Input() canDelete = true;
  @ViewChild('fileInput', {"static": true}) fileInput: ElementRef;

  @Output() fileAdded = new EventEmitter<File>();
  @Output() deleteFile = new EventEmitter();
  @Output() downloadFile = new EventEmitter();

  get fileDisplay(): string {
    if (this.file) {
      return (typeof this.file === 'string') ? this.file : this.file.name;
    } else {
      return '';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  openFileBrowser() {
    this.fileInput.nativeElement.click();
  }

  fileDropped(files: FileList) {
    this.file = files.item(0);
    this.fileAdded.emit(files.item(0));
  }

  onFileInputChange() {
    this.file = this.fileInput.nativeElement.files[ 0 ];
    this.fileAdded.emit(this.fileInput.nativeElement.files[ 0 ]);
  }

  delete() {
    this.deleteFile.emit();
  }

  download() {
    this.downloadFile.emit();
  }


}
