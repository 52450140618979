<div mat-dialog-title class="flex-row flex-align-space-between full-width">
  <div class="flex-row flex-align-space-between full-width">
    <span>{{ taskName }} Preview</span>
    <mat-icon class="clickable" (click)="close()">clear</mat-icon>
  </div>
</div>

<div mat-dialog-content class="map-container" #mapElement>

  <div class="spinner-container" *ngIf="!imageHasAlreadyLoaded || !extentHasAlreadyLoaded">
    <mat-spinner diameter="50"></mat-spinner>
  </div>

  <a href="http://mapbox.com/about/maps" class="mapbox-wordmark" target="_blank">Mapbox</a>

  <div class="mapbox-attribution-container">
    <a href="https://www.mapbox.com/about/maps/">Â© Mapbox | </a>
    <a href="http://www.openstreetmap.org/copyright">Â© OpenStreetMap | </a>
    <a href="https://www.mapbox.com/map-feedback/" target="_blank"><strong>Improve this map</strong></a>
  </div>

  <div class="zoom-control mat-elevation-z3" [class.flex-column]="true" [class.flex-align-start-center]="true">
    <div class="zoom-button full-width" (click)="zoomMap(1)"
         style="font-size: 24px; border-bottom: 1px solid rgba(0, 0, 0, 0.12)" [class.flex-column]="true"
         [class.flex-align-center-center]="true">+
    </div>
    <div class="zoom-button full-width" (click)="zoomMap(-1)" style="font-size: 36px;" [class.flex-column]="true"
         [class.flex-align-center-center]="true">-
    </div>
  </div>

  <div class="layer-select">
    <mat-form-field>
      <mat-select placeholder="Active Layer" [(value)]="selectedLayer" (selectionChange)="changeLayer()">
        <mat-option *ngFor="let name of layerNames; let index = index" [value]="name">{{ name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="legend mat-elevation-z3" [class.flex-column]="true" [class.flex-align-center-center]="true">
    <div>People</div>
    <img [src]="legendUrl" alt=""/>
  </div>
</div>
