import {GenProject} from '../generated/serverModels/GenProject';
import {GenRoleDefinition} from '../generated/serverModels/GenRoleDefinition';

export class Project extends GenProject {

  public roleMap: Map<string, GenRoleDefinition> = new Map<string, GenRoleDefinition>();

  constructor(data?: any) {
    super(data);
    if (!this.roles) {
      this.roles = [];
    }
    if (!this.members) {
      this.members = [];
    }
  }

  getThumbnailPath(): string | undefined {
    if (this.thumbnail) {
      return `/api/project/thumbnail/${this.id}/thumbnail.png`;
    } else {
      return undefined;
    }
  }

  hasRole(role: GenRoleDefinition | string): boolean {
    if (!this.roles) {
      return false;
    }
    const roleName = (typeof role === 'string') ? role : role?.name;
    return this.roles.some(r => r === roleName);
  }

  changeRole(value: boolean, role: GenRoleDefinition) {
    if (value) {
      if (!this.hasRole(role)) {
        this.roles.push(role.name);
      }
    } else {
      this.roles = this.roles.filter(r => r !== role.name);
    }
  }


}
