import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "../../types/user.class";
import {UserService} from "../../services/user.service";
// @ts-ignore
import * as agencies from '../../../assets/agencies.json'
import {UntypedFormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {MatOptionSelectionChange} from "@angular/material/core";

/**
 * Form to gather the info that Marie requests of first-time users before giving them access to data.
 *
 * Sample email:
 *
 *
 *
 * Good morning – In order to finish processing your approval for access to the Portal, could you please answer a few questions.
 *
 * Contractor – yes or no
 * Organization
 * Reason for Account
 *
 * This information is used by NGA to report metrics to leadership in support of ORNL’s LandScan program and is a requirement for access to the Portal.  Let me know if you have any questions.


 */

@Component({
  selector: 'portal-user-survey',
  templateUrl: './user-survey.component.html',
  styleUrls: ['./user-survey.component.scss']
})
export class UserSurveyComponent implements OnInit {
  user: User;
  submitted = false;
  verificationCodeSent = false;
  verificationCode: string;
  contractorAlreadySet = false;
  justSentVerificationCode = false;
  organization: string;
  department = '';
  adminEmail: string;
  govAgencies: any = (agencies as any).default;
  allAgencies: any[] = [];
  parentAgencies: any[] = [];
  subAgencies: any[] = [];
  selectedAgency: any;
  selectedSubAgency: any;
  otherAgency: string;
  otherSubAgency: string;

  agencyControl = new UntypedFormControl();
  subAgencyControl = new UntypedFormControl();
  filteredAgencies: Observable<any[]>;
  filteredSubAgencies: Observable<any[]>;


  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private popup: MatSnackBar,
    private router: Router
  ) {
    this.adminEmail = (window as any).data.adminEmail;
  }

  ngOnInit(): void {
    this.allAgencies = this.govAgencies.filter(a => a && ((a.parent_id === null && a.used) || a.parent_id !== null));
    this.parentAgencies = this.allAgencies.filter(a => a && a.parent_id === null);
    this.subAgencies = this.allAgencies.filter(a => a && a.parent_id !== null);

    this.filteredAgencies = this.agencyControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterAgency(value, this.parentAgencies))
    );

    this.filteredSubAgencies = this.subAgencyControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterAgency(value, this.subAgencies))
    );

    this.user = this.activatedRoute.snapshot.data['user'];
    if (this.user.roles && this.user.roles.length > 0) {
      this.router.navigateByUrl('/');
    }
    if (this.user.contractor === true || this.user.contractor === false) {
      this.contractorAlreadySet = true;
    }

    if (this.user.email.indexOf('@') === -1) {
      this.user.email = '';
    } else {
      this.verificationCodeSent = true;
    }
  }

  private _filterAgency(value: string, agencies: any[]): any[] {
    return agencies.filter(a => a && a.name && a.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  }

  selectAgency(event: MatOptionSelectionChange, agency: any) {
    if (event.isUserInput) {
      this.selectedAgency = agency;
      if (this.selectedAgency !== 'Other') {
        this.subAgencies = this.allAgencies.filter(a => a && a.parent_id === this.selectedAgency.id)
          .sort((a, b) => a.name < b.name ? -1 : 1);
        this.filteredSubAgencies = this.subAgencyControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterAgency(value, this.subAgencies))
        );
      } else {
        this.subAgencyControl.setValue('Other');
        this.selectedSubAgency = 'Other';
      }
    }
  }

  selectSubAgency(event: MatOptionSelectionChange, agency: any) {
    if (event.isUserInput) {
      this.selectedSubAgency = agency;
    }
  }


  submit() {
    this.user.department = this.department;
    this.user.organization = (this.selectedAgency === 'Other') ? this.otherAgency : this.selectedAgency.name;
    this.user.subOrganization = (this.selectedSubAgency === 'Other') ? this.otherSubAgency : this.selectedSubAgency.name;
    this.userService.submitSurvey(this.user)
      .subscribe(u => {
        this.submitted = true;
        this.popup.open(`Thank you for submitting your information.  Your account will be reviewed soon.`, 'okay', {
          duration: 2000,
          panelClass: ['success']
        });
      }, () => {
        this.popup.open(`Submitting the survey failed`, 'okay', {
          duration: 60000,
          panelClass: ['failure']
        });

      });

  }

  get isOrganizationValid(): boolean {
    const c1 = !!this.selectedAgency && !!this.selectedSubAgency && this.selectedAgency !== 'Other' && this.selectedSubAgency !== 'Other';
    const c2 = this.selectedAgency === 'Other' && !!this.otherAgency && !!this.otherSubAgency;
    const c3 = this.selectedAgency !== 'Other' && this.selectedSubAgency === 'Other' && !!this.otherSubAgency;
    return c1 || c2 || c3;
  }

  isGovOrMil(email: string): boolean {
    if (!email) {
      return false;
    }
    const ending = email.substr(email.lastIndexOf('.')).toLowerCase();
    return (ending === '.gov' || ending === '.mil');
  }

  sendEmailVerificationCode() {
    this.userService.sendEmailVerificationCode(this.user.email)
      .subscribe(
        () => {
          this.verificationCodeSent = true;
          this.justSentVerificationCode = true;

          this.popup.open(`Thank you for submitting your email.  Check your inbox for a verification code.`, 'okay', {
            duration: 2000,
            panelClass: ['success']
          });

        },
        () => {
          this.popup.open(`Could not send the email verification code`, 'okay', {
            duration: 60000,
            panelClass: ['failure']
          });
        })
  }

  checkEmailVerificationCode() {
    this.userService.checkEmailVerificationCode(this.verificationCode)
      .subscribe(
        () => {
          this.user.emailVerified = true;

          this.popup.open(`Thank you for verifying your email.`, 'okay', {
            duration: 2000,
            panelClass: ['success']
          });

        },
        () => {
          this.popup.open(`Could not send the email verification code`, 'okay', {
            duration: 60000,
            panelClass: ['failure']
          });
        })

  }

  validateEmail(email): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getSendButtonText(): string {
    return this.verificationCodeSent ? 'Resend Verification Code' : 'Send Verification Code'
  }

  isFormValid() {
    const user = this.user;
    const c1 = user.contractor !== undefined && this.isOrganizationValid && user.requestReason && this.department;
    const c2 = user.pocName && user.pocEmail && user.pocPhone && user.pocOrganization && this.validateEmail(user.pocEmail) && this.isGovOrMil(user.pocEmail);
    return (this.isGovOrMil(user.email)) ? c1 : (c1 && c2);
  }

}
