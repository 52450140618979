
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenWmsProcessStatus {
    static readonly None: GenWmsProcessStatus = new GenWmsProcessStatus('None');
    static readonly Running: GenWmsProcessStatus = new GenWmsProcessStatus('Running');
    static readonly Error: GenWmsProcessStatus = new GenWmsProcessStatus('Error');
    static readonly Done: GenWmsProcessStatus = new GenWmsProcessStatus('Done');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'None') return GenWmsProcessStatus.None;
        if (name === 'Running') return GenWmsProcessStatus.Running;
        if (name === 'Error') return GenWmsProcessStatus.Error;
        if (name === 'Done') return GenWmsProcessStatus.Done;
        throw new Error('Unexpected value for GenWmsProcessStatus:' + name);
    }

    static values(): GenWmsProcessStatus[] {
        return [
            GenWmsProcessStatus.None,
            GenWmsProcessStatus.Running,
            GenWmsProcessStatus.Error,
            GenWmsProcessStatus.Done,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

