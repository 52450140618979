import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';
import { PsDatasetStyle } from 'src/app/types/ps-dataset-style.class';
import { IPsLayer } from 'src/app/types/ps-layer.interface';

@Component({
  selector: 'portal-wms-management-dialog',
  templateUrl: './wms-management-dialog.component.html',
  styleUrls: ['./wms-management-dialog.component.scss']
})
export class WmsManagementDialogComponent {

public styles: PsDatasetStyle[] = [];

  constructor(private dialogRef: MatDialogRef<WmsManagementDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IPsLayer,
              public taskService: TaskService) {
      this.taskService.getWmsStyles().subscribe(res => {
          this.styles = res;
      });
  }


public update(): void {
      // console.log(this.data);
      this.dialogRef.close(this.data);
  }

public close(): void {
      this.dialogRef.close();
  }

}
