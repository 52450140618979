<div class="login-wrapper full-width full-height gap-20" [class.flex-column]="true"
     [class.flex-align-center-center]="true">
  <div [class.flex-row]="true" [class.flex-align-start-center]="true" [class.gap-20]="true">
    <img alt="login illustration" src="assets/login_illustration.png" class="login-image">
    <div class="login-message">Welcome Back</div>
  </div>

  <div class="login-form full-width">
    <div *ngIf="loginError && !authenticationFailed && !authorizationFailed" class="error-message">
      Login Failed
    </div>

    <ng-container>
      <div *ngIf="authenticationFailed" class="error-message">
        Invalid username/password
      </div>

      <div *ngIf="authorizationFailed" class="error-message">
        Your username / password are correct, but your access to the site has not yet been approved.
        An email has been sent to the site administrator requesting approval.
        Please <a [href]="'mailto:' + adminEmail">Contact Us</a> if you need help.
      </div>

      <mat-form-field *ngIf="passwordAuthenticationEnabled" appearance="outline" class="full-width">
        <mat-label>Username</mat-label>
        <input matInput
               autofocus
               [(ngModel)]="username"
               (keyup.enter)="login()"
               name="username"/>
      </mat-form-field>

      <mat-form-field *ngIf="passwordAuthenticationEnabled" appearance="outline" class="full-width">
        <mat-label>Password</mat-label>
        <input matInput
               [type]="(hidePass) ? 'password' : 'text'"
               [(ngModel)]="password"
               (keyup.enter)="login()"
               name="password"/>
        <mat-icon matSuffix style="color: #a2a2a2" class="clickable"
                  (click)="hidePass = !hidePass">{{ (hidePass) ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>

      <mat-checkbox color="primary" [(ngModel)]="rememberMe">Remember Me</mat-checkbox>

      <div class="login-links full-width">
        <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true"
             [class.gap-10]="true">
          <div *ngIf="passwordAuthenticationEnabled">Forgot username or password? <a
            href="https://xcams.ornl.gov/xcams/fpwStep1.shtml">Reset it</a></div>
          <div *ngIf="passwordAuthenticationEnabled">Don't have an account? <a
            href="https://xcams.ornl.gov/xcams/regStep1.shtml">Request one now</a></div>
          <div>Need help logging in? <a [href]="'mailto:' + adminEmail">Contact Us</a></div>
          <div style="font-size: 10px">
            Please register using a valid .gov or .mil email address so that we may verify your request meets access
            requirements.
            If you do not have a .gov or .mil email address but are currently in a partnership with or working on behalf
            of the US
            federal government, you will be asked to provide contact information for your government POC during the
            registration process.
          </div>
        </div>
      </div>

      <div class="full-width gap-12 flex-row flex-align-start-center">
        <button *ngIf="passwordAuthenticationEnabled" mat-raised-button (click)="login()" class="pass-login">
          LOGIN WITH PASSWORD
        </button>
        <button mat-raised-button color="primary" (click)="pki()" class="pki-login">
          LOGIN WITH PKI
        </button>
      </div>

      <div class="login-warning full-width">
        <div class="full-width" [class.flex-column]="true" [class.flex-align-start-start]="true"
             [class.gap-10]="true">
          <div *ngIf="passwordAuthenticationEnabled">Accounts prior to 2019 will need to be re-registered <a
            href="https://xcams.ornl.gov/xcams/regStep1.shtml">here</a>.
          </div>
          <div *ngIf="passwordAuthenticationEnabled">See LandScan Portal Access Documentation
            <!--suppress HtmlUnknownTarget -->
            <a href="/documentation/LandScan Portal Account Request Approval Process.docx" download="true"
               target="_blank">here</a>.
          </div>
          <div>Need help? <a [href]="'mailto:' + adminEmail">Contact Us</a></div>
        </div>
      </div>

    </ng-container>
  </div>
</div>


<div class="full-height image-container" [class.flex-row]="true" [class.flex-align-start-center]="true">
  <img class="login-image" src="assets/LS2016.jpg" alt="LandScan 2016 map background"/>
</div>


