import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map} from 'rxjs/operators';

import { GenGenLocation } from '../generated/serverModels/GenGenLocation';
import {GenLocationType} from "../generated/serverModels/GenLocationType";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

public locations: BehaviorSubject<GenGenLocation[]>;

  constructor(private http: HttpClient) {
      this.locations = new BehaviorSubject([]);
      this.requestAll().subscribe((locations) => {
          this.locations.next(locations);
      });
  }

  getAllCountries(): Observable<GenGenLocation[]> {
    return this.http.get(`/api/location?locationType=${GenLocationType.Country}`).pipe(map(arr => {
      return Array.isArray(arr) ? arr.map(o => new GenGenLocation(o)) : [];
    }));
  }

  getAll(): Observable<GenGenLocation[]> {
      return this.locations;
  }

  getAllCOCOMs(): Observable<GenGenLocation[]> {
    return this.http.get(`/api/location?locationType=${GenLocationType.COCOM}`).pipe(map(arr => {
      return Array.isArray(arr) ? arr.map(o => new GenGenLocation(o)) : [];
    }));
  }

  requestAll(): Observable<GenGenLocation[]> {
      return this.http.get('/api/location').pipe(map(arr => {
          return Array.isArray(arr) ? arr.map(o => new GenGenLocation(o)) : [];
      }));
  }
}
