import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Task} from 'src/app/types/task.class';
import {User} from '../../../../types/user.class';
import {MatTableDataSource} from '@angular/material/table';
import {Project} from '../../../../types/project.class';
import * as moment from 'moment';
import {MapPreviewDialogComponent} from '../map-preview-dialog/map-preview-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GenWorkflowStatus} from '../../../../generated/serverModels/GenWorkflowStatus';
import {PsDataset} from '../../../../types/ps-dataset.class';
import {
  FileTransferDialogComponent
} from '../../../project/components/file-transfer-dialog/file-transfer-dialog.component';
import {filter} from 'rxjs/operators';
import {
  WmsManagementDialogComponent
} from '../../../project/components/wms-management-dialog/wms-management-dialog.component';
import {TaskService} from '../../../../services/task.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EditTaskDialogComponent} from '../../../project/components/edit-task-dialog/edit-task-dialog.component';
import {MatSort} from '@angular/material/sort';
import {PromptComponent, PromptData} from '../prompt/prompt.component';
import {BehaviorSubject} from 'rxjs';
import {ReleaseNotesDialogComponent} from '../release-notes-dialog/release-notes-dialog.component';

@Component({
  selector: 'portal-task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.scss']
})
export class TaskTableComponent implements OnChanges, AfterViewInit, OnInit {

  dataSource: MatTableDataSource<Task> = new MatTableDataSource<Task>();
  defaultColumns: string[] = ['checkbox', 'name', 'projectId', 'tags', 'releaseTimestamp', 'publishedTimestamp', 'fileLength', 'save_alt', 'map'];
  contributorColumns: string[] = ['checkbox', 'name', 'projectId', 'status', 'contributor', 'tags', 'releaseTimestamp', 'publishedTimestamp', 'fileLength', 'save_alt', 'map', 'more_vert'];
  reportColumns: string[] = ['checkbox', 'name', 'projectId', 'status', 'downloadCount', 'contributor', 'tags', 'releaseTimestamp', 'publishedTimestamp', 'save_alt', 'map', 'more_vert'];
  displayedColumns: string[] = [];
  attemptedDownload = false;
  lastIframeDownload: string;
  taskForMenu: Task;
  offset: number;
  downloadTaskIndices: boolean[] = [];

  @ViewChild('downloadFrame', {static: true}) downloadFrame: ElementRef;
  @ViewChild('normSort', {static: true}) normSort: MatSort;
  @ViewChild('tvsSort', {static: true}) tvsSort: MatSort;

  $tasks = new BehaviorSubject<Task[]>([]);

  @Input()
  set tasks(value: Task[]) {
    this.$tasks.next(value);
  }

  get tasks(): Task[] {
    return this.$tasks.value;
  }

  @Input() user: User;
  @Input() showThumbnails = false;
  @Input() view: 'project' | 'recent' | 'report' = 'project';
  @Input() projects: Project[] = [];
  @Output() selectedTaskChange = new EventEmitter();

  get anyTasksSelected(): boolean {
    return this.downloadTaskIndices.indexOf(true) !== -1;
  }

  constructor(private dialog: MatDialog,
              private taskService: TaskService,
              private popup: MatSnackBar) {
  }

  ngOnInit() {

    this.dataSource.sort = this.normSort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'fileLength':
          if (item.sticky && this.view === 'project') {
            if (this.normSort.direction === 'asc') {
              return -2;
            } else {
              return 1000000000000000000000;
            }
          } else {
            if (!item[property]) {
              return -1;
            } else {
              return item[property];
            }
          }
        case 'name':
        case 'status':
          if (item.sticky && this.view === 'project') {
            if (this.normSort.direction === 'asc') {
              return '00000000000';
            } else {
              return 'zzzzzzzzzzzzzz';
            }
          } else {
            return item[property];
          }
        case 'contributor':
          if (item.sticky && this.view === 'project') {
            if (this.normSort.direction === 'asc') {
              return '00000000000';
            } else {
              return 'zzzzzzzzzzzzzz';
            }
          } else {
            return `${item.contributor.firstName} ${item.contributor.lastName}`;
          }
        case 'publishedTimestamp':
        case 'releaseTimestamp':
          if (item.sticky && this.view === 'project') {
            if (this.normSort.direction === 'desc') {
              return moment().add(1000, 'years');
            } else {
              return 0;
            }
          } else {
            if (!item[property]) {
              return 1;
            } else {
              return item[property];
            }
          }
        default:
          return item[property];
      }
    };
  }

  ngAfterViewInit(): void {
    this.downloadFrame.nativeElement.onload = () => {
      if (!this.attemptedDownload) {
        // Soo... Firefox fires this when the iframe is added before anything has been clicked.
        return;
      }
      let retryFailed = false;
      try {
        if (this.lastIframeDownload) {
          window.open(this.lastIframeDownload);
        } else {
          retryFailed = true;
        }
      } catch (e) {
        retryFailed = true;
      }
      if (retryFailed) {
        window.alert('Download failed...');
      }
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.view === 'report') {
      this.displayedColumns = this.reportColumns;
    } else {
      this.displayedColumns = (this.user.isContributor()) ? this.contributorColumns.slice() : this.defaultColumns.slice();
    }
    const st = (changes.showThumbnails) ? changes.showThumbnails.currentValue : this.showThumbnails;
    if (!st) {
      const index = this.displayedColumns.findIndex(c => c === 'thumbnail');
      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
    const p = (changes.projects) ? changes.projects.currentValue : this.projects;
    if (p.length <= 1) {
      const index = this.displayedColumns.findIndex(c => c === 'projectId');
      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
    const t = (changes.tasks) ? changes.tasks.currentValue : this.tasks;
    if (!t.map(t => t.hasWms()).filter(v => v).length) {
      const index = this.displayedColumns.findIndex(c => c === 'map');
      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
    }

    this.$tasks.subscribe(t => this.dataSource.data = t);

    this.downloadTaskIndices = new Array(this.tasks.length);
    this.downloadTaskIndices.fill(false);
  }

  downloadCheckClicked(event: MouseEvent, index: number) {
    if (event.shiftKey && this.anyTasksSelected) {
      let lastSelected = -1;
      this.downloadTaskIndices.forEach((val, i) => {
        if (val) {
          lastSelected = i;
        }
      });

      if (index > lastSelected) {
        for (let i = lastSelected + 1; i < index; i++) {
          this.downloadTaskIndices[i] = true;
        }
      } else if (index < lastSelected) {
        for (let i = index + 1; i < lastSelected; i++) {
          this.downloadTaskIndices[i] = true;
        }
      }
    }
    window.setTimeout(() => {
      this.selectedTaskChange.emit(this.getMultiSelectURL());
    }, 0);
  }

  displayCell(column: string, task: Task) {
    switch (column) {
      case 'name':
        return task.name;
      case 'thumbnail':
        return task.thumbnail;
      case 'projectId':
        const project: Project = this.projects.find(p => p.id === task.projectId);
        return (project) ? project.name : '';
      case 'status':
        return this.prettyStatus(task.status);
      case 'contributor':
        return `${task.contributor.firstName} ${task.contributor.lastName}`;
      case 'tags':
        return task.tags.join(', ');
      case 'releaseTimestamp':
        const m = moment(task.releaseTimestamp);
        return (task.releaseTimestamp) ? `CY${m.year()}-Q${m.quarter()}` : '';
      case 'publishedTimestamp':
        return (task.publishedTimestamp) ? moment(task.publishedTimestamp).format('YYYY-MM-DD') : '';
      case 'fileLength':
        return (task.fileLength) ? `${(task.fileLength / 1024 / 1024).toFixed(1)} MB` : '';
    }
  }

  getProjectFromTask(task: Task): Project {
    return this.projects.find(p => p.id === task.projectId);
  }

  previewMap(task: Task) {
    const layerNames = task.url.map(val => {
      return val.split('/').pop();
    });

    const data = {
      taskId: task.id,
      taskName: task.name,
      layerNames
    };
    this.dialog.open(MapPreviewDialogComponent, {
      data,
      autoFocus: false,
      width: '90vw',
      panelClass: 'dialog-with-no-padding'
    });
  }


  fetch(task: Task): void {
    this.attemptedDownload = true;
    const iframe = this.downloadFrame.nativeElement;

    const downloadPath: string = task.getDownloadPath();
    if (iframe && !task.originalFilename.toLowerCase().endsWith('.pdf')) {
      this.lastIframeDownload = downloadPath;
      try {
        iframe.src = downloadPath;
      } catch (e) {
        window.open(this.lastIframeDownload);
        this.lastIframeDownload = undefined;
      }
    } else {
      this.lastIframeDownload = undefined;
      window.open(downloadPath);
    }

    this.trackDownload(task);
  }

  trackDownload(task: Task) {
    try {
      if (task.myDownloadCount <= 0) {
        task.myDownloadCount = 1;
      }
      return true;
    } catch (err) {
      console.error(err);
      return true;
    }
  }

  get canCreateWMS(): boolean {
    return this.user && this.user.isContributor() && (this.user.username === 'ax6' || this.user.username === '6nr' || this.user.username === 'azm');
  }

  isComplete(task: Task): boolean {
    return task && task.status === GenWorkflowStatus.Complete;
  }

  isLandScan(task: Task): boolean {
    return task && (task.tags.indexOf('LandScan HD') !== -1 || task.tags.indexOf('LandScan Global') !== -1 || task.tags.indexOf('LandScan USA') !== -1);
  }

  prettyStatus(status: GenWorkflowStatus): string {
    return status.toString().replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  public openDatasetModal(task: Task) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        filename: task.originalFilename,
      }
    };

    const upload = (dataset: PsDataset) => {
      this.taskService.createWms(task, dataset.createCountData)
        .subscribe(() => {
          this.popup.open('Your dataset is ready to preview!', 'okay', {
            duration: 5000,
            panelClass: ['success']
          });
        }, () => {
          this.popup.open('There was an error creating the services.', 'okay', {
            duration: 6000,
            panelClass: ['failure']
          });
        });
    };

    this.dialog.open(FileTransferDialogComponent, config).afterClosed()
      .pipe(filter(val => !!val))
      .subscribe((dataset: PsDataset) => {
        this.popup.open('Your WMS layer is being created.', 'okay', {
          duration: 5000,
          panelClass: ['success']
        });

        upload(dataset);
      });
  }

  public getMultiSelectURL(): string {
    let path = '/api/task/multi-download?';
    let first = true;
    this.downloadTaskIndices.forEach((value, index) => {
      if (value) {
        if (!first) {
          path += '&';
        }

        path += `id=${this.dataSource._orderData(this.tasks)[index].id}`;
        first = false;
      }
    });
    return (path.indexOf('id=') === -1) ? '' : path;
  }


  public manageWms(task: Task): void {
    const layerName = task.url[0].split('/').pop();
    this.taskService.getLayer(layerName).subscribe(res => {
      const config: MatDialogConfig = {
        data: res,
        disableClose: true
      };

      this.dialog.open(WmsManagementDialogComponent, config).afterClosed()
        .pipe(filter(val => !!val))
        .subscribe(layer => {
          this.taskService.updateLayer(layer).subscribe(() => {
            this.popup.open('WMS Layer Updated', 'okay', {
              duration: 5000,
              panelClass: ['success']
            });
          });
        });
    }, () => {
      this.popup.open('Error getting WMS details', 'OK', {
        panelClass: ['failure']
      });
    });
  }

  updateArchiveStatus(task: Task, status: boolean) {
    const data: PromptData = {
      level: 'med',
      type: 'item',
      action: (status) ? 'archive' : 'unarchive',
      name: task.name
    };
    this.dialog.open(PromptComponent, {data, panelClass: 'dialog-with-no-padding', width: '50vw'})
      .afterClosed().subscribe((response) => {
      if (response) {
        task.archived = status;
        this.taskService.save(task, null).subscribe((e) => {
          if (e instanceof XMLHttpRequest) {
            // this.applySearchFilter( this.searchVal );
          }
        }, (error) => {
          console.error(error);
        });
      }
    });
  }

  edit(task: Task): void {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '55vw',
      maxHeight: '98vh',
      panelClass: 'dialog-with-no-padding',
      data: {
        user: this.user,
        task,
        project: this.getProjectFromTask(task)
      }
    };
    this.dialog.open(EditTaskDialogComponent, configData).afterClosed().subscribe((updatedTask: Task) => {
      if (updatedTask) {
        const origIndex = this.tasks.findIndex(t => t.id === task.id);
        if (origIndex !== -1) {
          this.tasks[origIndex] = updatedTask;
          this.tasks = this.tasks.slice();
        }

        this.dataSource.data = this.tasks;
      }
    });
  }


  remove(taskToDelete: Task, disabled: boolean): void {
    if (!disabled) {
      const promptData: PromptData = {
        level: 'high',
        type: 'item',
        action: 'delete',
        name: taskToDelete.name
      };
      this.dialog.open(PromptComponent, {data: promptData, panelClass: 'dialog-with-no-padding', width: '50vw'})
        .afterClosed()
        .subscribe(confirmed => {
          if (confirmed) {
            this.taskService.remove(taskToDelete).subscribe(() => {
              this.popup.open('Task Deleted', 'okay', {duration: 2000, panelClass: ['success']});
              this.tasks = this.tasks.filter(d => d !== taskToDelete);

              this.dataSource.data = this.tasks;
            });
          }
        });
    }
  }

  viewReleaseNotes(task: Task) {
    this.dialog.open(ReleaseNotesDialogComponent, {
      data: {
        task
      },
      width: '50vw'
    });
  }
}
