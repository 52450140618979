<mat-table #normSort="matSort" [dataSource]="dataSource" class="full-width"
           [ngClass]="{'contributor': user && user.isContributor() && view !== 'report', 'non-contributor': user && !user.isContributor() && view !== 'report', 'none-selected': !anyTasksSelected, 'report-table': view === 'report'}"
           style="border-radius: 4px" matSort matSortActive="publishedTimestamp" matSortDirection="desc">
  <ng-container matColumnDef="checkbox">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index" class="flex-row flex-align-center-center">
      <mat-checkbox color="primary"
                    [(ngModel)]="downloadTaskIndices[i]"
                    class="download-checkbox"
                    [class.any-selected]="anyTasksSelected"
                    (click)="$event.stopPropagation(); downloadCheckClicked($event, i)"></mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width flex-row flex-align-start-center gap-8">
        <div class="flex-grow truncate task-name"
             [matTooltip]="element.releaseNotes"
             matTooltipPosition="right"
             matTooltipClass="white-space-pre-wrap">
          {{ element.name }} <span *ngIf="element.archived" class="archived">(Archived)</span>
        </div>
        <mat-icon class="clickable release-notes-icon" (click)="viewReleaseNotes(element)"
                  matTooltip="click to view notes"
                  matTooltipPosition="right">
          text_snippet
        </mat-icon>
        <mat-icon *ngIf="view === 'project' && element.sticky" class="documentation-icon" matTooltip="Documentation">
          description
        </mat-icon>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container *ngIf="displayedColumns.indexOf('thumbnail') !== -1" matColumnDef="thumbnail">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width">
        <img class="thumbnail" *ngIf="element.thumbnail" [src]="element.getThumbnailPath()" alt="thumbnail"/>
        <div class="thumbnail" *ngIf="!element.thumbnail"></div>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container *ngIf="displayedColumns.indexOf('downloadCount') !== -1" matColumnDef="downloadCount">
    <mat-header-cell *matHeaderCellDef mat-sort-header># of Downloads</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width truncate">{{ element.downloadCount }}</div>
    </mat-cell>
  </ng-container>
  <ng-container *ngIf="displayedColumns.indexOf('projectId') !== -1" matColumnDef="projectId">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Project</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width truncate">{{ displayCell('projectId', element) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container *ngIf="displayedColumns.indexOf('status') !== -1" matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width truncate">{{ prettyStatus(element.status) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container *ngIf="displayedColumns.indexOf('contributor') !== -1" matColumnDef="contributor">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Assignee</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width truncate">{{ element.contributor.firstName + ' ' + element.contributor.lastName }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tags">
    <mat-header-cell *matHeaderCellDef>Tags</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width flex-row flex-align-start-center gap-6">
        <mat-icon *ngIf="element.tags.length" class="tag-icon">local_offer</mat-icon>
        <div class="truncate">{{ element.tags.join(', ') }}</div>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="releaseTimestamp">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Tentative</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width truncate">{{ displayCell('releaseTimestamp', element) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="publishedTimestamp">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Published</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width truncate">{{ displayCell('publishedTimestamp', element) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fileLength">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Size</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width">{{ displayCell('fileLength', element) }}</div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="save_alt">
    <mat-header-cell *matHeaderCellDef>
      <div class="full-width flex-row flex-align-center-center">Download</div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.fileLength" class="full-width flex-row flex-align-center-center"
           (click)="$event.stopPropagation(); fetch(element)">
        <mat-icon class="clickable" style="color: #9B9B9B" [matTooltipDisabled]="!element.fileType"
                  [matTooltip]="'Contains .' + element.fileType">save_alt
        </mat-icon>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container *ngIf="displayedColumns.indexOf('map') !== -1" matColumnDef="map">
    <mat-header-cell *matHeaderCellDef>
      <div class="full-width flex-row flex-align-center-center">View</div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.hasWms()" class="full-width flex-row flex-align-center-center"
           (click)="$event.stopPropagation(); previewMap(element)">
        <mat-icon class="clickable" style="color: #9B9B9B; width: 20px; height: 20px" svgIcon="wms"></mat-icon>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="more_vert">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="full-width flex-row flex-align-center-center"
           (click)="$event.stopPropagation(); taskForMenu = element;" [matMenuTriggerFor]="taskMenu">
        <mat-icon class="clickable" style="color: #9B9B9B">more_vert</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row [ngClass]="{'documentation': row.sticky && view === 'project'}"
           *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-menu #taskMenu="matMenu">
  <button *ngIf="canCreateWMS && isLandScan(taskForMenu) && taskForMenu.url" mat-menu-item (click)="manageWms(taskForMenu)"
          class="flex-row flex-align-start-center">
    <mat-icon>build</mat-icon>
    <div class="flex-grow">Manage WMS</div>
  </button>
  <button *ngIf="canCreateWMS && isLandScan(taskForMenu) && !taskForMenu.url" mat-menu-item (click)="openDatasetModal(taskForMenu)"
          class="flex-row flex-align-start-center">
    <mat-icon>layers</mat-icon>
    <div class="flex-grow">Create WMS</div>
  </button>
  <button mat-menu-item *ngIf="taskForMenu && taskForMenu.archived === false"
          (click)="updateArchiveStatus(taskForMenu, true)" class="flex-row flex-align-start-center">
    <mat-icon>archive</mat-icon>
    <div class="flex-grow">Archive</div>
  </button>
  <button mat-menu-item *ngIf="taskForMenu && taskForMenu.archived === true"
          (click)="updateArchiveStatus(taskForMenu, false)" class="flex-row flex-align-start-center">
    <mat-icon>unarchive</mat-icon>
    <div class="flex-grow">Unarchive</div>
  </button>
  <button mat-menu-item (click)="edit(taskForMenu)" class="flex-row flex-align-start-center">
    <mat-icon>edit</mat-icon>
    <div class="flex-grow">Edit</div>
  </button>
  <button mat-menu-item [disabled]="isComplete(taskForMenu) && !user.isPublisher()"
          matTooltip="Change Status To 'In Progress' To Delete"
          [matTooltipDisabled]="!(isComplete(taskForMenu) && !user.isPublisher())"
          (click)="remove(taskForMenu, isComplete(taskForMenu) && !user.isPublisher())"
          class="flex-row flex-align-start-center">
    <mat-icon>delete</mat-icon>
    <div class="flex-grow">Delete</div>
  </button>
</mat-menu>


<iframe #downloadFrame></iframe>
