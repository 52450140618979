import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {GenGistRoleDefinition} from '../../../../generated/serverModels/GenGistRoleDefinition';

@Component({
  selector: 'portal-delete-role',
  templateUrl: './delete-role.component.html',
  styleUrls: ['./delete-role.component.scss']
})
export class DeleteRoleComponent implements OnInit {

  roles: GenRoleDefinition[] = [];
  roleChecklist: { checked: boolean, role: GenRoleDefinition }[] = [];

  constructor(public ref: MatDialogRef<DeleteRoleComponent>,
              @Inject(MAT_DIALOG_DATA) private data: GenRoleDefinition[]) {
    this.roles = data.filter(r => {
      return r.name !== 'ROLE_CONTRIBUTOR' &&
        r.name !== 'ROLE_APPLICATION_MANAGER' &&
        r.name !== 'ROLE_PUBLISHER' &&
        r.name !== 'ROLE_SPONSOR' &&
        r.name !== 'ROLE_ADMIN';
    });
    this.roleChecklist = this.roles.map(r => ({checked: false, role: r}));
  }

  get maxRoleHeight(): number {
    return Math.ceil((this.roles.length) / 2) * 36;
  }

  ngOnInit(): void {
  }

  close() {
    this.ref.close();
  }

  get isValid(): boolean {
    return this.roleChecklist.map(r => r.checked).indexOf(true) !== -1;
  }

  delete() {
    this.ref.close(this.roleChecklist.filter(r => r.checked).map(r => r.role));
  }

  roleDescription(roleDefinition: GenGistRoleDefinition): string {
    return roleDefinition.description;
  }

}
